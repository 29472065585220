import {useNFTCategories} from "../../../hooks/useNFTCategories";
import {Category} from "../../../types/Category.type";

type CategoriesProps = {
    categories: string[],
    containerClassName?: string
}

const Categories = ({categories, containerClassName = ''}: CategoriesProps) => {

    const {nftCategories} = useNFTCategories()

    if (categories.length === 0) {
        return false
    }

    const CategoryItem = ({item}: { item: string }) => {
        const category: Category | undefined = nftCategories.find((category) => category.id === item)
        if (!category) {
            return false
        }
        return <div className={"text-sm py-2 px-4 bg-primary/10 flex flex-row items-center"}>
            <span className={"pr-1"}>{category.icon}</span>
            {category.name}
        </div>
    }

    return (
        <div className={`flex flex-row items-center gap-x-3 ${containerClassName}`}>
            {categories.map((category: string, index: number) => <CategoryItem key={index} item={category}/>)}
        </div>
    )
}

export default Categories