import {NFT} from "../types/NFT.type";
import {useUserLikeQueries} from "./useUserLikeQueries";
import {UserData, UserLike} from "../types/UserData.type";
import {useUserContext} from "../providers/UserProvider";

export default function useUserLikes() {

    const {setUser} = useUserContext()
    const {createUserLike, removeUserLike} = useUserLikeQueries()

    const likeOrDislikeNFT = async (nftId: NFT['id'], user: UserData, like: boolean, updateState: (state: any) => void): Promise<string> => {
        try {
            if (!user?.uid) {
                throw new Error("User not found")
            }
            if (like) {
                const likesCount = await createUserLike(user.uid, nftId)
                updateState((prev: any) => {
                    if (prev instanceof Array) {
                        return prev.map((item: NFT) => {
                            if (item.id === nftId) {
                                return {...item, likesCount: likesCount}
                            }
                            return item
                        })
                    }
                })
                setUser((prev: any) => ({
                    ...prev,
                    likes: [
                        ...prev.likes,
                        {
                            nftId,
                            id: likesCount
                        }
                    ]
                }))
                return Promise.resolve("updated state")
            } else {
                if (!user.likes?.length) {
                    throw new Error("User likes not found")
                }
                const likesCount = await removeUserLike(nftId)
                updateState((prev: any) => {
                    if (prev instanceof Array) {
                        return prev.map((item: NFT) => {
                            if (item.id === nftId) {
                                return {...item, likesCount: likesCount}
                            }
                            return item
                        })
                    }
                })
                setUser((prev: any) => ({
                    ...prev,
                    likes: prev.likes.filter((like: UserLike) => like.nftId !== nftId)
                }))
                return Promise.resolve("updated state")
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {likeOrDislikeNFT}

}