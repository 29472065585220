import {NFT} from "./NFT.type";
import {User} from "firebase/auth";

interface UserLikeInterface {
    id: string;
    userId: User['uid'],
    nftId: NFT['id'],
    created: Date
}

interface UserPurchaseInterface {
    id: string;
    userId: User['uid'],
    nftId: NFT['id'],
    created: Date
}

export type UserLike = Omit<UserLikeInterface, 'userId'>

export type UserPurchase = Omit<UserPurchaseInterface, 'userId'>

export type UserData = {
    uid: string;
    userType: UserType;
    fullName?: string;
    username?: string;
    email: string;
    phone?: string;
    wallet?: string;
    avatar?: string;
    likes?: UserLike[];
    purchases?: UserPurchase[];
    following?: UserData['uid'][];
    followers?: UserData['uid'][];
    emailVerified: boolean;
    active: boolean;
    created: Date;
}

export type UserDataPayload = Omit<UserData, 'uid'>

export enum UserType {
    DEFAULT = 'default',
    MUSICIAN = 'musician',
    VIDEOMAKER = 'videomaker',
}