import {NFTState} from "../../../types/NFT.type";
import {useCallback, useMemo, useState} from "react";
import {FileWithPath} from "react-dropzone";
import {useTranslation} from "react-i18next";
import Label from "../../ui/Inputs/Label";
import {Button} from "../../ui/Buttons/Button";
import InputText from "../../ui/Inputs/InputText";
import TextArea from "../../ui/Inputs/TextArea";
import FormNFTPreviewItem from "./FormNFTPreviewItem";
import {InputError} from "../../../types/InputError.types";
import Dropdown, {DropdownOption} from "../../ui/Inputs/Dropdown";
import {useNFTCategories} from "../../../hooks/useNFTCategories";
import {Collection, CollectionState} from "../../../types/Collection.type";
import VideoDropzone from "./VideoDropzone/VideoDropzone";
import VideoCoverDropzone from "./VideoCoverDropzone/VideoCoverDropzone";
import useUserQueries from "../../../hooks/useUserQueries";
import {UserData, UserType} from "../../../types/UserData.type";
import InputNumber from "../../ui/Inputs/InputNumber";
import useNFTFileStorage from "../../../hooks/useNFTFileStorage";
import {useUserContext} from "../../../providers/UserProvider";
import UserDataSearch from "../../search/UserDataSearch";

type formProps = {
    formState: NFTState,
    setFormState: (formState: (prev: NFTState) => any) => void,
    collectionState: CollectionState,
    setCollectionState: (collectionState: CollectionState) => void,
    onSubmit: () => void,
    onCreateCollection: () => void,
    collectionErrors: InputError[],
    formErrors: InputError[],
    setFormErrors: (formErrors: InputError[]) => void,
    userCollections: Collection[],
}

const FormNFT = ({
                     formState,
                     setFormState,
                     collectionState,
                     setCollectionState,
                     onSubmit,
                     onCreateCollection,
                     collectionErrors,
                     formErrors,
                     setFormErrors,
                     userCollections = []
                 }: formProps) => {

    const {t} = useTranslation()
    const [videoFilePreview, setVideoFilePreview] = useState<FileWithPath | undefined>(undefined)
    const [coverFilePreview, setCoverFilePreview] = useState<FileWithPath | undefined>(undefined)
    const [selectedMusician, setSelectedMusician] = useState<UserData | undefined>(undefined)
    const [selectedVideoMaker, setSelectedVideoMaker] = useState<UserData | undefined>(undefined)

    const {nftCategories} = useNFTCategories()
    const {getUserData} = useUserQueries()
    const {getAbsoluteUrl} = useNFTFileStorage()

    const {user} = useUserContext()

    const canSubmit = useMemo(() => !!formState.filePath && !!formState.name, [formState.filePath, formState.name])

    const filePreview: FileWithPath | undefined = useMemo(() => {
        return coverFilePreview || videoFilePreview
    }, [coverFilePreview, videoFilePreview])

    const nftCategoriesAsOptions = useMemo(() => {
        return nftCategories.map((item): DropdownOption => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }, [nftCategories])

    const userCollectionsAsOptions = useMemo(() => {
        return userCollections.map((item): DropdownOption => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }, [userCollections])

    const selectedCollection: Collection | undefined = useMemo(() => {
        return userCollections.find((item) => item.id === formState.collectionId)
    }, [formState.collectionId, userCollections])

    const onChangeInput = useCallback(async (value: string, name: string) => {
        if (name === "price") {
            let numberValue = Number(value).toFixed(8).replace(/\.?0+$/,"")
            setFormState(prev => ({...prev, [name]: numberValue}))
            return;
        }
        if (name === 'musicianUid') {
            const musician = await getUserData(value)
            if (musician) {
                setSelectedMusician(musician)
            }
            if (!!musician?.wallet) {
                setFormState(prev => ({...prev, musicWallet: musician?.wallet, [name]: value}))
                return;
            }
        }
        if (name === 'videomakerUid') {
            const videomaker = await getUserData(value)
            if (videomaker) {
                setSelectedVideoMaker(videomaker)
            }
            if (!!videomaker?.wallet) {
                setFormState(prev => ({...prev, videomakerWallet: videomaker?.wallet, [name]: value}))
                return;
            }
        }
        setFormState(prev => ({...prev, [name]: value}))
    }, [getUserData, setFormState])

    const onChangeCollectionInput = useCallback((value: string, name: string) => {
        setCollectionState({...collectionState, [name]: value});
    }, [collectionState, setCollectionState]);

    const onChangeCategories = useCallback((value: string[] | string) => {
        if (Array.isArray(value) && value.length > 0) {
            setFormState(prev => ({...prev, categories: value}))
        }
    }, [setFormState])

    const onChangeCollection = useCallback((value: string[] | string) => {
        if (!Array.isArray(value)) {
            setFormState(prev => ({...prev, collectionId: value}))
        }
    }, [setFormState])

    const onUploadVideoFile = useCallback(async (filePath: string) => {
        const absoluteUrl = await getAbsoluteUrl(filePath)
        if (absoluteUrl) {
            setFormState((prev) => ({...prev, filePath: absoluteUrl}))
        }
    }, [getAbsoluteUrl, setFormState])

    const onUploadCoverFile = useCallback(async (filePath: string) => {
        const absoluteUrl = await getAbsoluteUrl(filePath)
        if (absoluteUrl) {
            setFormState(prev => ({...prev, coverPath: absoluteUrl}))
        }
    }, [getAbsoluteUrl, setFormState])

    const copyOwnWallet = useCallback((who: "musician" | "videomaker") => {
        try {
            if (who === "musician") {
                setFormState(prev => ({...prev, musicWallet: user?.wallet || ""}))
            }
            if (who === "videomaker") {
                setFormState(prev => ({...prev, videomakerWallet: user?.wallet || ""}))
            }
        } catch (e) {
            console.error("error", e)
        }
    }, [setFormState, user?.wallet])

    const CreateCollection = useMemo(() => {
        return <div className={"p-4 bg-primary/10 rounded-lg mb-8"}>
            {userCollections.length > 0 && <div className={"mb-4"}><h4>{t("inputs.selectCollection")}</h4>
              <Dropdown name={"collectionId"}
                        multiple={false}
                        label={t("inputs.collection")}
                        containerClasses={"mb-4"}
                        inputClasses={"bg-white"}
                        options={userCollectionsAsOptions}
                        value={formState.collectionId ? [formState.collectionId] : []}
                        onChange={onChangeCollection}/>
            </div>}
            <h4>{!userCollections.length ? t("inputs.noUserCollectionTitle") : t("inputs.createCollectionTitle")}</h4>
            <div>
                <InputText type={"text"}
                           name={"name"}
                           required={true}
                           value={collectionState.name}
                           onChange={onChangeCollectionInput}
                           containerClasses={"mb-4"}
                           inputClasses={"bg-white"}
                           placeholder={t("placeholders.collectionName")}
                           error={collectionErrors.find(e => e.name === 'name')}
                           label={t("inputs.collectionName")}/>
                <Button size={"sm"} onClick={onCreateCollection}>{t("actions.createCollection")}</Button>
            </div>
        </div>
    }, [collectionErrors, collectionState.name, formState.collectionId, onChangeCollection, onChangeCollectionInput, onCreateCollection, t, userCollections.length, userCollectionsAsOptions])

    return <div className={"flex flex-col gap-6 lg:flex-row lg:gap-y-0 lg:gap-x-6 justify-between"}>
        <div className={"lg:w-8/12"}>
            <div className={"mb-4"}>
                <Label htmlFor={""} requiredInput={true}>{t("inputs.uploadVideoFile")}</Label>
                <VideoDropzone setFilePreview={setVideoFilePreview}
                               onUploadFile={onUploadVideoFile}
                               setFormErrors={setFormErrors}
                               formErrors={formErrors}
                               filePreview={videoFilePreview}/>
            </div>

            <div className={"mb-4"}>
                <Label htmlFor={""} requiredInput={true}>{t("inputs.uploadVideoCover")}</Label>
                <VideoCoverDropzone setFilePreview={setCoverFilePreview}
                                    onUploadFile={onUploadCoverFile}
                                    setFormErrors={setFormErrors}
                                    formErrors={formErrors}
                                    filePreview={coverFilePreview}/>
            </div>

            {CreateCollection}

            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4"}>
                <div className={"relative"}>
                    <UserDataSearch required
                                    label={t("inputs.videomaker")}
                                    placeholder={t("placeholders.videomaker")}
                                    userType={UserType.VIDEOMAKER}
                                    onChange={(value: string) => onChangeInput(value, "videomakerUid")}/>
                </div>
                <div className={"relative"}>
                    <UserDataSearch required
                                    label={t("inputs.musician")}
                                    placeholder={t("placeholders.musician")}
                                    userType={UserType.MUSICIAN}
                                    onChange={(value: string) => onChangeInput(value, "musicianUid")}/>
                </div>
            </div>

            <div className={"mb-4"}>
                <InputNumber
                             name={"price"}
                             required={true}
                             value={formState.price.toString()}
                             onChange={onChangeInput}
                             placeholder={t("placeholders.NFTprice")}
                             containerClasses={"mb-4"}
                             step={"0.01"}
                             min={0.000001}
                             max={100000}
                             error={formErrors.find(e => e.name === 'price')}
                             label={t("inputs.price")}/>
                <InputText type={"text"}
                           name={"name"}
                           required={true}
                           value={formState.name}
                           onChange={onChangeInput}
                           containerClasses={"mb-4"}
                           placeholder={t("placeholders.NFTtitle")}
                           error={formErrors.find(e => e.name === 'name')}
                           label={t("inputs.title")}/>
                <TextArea name={"description"}
                          required
                          value={formState.description}
                          onChange={onChangeInput}
                          containerClasses={"mb-4"}
                          error={formErrors.find(e => e.name === 'description')}
                          placeholder={t("placeholders.NFTdescription")}
                          label={t("inputs.description")}/>
                <Dropdown name={"categories"}
                          multiple={true}
                          label={t("inputs.categories")}
                          containerClasses={"mb-4"}
                          placeholder={t("placeholders.NFTcategories")}
                          options={nftCategoriesAsOptions}
                          value={formState.categories}
                          onChange={onChangeCategories}/>

                <div className={"lg:grid lg:grid-cols-2 lg:gap-x-4"}>
                    <InputText type={"number"}
                               name={"musicRoyalties"}
                               required={true}
                               value={formState.musicRoyalties ? formState.musicRoyalties.toString() : ""}
                               onChange={onChangeInput}
                               containerClasses={"mb-4"}
                               label={t("inputs.musicRoyalties")}
                               placeholder={t("placeholders.musicRoyalties")}
                               helpText={t("helpTexts.musicRoyalties")}
                               error={formErrors.find(e => e.name === 'musicRoyalties')}
                               min={0}
                               max={70}
                    />
                    <div className={"mb-4"}>
                        <InputText type={"text"}
                                   name={"musicWallet"}
                                   required={true}
                                   value={formState.musicWallet || ""}
                                   onChange={onChangeInput}
                                   label={t("inputs.musicWallet")}
                                   error={formErrors.find(e => e.name === 'musicWallet')}
                                   placeholder={t("placeholders.musicWallet")}
                        />
                        <button onClick={() => copyOwnWallet("musician")}
                                className={"text-xs text-primary font-bold ml-2"}>{t("actions.useOwnWallet")}</button>
                    </div>
                    <InputText type={"number"}
                               name={"videomakerRoyalties"}
                               value={formState.videomakerRoyalties ? formState.videomakerRoyalties.toString() : ""}
                               onChange={onChangeInput}
                               containerClasses={"mb-4"}
                               label={t("inputs.videomakerRoyalties")}
                               placeholder={t("placeholders.videomakerRoyalties")}
                               helpText={t("helpTexts.videomakerRoyalties")}
                               error={formErrors.find(e => e.name === 'videomakerRoyalties')}
                               required
                               min={0}
                               max={70}
                    />
                    <div className={"mb-4"}>
                        <InputText type={"text"}
                                   name={"videomakerWallet"}
                                   value={formState.videomakerWallet || ""}
                                   onChange={onChangeInput}
                                   label={t("inputs.videomakerWallet")}
                                   required
                                   error={formErrors.find(e => e.name === 'videomakerWallet')}
                                   placeholder={t("placeholders.videomakerWallet")}
                        />
                        <button onClick={() => copyOwnWallet("videomaker")}
                                className={"text-xs text-primary font-bold ml-2"}>{t("actions.useOwnWallet")}</button>
                    </div>
                </div>
            </div>

            <Button size={"sm"} disabled={!canSubmit} onClick={onSubmit}>{t("actions.createItem")}</Button>

        </div>
        <div className={"lg:w-4/12"}>
            <h4 className={"mb-1"}>{t("pages.createNFT.previewItem")}</h4>
            <FormNFTPreviewItem item={formState}
                                collection={selectedCollection}
                                videoMaker={selectedVideoMaker}
                                musician={selectedMusician}
                                filePreview={filePreview}/>
        </div>
    </div>
}

export default FormNFT