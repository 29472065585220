import { createBrowserRouter } from 'react-router-dom'
import ScreenFront from '../screens/front/ScreenFront'
import ScreenLogin from '../screens/login/ScreenLogin'
import ScreenRegister from '../screens/register/ScreenRegister'
import ScreenAuthor from '../screens/author/ScreenAuthor'
import ScreenLogout from '../screens/logout/ScreenLogout'
import ScreenCreateNFT from '../screens/create/ScreenCreateNFT'
import ScreenNFT from '../screens/nft/ScreenNFT'
import ScreenExplore from '../screens/explore/ScreenExplore'
import ScreenCollectionItem from '../screens/collections/ScreenCollectionItem'
import ScreenCollections from '../screens/collections/ScreenCollections'
import Screen404 from '../screens/errors/screen404'
import ScreenStatistics from '../screens/statistics/ScreenStatistics'
import ScreenStaticText from '../screens/static/ScreenStaticText'
import ProtectedRoutes from './ProtectedRoutes'

const routes = [
  {
    path: '/',
    element: <ScreenFront/>
  },
  {
    path: '/login',
    element: <ScreenLogin/>
  },
  {
    path: '/register',
    element: <ScreenRegister/>
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: '/account',
        element: <ScreenAuthor/>,
      },
      {
        path: '/create-nft',
        element: <ScreenCreateNFT/>
      },
      {
        path: '/logout',
        element: <ScreenLogout/>
      },
    ]
  },
  {
    path: '/author/:id',
    element: <ScreenAuthor/>
  },
  {
    path: '/nft/:id',
    element: <ScreenNFT/>
  },
  {
    path: '/explore',
    element: <ScreenExplore/>
  },
  {
    path: '/collections',
    element: <ScreenCollections/>
  },
  {
    path: '/collections/:id',
    element: <ScreenCollectionItem/>
  },
  {
    path: '/statistics',
    element: <ScreenStatistics/>
  },
  {
    path: '/about',
    element: <ScreenStaticText/>
  },
  {
    path: '/privacy-policy',
    element: <ScreenStaticText/>
  },
  {
    path: '/general-terms-of-sale',
    element: <ScreenStaticText/>
  },
  {
    path: '/terms-of-use',
    element: <ScreenStaticText/>
  },
  {
    path: '/how-to-buy-clipme',
    element: <ScreenStaticText/>
  },
  {
    path: '/selling-clipme',
    element: <ScreenStaticText/>
  },
  {
    path: '/what-clipme-are',
    element: <ScreenStaticText/>
  },
  {
    path: '*',
    element: <Screen404/>
  }
]

const router = createBrowserRouter(routes, {
  unstable_dataStrategy ({
    request,
    params,
    matches,
  }) {
    console.log('DATA STRATEGY', request, params, matches)

  }
})

export { router }