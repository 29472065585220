import AppScaffold from '../../components/ui/AppScaffold'
import {useTranslation} from 'react-i18next'
import type {NFTState} from '../../types/NFT.type'
import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import FormNFT from "../../components/forms/nft/FormNFT";
import {InputError} from "../../types/InputError.types";
import {number, object, string, ValidationError} from "yup";
import {parseYupErrors} from "../../utils/parseYupErrors.js";
import useNFTQueries from "../../hooks/useNFTQueries";
import {useAppContext} from "../../providers/AppProvider";
import {useUserContext} from "../../providers/UserProvider";
import {useNavigate} from "react-router-dom";
import useNFTCollectionQueries from "../../hooks/useNFTCollectionQueries";
import {Collection, CollectionState} from "../../types/Collection.type";
import {useWeb3Context} from "../../providers/Web3Provider";
import useMetamaskConnection from "../../hooks/useMetamaskConnection";
import {toast} from "react-toastify";
import useStatisticQueries from "../../hooks/useStatisticQueries";
import {TransactionType} from "../../types/Statistics.type";
import ProtectedPage from "../../components/routing/ProtectedPage";
import { v4 as uuidv4 } from 'uuid';

const formInitialState: NFTState = {
    uuid: uuidv4(),
    previousOwnerId: null,
    musicianUid: "",
    videomakerUid: "",
    //filePath: "https://firebasestorage.googleapis.com/v0/b/videomuse-23616.appspot.com/o/user%2FrBsHRNLDKSPJf8c03O897X15frH3%2Fvideos%2Fvideo%20(720p).mp4?alt=media&token=9984df5b-aace-4246-b7c7-b48478e9894a", // TODO: Remove this in production
    filePath: "",
    coverPath: "",
    userId: "",
    published: true,
    name: "",
    price: 0,
    description: "",
    musicRoyalties: 50,
    musicWallet: "",
    videomakerRoyalties: 50,
    videomakerWallet: "",
    categories: [],
    collectionId: "",
    collectionAddress: "",
    onSale: true,
    likesCount: 0,
    created: new Date(),
    updated: new Date()
}

const collectionInitialState: CollectionState = {
    name: "",
    userId: "",
    published: true,
    created: new Date(),
    updated: new Date(),
    nfts: []

}

const ScreenCreateNFT = () => {

    const {user} = useUserContext()
    const {setAppLoading} = useAppContext()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const {createNTF} = useNFTQueries()
    const {getUserCollections, createCollection, updateCollectionAddress} = useNFTCollectionQueries()
    const {createNFTForSale} = useWeb3Context()
    const {connectedToMetamask, connectToMetamask} = useMetamaskConnection()
    const {createTransaction} = useStatisticQueries()

    const [formState, setFormState] = useState<NFTState>(formInitialState)
    const [collectionState, setCollectionState] = useState<CollectionState>(collectionInitialState)
    const [formErrors, setFormErrors] = useState<InputError[]>([])
    const [collectionErrors, setCollectionErrors] = useState<InputError[]>([])
    const [userCollections, setUserCollections] = useState<Collection[]>([])

    const schema = object().shape({
        musicianUid: string().required(),
        videomakerUid: string().required(),
        name: string().required().min(3),
        price: number().required().min(0.000001),
        description: string().nullable().min(4),
        musicRoyalties: number().required().min(0).max(70).test('sum', t("messages.royaltiesSum"), function (value, context) {
            if (value + context.parent.videomakerRoyalties === 100) {
                return true
            }
        }),
        musicWallet: string().required().min(34).test('wallets', t("messages.oneOfTheWalletShouldBeOfUser"), function (value, context) {
            if (user?.wallet === value || context.parent.videomakerWallet === user?.wallet) {
                return true
            }
        }),
        videomakerRoyalties: number().min(0).max(70).test('sum', t("messages.royaltiesSum"), function (value, context) {
            if (value + context.parent.musicRoyalties === 100) {
                return true
            }
        }),
        videomakerWallet: string().required().min(34).test('wallets', t("messages.oneOfTheWalletShouldBeOfUser"), function (value, context) {
            if (user?.wallet === value || context.parent.musicWallet === user?.wallet) {
                return true
            }
        }),
        filePath: string().required(),
    })

    const collectionSchema = object().shape({
        name: string().required().min(4),
    })

    const collection: Collection | null | undefined = useMemo(() => {
        return !!formState.collectionId ? userCollections.find((collection) => collection.id === formState.collectionId) : null
    }, [formState.collectionId, userCollections])

    const getCollections = useCallback(async () => {
        if (user?.uid) {
            getUserCollections(user?.uid, true).then((collections) => {
                setUserCollections(collections)
            }).catch((error) => {
                console.log("error", error)
            })
        }
    }, [getUserCollections, user?.uid])

    useEffect(() => {
        getCollections().catch()
    }, [user?.uid]);

    const onCreateCollection = useCallback(async () => {
        try {
            setCollectionErrors([])
            collectionSchema.validateSync(collectionState, {abortEarly: false})
            if (user?.uid) {
                const collectionId = await createCollection({...collectionState, userId: user?.uid})
                if (collectionId) {
                    setCollectionState(collectionInitialState)
                    setFormState(prev => ({...prev, collectionId: collectionId}))
                    await getCollections()
                }
            }
        } catch (e) {
            console.error(e)
            if (e instanceof ValidationError) {
                setCollectionErrors(parseYupErrors(e))
            }
        }
    }, [collectionSchema, collectionState, user?.uid, createCollection, getCollections])

    const onSubmit = useCallback(async () => {
        try {
            setAppLoading(true)
            setFormErrors([])
            schema.validateSync(formState, {abortEarly: false})

            if (!connectedToMetamask) {
                await connectToMetamask()
                toast(t("messages.metamaskConnectedResubmit"), {type: "success"})
                return;
            }

            if (!connectedToMetamask) {
                toast(t("messages.metamaskNotConnected"), {type: "error"})
                throw new Error("Metamask not connected")
            }

            if (user?.uid) {
                const createNFTForSaleResponse = await createNFTForSale(formState, collection)
                if (createNFTForSaleResponse[0]) {
                    const nftId: string = await createNTF({
                        ...formState,
                        userId: user?.uid,
                        tokenId: createNFTForSaleResponse[0],
                        collectionAddress: createNFTForSaleResponse[1]
                    });
                    // Store collection address in collection in the case we are using a custom collection, not default
                    if(!collection?.address && !!collection?.id && createNFTForSaleResponse[1]){
                        await updateCollectionAddress(collection?.id, createNFTForSaleResponse[1])
                    }
                    if (nftId) {

                        // TODO: check this is the right way to create transaction
                        await createTransaction({
                            nftId: nftId,
                            nftTokenId: createNFTForSaleResponse[0],
                            collectionId: formState.collectionId || null,
                            type: TransactionType.CREATE,
                            price: formState.price,
                            sellerUid: formState.userId,
                        })

                        navigate(`/nft/${nftId}`)
                    }
                }
            }
        } catch (e: any) {
            console.error(e)
            if (e instanceof ValidationError) {
                setFormErrors(parseYupErrors(e))
            } else {
                if (!!e?.code) {
                    toast.error(t("messages." + e.code))
                } else {
                    toast(t("messages.errorCreatingNFT"), {type: "error"})
                }
            }
        } finally {
            setAppLoading(false)
        }
    }, [collection, setAppLoading, schema, formState, connectedToMetamask, user?.uid, connectToMetamask, t, createNFTForSale, createNTF, updateCollectionAddress, createTransaction, navigate])

    return (<ProtectedPage>
        <AppScaffold menuStyle={"inverted"} menuPosition={"absolute"}>
            <Fragment>
                <section className={"pt-32 pb-28 bg-createPage cover"}>
                    <h1 className={"h1 text-white text-center"}>{t("pages.createNFT.title")}</h1>
                </section>
                <section className={"app-container py-32"}>
                    <FormNFT formState={formState}
                             setFormState={setFormState}
                             collectionState={collectionState}
                             setCollectionState={setCollectionState}
                             onSubmit={onSubmit}
                             onCreateCollection={onCreateCollection}
                             collectionErrors={collectionErrors}
                             formErrors={formErrors}
                             setFormErrors={setFormErrors}
                             userCollections={userCollections}/>
                </section>
            </Fragment>
        </AppScaffold>
    </ProtectedPage>)
}

export default ScreenCreateNFT