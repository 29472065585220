import {UserData, UserDataPayload, UserLike, UserType} from "../types/UserData.type";
import {
    arrayRemove,
    arrayUnion,
    collection,
    CollectionReference,
    doc,
    documentId,
    DocumentReference,
    getDoc,
    getDocs,
    limit,
    query,
    Query,
    QuerySnapshot,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import {db} from "../firebase";
import {User} from "firebase/auth";

export default function useUserQueries() {

    const collectionName: string = "userData";
    const likeCollectionName: string = "userLikes";

    const userHasData = async (userId: User['uid']): Promise<boolean> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, userId);
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()) return Promise.resolve(false)
            const data = docSnap.data() as UserData
            if (!data.active) {
                return Promise.resolve(false)
            }
            return Promise.resolve(true);

        } catch (error) {
            return Promise.reject(false)
        }
    }

    const getUserData = async (userId: User['uid']): Promise<UserData | null> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, userId);
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                const userLikesQuery: QuerySnapshot = await getDocs(query(collection(db, likeCollectionName), where("userId", "==", userId)));
                const likes: UserLike[] = []
                userLikesQuery.forEach((doc) => {
                    likes.push({...doc.data(), id: doc.id} as UserLike)
                });
                return Promise.resolve({...docSnap.data(), uid: docSnap.id, likes} as UserData)
            }
            return Promise.resolve(null)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getUserDataByIds = async (userIds: User['uid'][]): Promise<UserData[]> => {
        try {
            const collectionReference: CollectionReference = collection(db, collectionName);
            const q: Query = query(collectionReference, where(documentId(), "in", userIds));
            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) return []
            const results: UserData[] = []
            querySnapshot.forEach((doc) => {
                results.push({...doc.data() as UserData, uid: doc.id})
            });
            return results;
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const storeUserData = async (data: UserDataPayload, userId: User['uid']): Promise<string> => {
        try {
            await setDoc(doc(db, collectionName, userId), {
                ...data,
                created: serverTimestamp(),
            });
            return Promise.resolve(userId)

        } catch (error) {
            return Promise.reject(error)
        }
    }

    const storeWalletAddress = async (wallet: string, userId: User['uid']): Promise<string> => {
        try {
            await setDoc(doc(db, collectionName, userId), {
                wallet: wallet,
                updated: serverTimestamp(),
            }, {merge: true});
            return Promise.resolve(userId)

        } catch (error) {
            return Promise.reject(error)
        }
    }

    const updateUserData = async (data: UserDataPayload, userId: User['uid']): Promise<string> => {
        try {
            await setDoc(doc(db, collectionName, userId), {
                ...data,
                updated: serverTimestamp(),
            }, {merge: true});
            return Promise.resolve(userId)

        } catch (error) {
            return Promise.reject(error)
        }
    }

    // TODO: Add search by username as well
    const getUserDataByUserNameOrEmail = async (keys: string): Promise<UserData[]> => {
        try {
            const collectionReference: CollectionReference = collection(db, collectionName);
            const q: Query = query(collectionReference, (
                where("deleted", "!=", true),
                    where("email", ">=", keys),
                    where("email", "<=", keys + "\uf8ff")));
            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) return []
            const results: UserData[] = []
            querySnapshot.forEach((doc) => {
                results.push({...doc.data() as UserData, uid: doc.id})
            });
            return results;
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getSomeUsersByType = async (type: UserType, itemLimit: number = 6): Promise<UserData[]> => {
        try {
            const collectionReference: CollectionReference = collection(db, collectionName);
            const q: Query = query(collectionReference,
                where("userType", "==", type),
                where("active", "==", true),
                limit(itemLimit));
            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) return []
            const results = new Set<UserData>()
            for (const doc of querySnapshot.docs) {
                results.add({...doc.data(), uid: doc.id} as UserData)
            }
            return results.size ? Array.from(results) : [];
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const followUser = async (userId: User['uid'], followId: User['uid']): Promise<void> => {
        try {
            const userRef: DocumentReference = doc(db, collectionName, userId);
            await updateDoc(userRef, {following: arrayUnion(followId)})

            const followRef: DocumentReference = doc(db, collectionName, followId);
            await updateDoc(followRef, {followers: arrayUnion(followId)})
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const unfollowUser = async (userId: User['uid'], followId: User['uid']): Promise<void> => {
        try {
            const userRef: DocumentReference = doc(db, collectionName, userId);
            await updateDoc(userRef, {following: arrayRemove(followId)})

            const followRef: DocumentReference = doc(db, collectionName, followId);
            await updateDoc(followRef, {followers: arrayRemove(followId)})
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const deleteSomePersonalDataFromUser = async (userId: User['uid']): Promise<boolean> => {
        try {
            const userRef: DocumentReference = doc(db, collectionName, userId);
            // Delete sensitive data
            await updateDoc(userRef, {email: "deleted@" + Date.now(), phone: "", avatar: "", active: false})
            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        userHasData,
        storeUserData,
        getUserData,
        getUserDataByUserNameOrEmail,
        updateUserData,
        storeWalletAddress,
        getSomeUsersByType,
        followUser,
        unfollowUser,
        getUserDataByIds,
        deleteSomePersonalDataFromUser
    }
}