import MainMenu from "./../MainMenu/MainMenu";
import {useCallback, useLayoutEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import CollapsibleMainMenu from "../MainMenu/CollapsibleMainMenu";
import GlobalSearch from "../../search/GlobalSearch";

type HeaderProps = {
    position?: "fixed" | "absolute" | "relative"
    menuStyle: "normal" | "inverted"
}

const Header = ({menuStyle, position = "absolute"}: HeaderProps) => {

    const [headerIsScrolling, setHeaderIsScrolling] = useState<boolean>(false)
    const headerRef = useRef<HTMLElement>(null)

    const [displayCollapsibleMenu, setDisplayCollapsibleMenu] = useState<boolean>(false)

    const onScroll = useCallback(() => {
        const scrollTop = window.scrollY;
        if (scrollTop > 0) {
            setHeaderIsScrolling(true)
            //headerRef?.current?.classList.add('sticky', 'bg-primaryDark');
        } else {
            setHeaderIsScrolling(false)
            //headerRef?.current?.classList.remove('sticky', 'bg-primaryDark');
        }
    }, [])

    useLayoutEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [onScroll])

    const onToggleCollapsibleMenu = useCallback(() => {
        setDisplayCollapsibleMenu(prev => !prev)
    }, [])

    return (
        <header ref={headerRef}
                className={`${position} top-0 left-0 w-full z-30 ${headerIsScrolling ? 'sticky bg-primaryDark' : (menuStyle === 'normal' ? 'bg-transparent' : 'bg-transparent')}`}>

            <div className={"app-container px-4 flex flex-row items-center justify-between"}>
                <div className={"flex flex-row items-center justify-start gap-x-4 lg:w-4/12"}>
                    <div className={"mr-4 flex-1"}>
                        <Link to={"/"} className={"block w-full"}>
                            <img src={menuStyle === "normal" && !headerIsScrolling ? "/logo.png" : "/logo-light.png"}
                                 className={"w-full"}
                                 alt={""}
                                 height={36}
                                 width={167}/>
                        </Link>
                    </div>

                    <GlobalSearch collectionIndex={'nft_index'} headerIsScrolling={headerIsScrolling} menuStyle={menuStyle}/>

                </div>
                <MainMenu onToggleCollapsibleMenu={onToggleCollapsibleMenu}
                          style={headerIsScrolling ? "inverted" : menuStyle}/>
            </div>
            <div className={"relative"}>
                <CollapsibleMainMenu show={displayCollapsibleMenu} style={headerIsScrolling ? "inverted" : menuStyle}/>
            </div>
        </header>
    );
}

export default Header