import {Dialog} from "@headlessui/react";
import {MdClose} from "react-icons/md";

export type DialogProps = {
    isOpen: boolean,
    onClose: () => void,
    title?: string,
    description?: string,
    children: JSX.Element
}

const AppDialog = ({isOpen, onClose, title, description, children}: DialogProps) => {
    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-[99]">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
            <div className="fixed top-0 md:inset-0 flex w-screen overflow-y-auto items-center justify-center">
                <Dialog.Panel className={"bg-white p-6 rounded-md shadow-lg md:w-2/3 lg:w-1/2 max-h-[500px] md:max-h-screen relative overflow-y-auto"}>
                    <button className={"absolute top-0 pt-6 pr-6 right-0 z-10"} onClick={onClose}>
                        <MdClose size={22}/>
                    </button>
                    {title && <Dialog.Title as={"h4"} className={"mb-4"}>{title}</Dialog.Title>}
                    {description && <Dialog.Description className={"mb-4"}>{description}</Dialog.Description>}
                    {children}
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}

export default AppDialog;