import useAlgoliaSearch from "../../hooks/useAlgoliaSearch";
import {Combobox, ComboboxInput, ComboboxOption, ComboboxOptions} from "@headlessui/react";
import React, {useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {debounce} from "../../utils/debounce";
import GlobalSearchResultItem from "./GlobalSearchResultItem";
import {useNavigate} from "react-router-dom";

type GlobalSearchProps = {
    collectionIndex: string,
    menuStyle: "normal" | "inverted"
    headerIsScrolling: boolean
}

type SearchResult = {
    index: any[]
}

const GlobalSearch = ({collectionIndex = 'nft_index', menuStyle, headerIsScrolling}: GlobalSearchProps) => {

    const {t} = useTranslation()

    const navigate = useNavigate()

    const [selectedResult, setSelectedResult] = useState<string>("");
    const [searchResults, setSearchResults] = useState<SearchResult[]>([])

    const {searchMultipleIndexes} = useAlgoliaSearch()

    const onSearchItem = useCallback(async (searchTerm: string): Promise<SearchResult[]> => {
        try {

            const queries = [
                {
                    indexName: 'nft_index',
                    query: searchTerm,
                    params: {
                        hitsPerPage: 3
                    }
                },
                {
                    indexName: 'user_index',
                    query: searchTerm,
                    params: {
                        hitsPerPage: 3
                    }
                },
                {
                    indexName: 'collection_index',
                    query: searchTerm,
                    params: {
                        hitsPerPage: 3
                    }
                }
            ]

            const results = await searchMultipleIndexes(queries);
            const items: any[] = [];
            results.forEach((result) => {
                result.hits.forEach((hit: any) => {
                    items.push({...hit, index: result?.index})
                })
            })

            setSearchResults(items);
            return items;
        } catch (error) {
            console.error(error);
            return [];
        }
    }, [searchMultipleIndexes]);

    const debouncedSearch = useRef(debounce(onSearchItem, 800)).current;

    const onChangeInput = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const value = event.target.value;
            if (value.length > 2) {
                debouncedSearch(value)
            }
        } catch (error) {
            console.error(error);
        }
    }, [debouncedSearch]);

    const onChangeComboBox = useCallback((objectId: string) => {

        if (objectId) {

            const item: any | undefined = searchResults.find((result: any) => result.objectID === objectId);

            setSelectedResult(item?.name || "");

            try {
                if (item.index === 'nft_index') {
                    navigate(`/nft/${item.objectID}`)
                } else if (item.index === 'user_index') {
                    navigate(`/author/${item.objectID}`)
                } else if (item.index === 'collection_index') {
                    navigate(`/collection/${item.objectID}`)
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setSelectedResult("")
        }
    }, [navigate, searchResults])

    return (
        <div>
            <Combobox value={selectedResult} onChange={onChangeComboBox}>
                <ComboboxInput onChange={onChangeInput}
                               placeholder={t("placeholders.NFTsearch")}
                               className={`flex-1 min-w-[220px] bg-primary/10 py-2 px-3 rounded-md ${(menuStyle === 'normal' && !headerIsScrolling) ? 'placeholder:text-gray-500 text-gray-800' : 'placeholder:text-white text-white'} text-sm hidden w-full lg:block border border-transparent hover:border-primary transition-all duration-300`}/>
                <div className={"relative"}>
                    <ComboboxOptions className={"absolute z-20 mt-1 max-h-60 w-full min-w-[250px] lg:min-w-[350px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"}>
                        {searchResults.map((result, index) => (
                            <GlobalSearchResultItem key={index}
                                                    item={result}
                                                    isLastItem={index === searchResults.length - 1}/>
                        ))}
                        {searchResults.length === 0 &&
                          <ComboboxOption className={"py-2 px-4 text-gray-500"} value={""}>
                              {t("global.noResults")}
                          </ComboboxOption>}
                    </ComboboxOptions>
                </div>
            </Combobox>
        </div>
    )

}

export default GlobalSearch