import InputText from "../../ui/Inputs/InputText";
import {useTranslation} from "react-i18next";
import {UserDataPayload, UserType} from "../../../types/UserData.type";
import AvatarDropzone from "./AvatarDropzone/AvatarDropzone";
import {useCallback, useMemo, useState} from "react";
import {FileWithPath} from "react-dropzone";
import {InputError} from "../../../types/InputError.types";
import Label from "../../ui/Inputs/Label";
import {Button} from "../../ui/Buttons/Button";
import useNFTFileStorage from "../../../hooks/useNFTFileStorage";
import Dropdown, {DropdownOption} from "../../ui/Inputs/Dropdown";

type FormUserDataProps = {
    formState: UserDataPayload,
    setFormState: (formState: (prev: UserDataPayload) => any) => void,
    formErrors: InputError[],
    onSubmit: () => void,
    setFormErrors: (formErrors: InputError[]) => void,
}

const FormUserData = ({formState, setFormState, onSubmit, formErrors, setFormErrors}: FormUserDataProps) => {

    const {t} = useTranslation()

    const {getAbsoluteUrl} = useNFTFileStorage()

    const [filePreview, setFilePreview] = useState<FileWithPath | undefined>(undefined)

    const userTypeAsOptions: DropdownOption[] = useMemo(() => {
        return Object.values(UserType).map((val) => {
            return {label: t(`userTypes.${val}`), value: val}
        })
    }, [t])

    const onChangeInput = useCallback((value: string, name: string) => {
        setFormState(prev => ({...prev, [name]: value}))
    }, [setFormState])

    const onChangeUserType = useCallback((value: string|string[]) => {
        if(!Array.isArray(value)){
            setFormState(prev => ({...prev, userType: value as UserType}))
        }
    }, [setFormState])

    const onUploadFile = useCallback(async (filePath: string) => {
        const absolutePath = await getAbsoluteUrl(filePath)
        if (absolutePath) {
            setFormState(prev => ({...prev, avatar: absolutePath}))
        }
    }, [getAbsoluteUrl, setFormState])

    return <div>
        <form onSubmit={(event) => event.preventDefault()}>
            <div className={"mb-4"}>
                <Label requiredInput={false} htmlFor={""}>{t("inputs.avatar")}</Label>
                <AvatarDropzone setFilePreview={setFilePreview}
                                onUploadFile={onUploadFile}
                                setFormErrors={setFormErrors}
                                formErrors={formErrors}
                                filePreview={filePreview}/>
            </div>
            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6"}>
                <InputText type={"text"}
                           name={"fullName"}
                           value={formState?.fullName || ""}
                           label={t('inputs.fullName')}
                           required
                           error={formErrors.find((error) => error.name === "fullName")}
                           onChange={onChangeInput}/>
                <InputText type={"text"}
                           name={"username"}
                           value={formState?.username || ""}
                           label={t('inputs.username')}
                           required
                           error={formErrors.find((error) => error.name === "username")}
                           onChange={onChangeInput}/>
                <InputText type={"text"}
                           name={"phone"}
                           value={formState?.phone || ""}
                           label={t('inputs.phone')}
                           error={formErrors.find((error) => error.name === "phone")}
                           onChange={onChangeInput}/>
                <Dropdown name={"type"}
                          required={true}
                          multiple={false}
                          label={t("inputs.userType")}
                          options={userTypeAsOptions}
                          value={formState.userType ? [formState.userType] : []}
                          onChange={onChangeUserType}/>
            </div>
            <div className={"flex justify-end"}>
                <Button onClick={onSubmit} size={"md"} disabled={false}>{t("actions.save")}</Button>
            </div>
        </form>
    </div>

}

export default FormUserData