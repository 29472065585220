import AppScaffold from "../../components/ui/AppScaffold";
import useNFTQueries from "../../hooks/useNFTQueries";
import {Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {NFT} from "../../types/NFT.type";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import Categories from "./components/Categories";
import {useTranslation} from "react-i18next";
import {IoMdHeart} from "react-icons/io";
import {useUserContext} from "../../providers/UserProvider";
import {Button} from "../../components/ui/Buttons/Button";
import {toast} from "react-toastify";
import {useAppContext} from "../../providers/AppProvider";
import {useWeb3Context} from "../../providers/Web3Provider";
import {FaEye} from "react-icons/fa";
import {useUserLikeQueries} from "../../hooks/useUserLikeQueries";
import {UserLike} from "../../types/UserData.type";
import {Helmet} from "react-helmet";

const ScreenNFT = () => {

    const {id} = useParams() as { id: string }

    const {setAppLoading} = useAppContext()
    const {buyNFTItem, checkItemWasSold} = useWeb3Context()
    const {user, setUser} = useUserContext()
    const {t} = useTranslation()
    const {getNTF, addNewVisit} = useNFTQueries()
    const {createUserLike, removeUserLike} = useUserLikeQueries()

    const [item, setItem] = useState<NFT | null>(null)

    const navigate = useNavigate()

    const canBeBought: boolean = useMemo(() => {
        if (!item) return false
        // If not registered user, can't buy
        if (!user) {
            return false
        }
        // If user is the owner, can't buy
        if (user?.uid === item.userId) {
            return false
        }
        return item.onSale
    }, [item, user])

    const likedByUser = useMemo(() => {
        if (!!user?.likes && !!item) {
            return user.likes.find((like) => like.nftId === item.id)
        }
        return false
    }, [item, user])

    useEffect(() => {
        if (id) {
            getNTF(id).then((data: NFT | null) => {
                if (data) {
                    setItem(data)
                }
            }).catch((error) => {
                console.log("error", error)
            })
        }
    }, [id]);

    useLayoutEffect(() => {
        if (!!item) {
            markAsVisited().catch()
        }
    }, [item]);

    const onLikeOrDislikeItem = useCallback(async (nftId: NFT['id'], like: boolean) => {
        try {
            if (!user?.uid) throw new Error("User not found")
            if (like) {
                const likesCount = await createUserLike(user.uid, nftId)
                setItem((prev: any) => {
                    if (prev instanceof Object) {
                        return {...prev, likesCount: likesCount}
                    }
                    return prev
                })
                setUser((prev: any) => ({
                    ...prev,
                    likes: [
                        ...prev.likes,
                        {
                            nftId,
                            id: likesCount
                        }
                    ]
                }))
            } else {
                if (!!user?.likes?.length) {
                    const likesCount = await removeUserLike(id)
                    setItem((prev: any) => {
                        if (prev instanceof Object) {
                            return {...prev, likesCount: likesCount}
                        }
                        return prev
                    })
                    setUser((prev: any) => ({
                        ...prev,
                        likes: prev.likes.filter((like: UserLike) => like.nftId !== id)
                    }))
                }
            }

        } catch (error) {
            console.log("error", error)
        }
    }, [createUserLike, id, removeUserLike, setUser, user?.likes?.length, user?.uid])

    const markAsVisited = useCallback(async () => {
        try {
            // get user IP
            if (!item) throw new Error("Item not found")
            const ipResponse = await fetch("https://api.ipify.org?format=json")
            const ipData = await ipResponse.json()
            if (item?.views?.includes(ipData.ip)) return
            await addNewVisit(item?.id, ipData.ip)
            setItem((prev: any) => {
                if (prev instanceof Object) {
                    return {...prev, views: !!prev?.views?.length ? [...prev.views, ipData.ip] : [ipData.ip]}
                }
                return prev
            })
        } catch (error) {
            console.log("error", error)
        }
    }, [addNewVisit, item])

    const onBuyItem = useCallback(async () => {
        try {
            if (!item) throw new Error("Item not found")
            if (item.tokenId && !!user?.uid) {
                setAppLoading(true)
                await buyNFTItem(item)
                await checkItemWasSold(item, user.uid)
                toast(t("toast.itemBought"), {type: "success"})
                navigate("/account")
            }
        } catch (error: any) {
            setAppLoading(false)
            if (!!error?.code) {
                toast.error(t("messages." + error.code))
            } else {
                toast.error(t("messages.errorBuyingItem"))
            }
            console.log("error", error)
        } finally {
            setAppLoading(false)
        }
    }, [item, user?.uid, setAppLoading, buyNFTItem, checkItemWasSold, t, navigate])

    const FileLoadIndicator = () => {
        return <div className={"absolute top-0 left-0 w-full h-full bg-primary/80 flex items-center justify-center"}>
            <AiOutlineLoading3Quarters size={28} className={"animate-spin text-white"}/>
        </div>
    }

    const FilePreview = useMemo(() => {
        // remove aspect-video for now
        return item?.filePath ?
            <video src={item?.filePath}
                   controls={true}
                   preload={"auto"}
                   className={"h-full w-full object-cover"}/> :
            <FileLoadIndicator/>
    }, [item?.filePath])

    const LikeHeart = () => {
        return !!item && (
            <div className={"flex flex-row items-center"}>
                {likedByUser ? (
                        <button onClick={() => onLikeOrDislikeItem(item.id, false)}>
                            <IoMdHeart className={"text-primary"}/>
                        </button>) :
                    (<button onClick={() => onLikeOrDislikeItem(item.id, true)}>
                        <IoMdHeart className={"text-gray-300 hover:text-primary"}/>
                    </button>)}
                <span className={"text-xs text-gray-500 ml-1"}>{item.likesCount || 0}</span>
            </div>
        )
    }

    const MusicianProfilePicture = useMemo(() => {
        if (!!item?.musician) {
            return <Link to={'/author/' + item.musicianUid} className={"flex flex-row items-center"}>
                <div className={"w-14 h-14 rounded-full block bg-primary mr-4"}>
                    {item.musician.avatar && <img src={item.musician.avatar}
                                                  referrerPolicy={"no-referrer"}
                                                  alt={item.musician.fullName?.toString()}
                                                  className={"w-14 h-14 rounded-full hover:p-2 transition-all ease-in-out duration-500"}/>}
                </div>
                <p className={"font-bold text-base text-black"}>{item.musician.fullName}</p>
            </Link>
        }
        return null
    }, [item?.musician, item?.musicianUid])

    const VideomakerProfilePicture = useMemo(() => {
        if (!!item?.videomaker) {
            return <Link to={'/author/' + item.videomakerUid} className={"flex flex-row items-center"}>
                <div className={"w-14 h-14 rounded-full block bg-primary mr-4"}>
                    {item.videomaker.avatar && <img src={item.videomaker.avatar}
                                                    referrerPolicy={"no-referrer"}
                                                    alt={item.videomaker.fullName?.toString()}
                                                    className={"w-14 h-14 rounded-full hover:p-2 transition-all ease-in-out duration-500"}/>}
                </div>
                <p className={"font-bold text-base text-black"}>{item.videomaker.fullName}</p>
            </Link>
        }
        return null
    }, [item?.videomaker, item?.videomakerUid])

    return <AppScaffold menuPosition={"relative"} menuStyle={"normal"}>
        <Fragment>
            <Helmet titleTemplate="Videomuse - %s">
                <title>{item?.name || "Default Title"}</title>
                <meta name="description" content={item?.description || "Default description"}/>

                {/* Open Graph tags */}
                <meta property="og:title" content={item?.name || "Default Title"}/>
                <meta property="og:site_name" content={process.env.REACT_APP_NAME || "Videomuse"}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:description" content={item?.description || "Default description"}/>
                <meta property="og:type" content="music.song"/>
                <meta property="og:image" content={item?.coverPath || "default-image-path.jpg"}/>
                <meta property="og:image:alt" content={item?.name || "Default Image Alt Text"}/>
                <meta property="og:image:width" content="300"/>
                <meta property="og:image:height" content="300"/>

                {/* Twitter tags */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={item?.name || "Default Title"}/>
                <meta name="twitter:description" content={item?.description || "Default description"}/>
                <meta name="twitter:image" content={item?.coverPath || "default-image-path.jpg"}/>
                <meta name="twitter:image:alt" content={item?.name || "Default Image Alt Text"}/>
            </Helmet>
            <section className={"app-container py-24"}>
                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-6"}>
                    <div className={"relative min-h-[250px]"}>
                        {FilePreview}
                    </div>
                    <div>
                        <h1 className={"text-4xl mb-4"}>{item?.name}</h1>
                        {item?.collection ?
                            <Link to={'/collections/' + item.collectionId}>
                                <h5 className={"text-primary font-bold mb-2 text-sm"}>{item.collection.name}</h5>
                            </Link> : null}
                        <p className={"text-lg text-gray-400 font-bold mb-4"}>{item?.price} MATIC</p>
                        <div className={"mb-4 flex flex-row flex-wrap gap-x-4"}>
                            {!!item?.categories?.length && <Categories categories={item?.categories || []}/>}
                            <div className={"text-sm py-1.5 px-4 bg-primary/10 flex flex-col items-center justify-center"}>
                                <LikeHeart/>
                            </div>
                            {!!item?.views?.length &&
                              <div className={"text-sm py-1.5 px-4 bg-primary/10 flex flex-col items-center justify-center"}>
                                <div className={"flex flex-row items-center"}>
                                  <FaEye/>
                                  <span className={"text-xs text-gray-500 m-1"}>{item?.views?.length}</span>
                                </div>
                              </div>
                            }
                        </div>
                        <p className={"mb-6"}>{item?.description}</p>
                        <div className={"flex lg:flex-row lg:justify-start gap-6 mb-6"}>
                            <div>
                                <h6 className={"mb-4"}>{t("global.musician")}</h6>
                                {MusicianProfilePicture}
                            </div>
                            <div>
                                <h6 className={"mb-4"}>{t("global.videomaker")}</h6>
                                {VideomakerProfilePicture}
                            </div>
                        </div>
                        {canBeBought && <div>
                          <Button onClick={onBuyItem}>
                              {t("actions.buy")}
                          </Button>
                        </div>}
                    </div>
                </div>
            </section>
        </Fragment>
    </AppScaffold>

}

export default ScreenNFT;