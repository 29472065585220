import {createContext, useContext, useState} from "react";
import AppLoader from "../components/ui/Loader/AppLoader";

type AppProviderProps = {
    children: JSX.Element
}

type appContextData = {
    appLoading: boolean,
    setAppLoading: (loading: boolean) => void
}

const AppContext = createContext({} as appContextData);

export function useAppContext(): appContextData {
    return useContext(AppContext)
}

const AppProvider = ({children}: AppProviderProps) => {

    const [appLoading, setAppLoading] = useState<boolean>(false)

    return <AppContext.Provider value={{appLoading, setAppLoading}}>
        {children}
        <AppLoader visible={appLoading}/>
    </AppContext.Provider>
}

export default AppProvider;