import {useNFTCategories} from "../../../hooks/useNFTCategories";
import {Category} from "../../../types/Category.type";
import {Link} from "react-router-dom";

const CategoryBrowser = () => {

    const {nftCategories} = useNFTCategories()

    const CategoryItem = ({item}: {item: Category}) => {
        return <Link to={'/explore?category=' + item.id} className={"bg-primary/10 animate-wow rounded-md flex flex-col items-center justify-center p-4 hover:bg-primaryDark hover:text-white transition-colors duration-200"}>
            <span className={"text-[40px] text-primary pb-3"}>{item.icon ? item.icon : null}</span>
            <p className={"font-bold text-sm"}>{item.name}</p>
        </Link>
    }

    return (
        <section className={"app-container py-24"}>
            <h2 className={"text-center relative mb-6"}>
                Category<span className={"text-primary"}>browser</span>
                <span className={"absolute -bottom-6 left-1/2 -translate-x-1/2 w-20 h-0.5 bg-primary"}/>
            </h2>
            <div className={"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 py-8 justify-center"}>
                {nftCategories.map((category, index) => <CategoryItem key={category.id} item={category}/>)}
            </div>
        </section>
    )
}

export default CategoryBrowser