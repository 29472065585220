import Header from "./Header/Header";
import {JSX, useEffect} from "react";
import BottomBar from "./BottomBar/BottomBar";
import Footer from "./Footer/Footer";
import {useLocation} from "react-router-dom";

type Props = {
    children: JSX.Element,
    containerClasses?: string
    mainClasses?: string,
    menuStyle?: "normal" | "inverted"
    menuPosition?: "fixed" | "absolute" | "relative"
}

const AppScaffold = ({children, containerClasses = '', mainClasses = '', menuStyle = 'normal', menuPosition = 'absolute'}: Props) => {

    const {pathname} = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname]);

    return <main className={`${mainClasses}`}>
        <Header menuStyle={menuStyle} position={menuPosition} />
        <div className={`${containerClasses}`}>
            {children}
        </div>
        <BottomBar />
        <Footer />
    </main>
}

export default AppScaffold;