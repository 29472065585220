import AppScaffold from "../../components/ui/AppScaffold";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Transaction, TransactionParameters, TransactionType} from "../../types/Statistics.type";
import useStatisticQueries from "../../hooks/useStatisticQueries";
import TransactionTable from "./components/TransactionTable";
import GridLoader from "../../components/ui/Loaders/GridLoader";
import {Button} from "../../components/ui/Buttons/Button";
import InputNumber from "../../components/ui/Inputs/InputNumber";
import Dropdown, {DropdownOption} from "../../components/ui/Inputs/Dropdown";
import CollectionSearch from "../../components/search/CollectionSearch";
import {Helmet} from "react-helmet";

const STATISTICS_LIMIT_ITEMS = 30

const ScreenStatistics = () => {

    const {t} = useTranslation()

    const {getTransactions} = useStatisticQueries()

    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const [lastItemId, setLastItemId] = useState<Transaction['id'] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [queryParameters, setQueryParameters] = useState<TransactionParameters>({
        number: null,
        nftTokenId: null,
        collectionId: [],
        transactionType: null,
    } as TransactionParameters)

    const transactionTypeAsOption: DropdownOption[] = useMemo(() => {
        const options = Object.values(TransactionType).map((val) => {
            return {label: t(`transactionTypes.${val}`), value: val}
        })
        return [
            {label: t("inputs.all"), value: ""},
            ...options
        ]
    }, [t])

    const getTransactionItems = useCallback(async (loadMoreItems: boolean = false) => {
        try {
            setLoading(true)
            const transactionResponse = await getTransactions(STATISTICS_LIMIT_ITEMS, loadMoreItems ? lastItemId : null, queryParameters);
            setTransactions(prev => [...prev, ...transactionResponse.data])
            setTotalItems(transactionResponse.total)
            setLastItemId(transactionResponse.lastItemId)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }, [getTransactions, lastItemId, queryParameters])

    useEffect(() => {
        getTransactionItems().catch()
        return () => {
            setTransactions([])
            setLastItemId(null)
            setTotalItems(0)
        }
    }, [queryParameters]);

    const onSearchCollection = useCallback((hits: any[]) => {
        if (hits.length > 0) {
            setQueryParameters((prev: TransactionParameters) => {
                return {
                    ...prev,
                    collectionIds: hits.map((item: any) => item.objectID)
                }
            })
        } else {
            setQueryParameters((prev: TransactionParameters) => {
                return {
                    ...prev,
                    collectionIds: []
                }
            })
        }
    }, [])

    const onChangeType = useCallback((value: string | string[]) => {
        if (!Array.isArray(value)) {
            setQueryParameters(prev => ({...prev, type: value as TransactionType}))
        }
    }, [])

    const onChangeParameters = useCallback((value: string, name: string) => {
        if (name === 'number' || name === "nftTokenId") {
            setQueryParameters({...queryParameters, [name]: +value})
        } else {
            setQueryParameters({...queryParameters, [name]: value})
        }
    }, [queryParameters])

    return (
        <AppScaffold menuPosition={"relative"} menuStyle={"normal"}>
            <>
            <Helmet titleTemplate="Videomuse - %s">
                <title>{t('pages.statistics.title')}</title>
                <meta name="description" content={t('pages.statistics.description')}/>
            </Helmet>
            <div className={"py-28 px-20 text-center bg-registerPage bg-cover bg-top"}>
                    <h1 className={"h1 text-white pb-4"}>{t("pages.statistics.title")}</h1>
                </div>
                <section className={"app-container py-20"}>
                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8"}>
                        <InputNumber
                            placeholder={t("placeholders.transactionNumber")}
                            name={"number"}
                            value={queryParameters.number?.toString() || ""}
                            onChange={onChangeParameters}/>
                        <InputNumber
                            placeholder={t("placeholders.nftTokenId")}
                            name={"nftTokenId"}
                            value={queryParameters.nftTokenId?.toString() || ""}
                            onChange={onChangeParameters}/>
                        <CollectionSearch onSearch={onSearchCollection}/>
                        <Dropdown
                            name={"type"}
                            multiple={false}
                            options={transactionTypeAsOption}
                            value={queryParameters.type ? [queryParameters.type] : []}
                            onChange={onChangeType}/>
                    </div>
                    {loading ? <GridLoader visible/> :
                        <div className={"w-full flex flex-col items-center justify-center"}>
                            <TransactionTable transactions={transactions}/>
                        </div>}
                    {!!transactions.length && <div className={"py-6 flex flex-col items-center justify-center"}>
                      <Button disabled={transactions.length >= totalItems}
                              onClick={() => getTransactionItems(true)}>{t("actions.loadMore")}</Button>
                    </div>}
                </section>
            </>
        </AppScaffold>
    )
};

export default ScreenStatistics;