import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {deleteUser, getAuth, onAuthStateChanged, signOut, User} from "firebase/auth";
import useUserQueries from "../hooks/useUserQueries";
import {UserData, UserType} from "../types/UserData.type";
import {useSDK} from "@metamask/sdk-react";

type UserProviderProps = {
    children: JSX.Element
}

interface UserContextData {
    user: UserData | null | undefined
    setUser: (user: any) => void
    isLogged: boolean,
    connectedToMetamask: undefined | boolean,
    onDeleteUser: () => Promise<boolean>
}

const UserContext = createContext<UserContextData>(undefined as any)

export function useUserContext() {
    return useContext(UserContext)
}

const auth = getAuth()

const UserProvider = ({children}: UserProviderProps) => {

    const {getUserData, storeWalletAddress, deleteSomePersonalDataFromUser, storeUserData, updateUserData} = useUserQueries()

    const [user, setUser] = useState<UserData | null | undefined>(null)
    const [metamaskAccount, setMetamaskAccount] = useState<string | undefined>()
    const [isLogged, setIsLogged] = useState<boolean>(false)

    const {connected: connectedToMetamask, account} = useSDK()

    useEffect(() => {
        const unsubscribe = onAuthStateChangeListener()
        return () => {
            unsubscribe()
        }
    }, []);

    useEffect(() => {
        if (connectedToMetamask && !metamaskAccount) {
            setMetamaskAccount(account)
        }
    }, [connectedToMetamask]);

    useEffect(() => {
        if (metamaskAccount && !!user && !user?.wallet) {
            storeWalletAddress(metamaskAccount, user.uid).catch((e) => console.error(e))
        }
    }, [metamaskAccount, user]);

    const onAuthStateChangedCallback = useCallback(async (authUser: User | null): Promise<void> => {
        try {
            //console.log('authUser', authUser)
            if (authUser && authUser.emailVerified) {
                setIsLogged(true)

                if (!user) {
                    const userData = await getUserData(authUser.uid)
                    //console.log('userData', userData)
                    if (userData) {
                        setUser(userData)
                        if(!userData.emailVerified) {
                            updateUserData({...userData, emailVerified: authUser.emailVerified, active: true}, userData.uid)
                        }
                    } else {
                        await storeUserData({
                            fullName: authUser.displayName || authUser.email || "",
                            username: authUser.displayName || authUser.email || "",
                            email: authUser.email || "",
                            phone: authUser.phoneNumber || "",
                            avatar: authUser.photoURL || "",
                            userType: UserType.DEFAULT,
                            likes: [],
                            purchases: [],
                            following: [],
                            followers: [],
                            created: new Date(),
                            emailVerified: authUser.emailVerified,
                            active: authUser.emailVerified // We activate the user if the email is verified
                        }, authUser.uid)
                        const newUserData = await getUserData(authUser.uid)
                        //console.log('newUserData', newUserData)
                        setUser(newUserData)
                    }
                }
            } else {
                setIsLogged(false)
                setUser(undefined)
            }
        } catch (e) {
            console.error(e)
        }

    }, [getUserData, storeUserData, user])


    const onAuthStateChangeListener = () => {
        return onAuthStateChanged(auth, onAuthStateChangedCallback)
    }

    const onDeleteUser = useCallback(async () => {
        try {
            const auth = getAuth();
            const userAuth = auth.currentUser;

            if (userAuth) {
                await deleteSomePersonalDataFromUser(userAuth.uid)
                await signOut(auth)
                await deleteUser(userAuth)
                return Promise.resolve(true)
            }
            return Promise.reject(false)
        } catch (error: any) {
            console.error(error)
            return Promise.reject(error)
        }
    }, [deleteSomePersonalDataFromUser])

    return <UserContext.Provider value={{
        user,
        setUser,
        isLogged,
        connectedToMetamask,
        onDeleteUser
    }}>{children}</UserContext.Provider>
}

export default UserProvider;