import {useCallback} from "react";
import {NFT} from "../types/NFT.type";
import {UserData, UserPurchase} from "../types/UserData.type";
import {addDoc, collection, DocumentReference, serverTimestamp} from "firebase/firestore";
import {db} from "../firebase";

export default function useUserPurchaseNFTQueries() {

    const collectioName = "userPurchases"

    const createUserPurchase = useCallback(async (nftId: NFT['id'], userId: UserData['uid']): Promise<UserPurchase['id']> => {
        try {
            const doc: DocumentReference = await addDoc(collection(db, collectioName), {
                userId,
                nftId,
                created: serverTimestamp(),
            })
            return Promise.resolve(doc.id)
        } catch (error) {
            console.log("error", error)
            return Promise.reject(error)
        }
    }, [])

    return {createUserPurchase}

}