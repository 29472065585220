import InputText from "../../../components/ui/Inputs/InputText";
import {RegisterState} from "../ScreenRegister";
import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../components/ui/Buttons/Button";
import {InputError} from "../../../types/InputError.types";
import Dropdown, {DropdownOption} from "../../../components/ui/Inputs/Dropdown";
import {UserType} from "../../../types/UserData.type";
import {useAppContext} from "../../../providers/AppProvider";

type RegisterFormProps = {
    registerState: RegisterState,
    setRegisterState: (state: (prev: RegisterState) => any) => void,
    onSubmit: () => void,
    formErrors: InputError[]
}

const RegisterForm = ({registerState, setRegisterState, onSubmit, formErrors}: RegisterFormProps) => {

    const {appLoading} = useAppContext()
    const {t} = useTranslation()

    const userTypeAsOptions: DropdownOption[] = useMemo(() => {
        return Object.values(UserType).map((val) => {
            return {label: t(`userTypes.${val}`), value: val}
        })
    }, [t])

    const onChangeUserType = useCallback((value: string | string[]) => {
        if (!Array.isArray(value)) {
            setRegisterState(prev => ({...registerState, userType: value as UserType}))
        }
    }, [registerState, setRegisterState])

    const onInputChange = useCallback((value: string, name: string) => {
        setRegisterState(prev => ({...prev, [name]: value}))
    }, [setRegisterState])

    return <div className={"py-10"}>
        <form onSubmit={(event) => event.preventDefault()}>
            <div className={"grid md:grid-cols-2 gap-6 pb-6"}>
                <InputText type={"text"}
                           name={"name"}
                           label={t("inputs.name")}
                           placeholder={t("inputs.name")}
                           value={registerState.name}
                           error={formErrors.find(e => e.name === 'name')}
                           onChange={onInputChange}/>
                <InputText type={"email"}
                           name={"email"}
                           label={t("inputs.email")}
                           placeholder={t("inputs.email")}
                           required
                           error={formErrors.find(e => e.name === 'email')}
                           value={registerState.email}
                           onChange={onInputChange}/>
                <InputText type={"text"}
                           name={"username"}
                           label={t("inputs.registerUsername")}
                           placeholder={t("inputs.registerUsername")}
                           value={registerState.username}
                           error={formErrors.find(e => e.name === 'username')}
                           onChange={onInputChange}/>
                <InputText type={"text"}
                           name={"phone"}
                           label={t("inputs.phone")}
                           placeholder={t("inputs.phone")}
                           value={registerState.phone}
                           error={formErrors.find(e => e.name === 'phone')}
                           onChange={onInputChange}/>
                <InputText type={"password"}
                           name={"password"}
                           label={t("inputs.password")}
                           placeholder={t("inputs.password")}
                           value={registerState.password}
                           error={formErrors.find(e => e.name === 'password')}
                           onChange={onInputChange}/>
                <InputText type={"password"}
                           name={"passwordConfirm"}
                           label={t("inputs.passwordConfirm")}
                           placeholder={t("inputs.passwordConfirm")}
                           value={registerState.passwordConfirm}
                           error={formErrors.find(e => e.name === 'passwordConfirm')}
                           onChange={onInputChange}/>
                <Dropdown name={"type"}
                          required={true}
                          multiple={false}
                          label={t("inputs.userType")}
                          options={userTypeAsOptions}
                          value={registerState.userType ? [registerState.userType] : []}
                          onChange={onChangeUserType}/>
            </div>
            <Button onClick={onSubmit} disabled={appLoading} size={"md"}>{t("inputs.register")}</Button>
        </form>
    </div>
}

export default RegisterForm