import AppScaffold from '../../components/ui/AppScaffold'
import {useTranslation} from 'react-i18next'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import InputText from '../../components/ui/Inputs/InputText'
import {Button} from '../../components/ui/Buttons/Button'
import {Fragment, useCallback, useEffect, useMemo, useState} from 'react'

import {
    deleteUser,
    getAuth,
    GoogleAuthProvider,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut, User,
    UserCredential
} from "firebase/auth"
import {FirebaseError} from "@firebase/util"
import i18n from "../../i18n";
import {object, string, ValidationError} from "yup";
import {InputError} from "../../types/InputError.types";
import {parseYupErrors} from "../../utils/parseYupErrors.js";
import {toast} from "react-toastify";
import {UserType} from "../../types/UserData.type";
import useUserQueries from "../../hooks/useUserQueries";
import AppDialog from "../../components/ui/AppDialog/AppDialog";
import {useUserContext} from "../../providers/UserProvider";
import {useAppContext} from "../../providers/AppProvider";

type LoginState = {
    email: string,
    password: string
}

type RecoverPasswordState = {
    email: string
}

const initialLoginState = {
    email: "",
    password: ""
}

const initialRecoverPasswordState = {
    email: ""
}

const googleProvider = new GoogleAuthProvider();

const ScreenLogin = () => {

    const {user, isLogged} = useUserContext()

    const [loginState, setLoginState] = useState<LoginState>(initialLoginState)
    const [recoverPasswordState, setRecoverPasswordState] = useState<RecoverPasswordState>(initialRecoverPasswordState)
    const [showRecoverPassword, setShowRecoverPassword] = useState<boolean>(false)
    const [formErrors, setFormErrors] = useState<InputError[]>([])


    const navigate = useNavigate()
    const {t} = useTranslation()
    const [searchParams] = useSearchParams()

    const {userHasData, storeUserData, updateUserData} = useUserQueries()
    const {setAppLoading} = useAppContext()

    const auth = getAuth()
    auth.languageCode = i18n.language

    const schema = object().shape({
        email: string().email().required(),
        password: string().required().min(6)
    })

    const recoverPasswordSchema = object().shape({
        email: string().email().required()
    })

    useEffect(() => {
        setAppLoading(false)
        if (isLogged && !!user?.uid) {
            navigate("/account")
        }
    }, [user?.uid, navigate, isLogged, user, setAppLoading])

    const submitDisabled = useMemo(() => {
        return loginState.email === "" || loginState.password === ""
    }, [loginState.password, loginState.email])

    const onChangeInput = useCallback((value: string, name: string) => {
        setLoginState(prev => ({...prev, [name]: value}))
    }, [])

    const onChangeRecoverPasswordInput = useCallback((value: string, name: string) => {
        setRecoverPasswordState(prev => ({...prev, [name]: value}))
    }, [])

    const onLoginWithPassword = useCallback(async () => {
        try {
            setAppLoading(true)
            setFormErrors([])
            schema.validateSync(loginState, {abortEarly: false})
            const userCredential: UserCredential = await signInWithEmailAndPassword(auth, loginState.email, loginState.password)
            const loggedUser: User = userCredential.user

            if (searchParams.has("deleteAccount")) {
                if (searchParams.get("deleteAccount") === loggedUser.uid) {
                    await signOut(auth)
                    await deleteUser(loggedUser)
                    navigate("/")
                    setAppLoading(false)
                    return
                }
            }

            console.log('loggedUser', loggedUser)

            if (loggedUser.emailVerified) {
                setLoginState(initialLoginState)
            } else {
                await sendEmailVerification(loggedUser)
                await signOut(auth)
                setAppLoading(false)
                toast(t("messages.emailNotVerified"), {type: "error"})
            }
        } catch (e: unknown) {
            setAppLoading(false)
            if (e instanceof ValidationError) {
                setFormErrors(parseYupErrors(e))
            }
            if (e instanceof FirebaseError) {
                toast(t("firebase." + e.code), {type: "error"})
            }
        }
    }, [auth, loginState, navigate, schema, searchParams, setAppLoading, t])

    const onGoogleLogin = useCallback(async () => {
        try {
            setAppLoading(true)
            googleProvider.setCustomParameters({prompt: 'select_account'});
            const result: UserCredential = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            const hasData: boolean = await userHasData(user.uid)
            if (!hasData) {
                await storeUserData({
                    fullName: user.displayName || "",
                    username: user.displayName || "",
                    email: user.email || "",
                    phone: user.phoneNumber || "",
                    avatar: user.photoURL || "",
                    userType: UserType.DEFAULT,
                    likes: [],
                    purchases: [],
                    following: [],
                    followers: [],
                    created: new Date(),
                    emailVerified: user.emailVerified,
                    active: true
                }, user.uid)
            }

            if (searchParams.has("deleteAccount")) {
                if (searchParams.get("deleteAccount") === user.uid) {
                    await signOut(auth)
                    await deleteUser(user)
                    navigate("/")
                    setAppLoading(false)
                    return
                }
            }

        } catch (e: unknown) {
            console.error(e)
            setAppLoading(false)
            if (e instanceof FirebaseError) {
                toast(t("firebase." + e.code), {type: "error"})
            }
        }
    }, [auth, navigate, searchParams, setAppLoading, storeUserData, t, userHasData])

    const onRecoverPassword = useCallback(async () => {
        try {
            setFormErrors([])
            recoverPasswordSchema.validateSync(recoverPasswordState, {abortEarly: false})
            await sendPasswordResetEmail(auth, recoverPasswordState.email)
            toast(t("messages.passwordResetEmailSent"), {type: "success"})
        } catch (e: unknown) {
            console.error(e)
            if (e instanceof ValidationError) {
                setFormErrors(parseYupErrors(e))
            }
            if (e instanceof FirebaseError) {
                toast(t("firebase." + e.code), {type: "error"})
            }
        } finally {
            setShowRecoverPassword(false)
        }
    }, [auth, recoverPasswordSchema, recoverPasswordState, t])

    const LoginIntroduction = useMemo(() => {
        return <div className={"lg:w-5/12 transition duration-300 animate-wow"}>
            <h1 className={"h1 mb-4 text-white"}>{t("pages.login.section_title")}</h1>
            <p className={"text-white text-lg"}>{t("pages.login.section_description")}</p>
        </div>
    }, [t])

    const RecoverPassword = useMemo(() => {
        return (
            <div>
                <InputText name={"email"}
                           type={"email"}
                           value={recoverPasswordState.email}
                           error={formErrors.find(e => e.name === 'email')}
                           containerClasses={"mb-4"}
                           placeholder={t("inputs.email")}
                           required
                           onChange={onChangeRecoverPasswordInput}/>
                <Button extraClasses={"block w-full py-2"}
                        onClick={onRecoverPassword}
                        disabled={!recoverPasswordState.email}>{t("actions.requestPassword")}</Button>
            </div>
        )
    }, [formErrors, onChangeRecoverPasswordInput, onRecoverPassword, recoverPasswordState.email, t])

    const LoginForm = useMemo(() => {
        return <div className={"bg-white p-10 rounded-md lg:w-4/12 lg:mr-20"}>
            <h3 className={"h1 mb-4"}>{t("pages.login.form_title")}</h3>
            <p className={"text-gray-500 mb-4"}>{t("pages.login.form_description")} <Link to={'/register'}
                                                                                          className={"text-primary"}>{t("global.here")}</Link>.
            </p>
            <form className={"mb-6"} onSubmit={(ev) => ev.preventDefault()}>
                <InputText name={"email"}
                           type={"email"}
                           value={loginState.email}
                           error={formErrors.find(e => e.name === 'email')}
                           containerClasses={"mb-4"}
                           placeholder={t("inputs.email")}
                           required
                           onChange={onChangeInput}/>
                <InputText name={"password"}
                           type={"password"}
                           error={formErrors.find(e => e.name === 'password')}
                           value={loginState.password}
                           containerClasses={"mb-4"}
                           placeholder={t("inputs.password")}
                           required
                           onChange={onChangeInput}/>
                <Button extraClasses={"block w-full py-2"}
                        onClick={onLoginWithPassword}
                        disabled={submitDisabled}>{t("actions.submit")}</Button>
            </form>

            <p className={"mb-4"}><span className={"mr-1"}>{t("pages.login.forgotPasswordQuestion")}</span>
                <button className={"text-primary font-bold inline-block"}
                        onClick={() => setShowRecoverPassword(true)}>{t("actions.recoverPasswordHere")}</button>
            </p>
            <div className={"flex flex-row items-center"}>
                <p className={"text-gray-500 mr-1"}>{t('actions.loginWith')}:</p>
                <button className={"text-primary font-bold"} onClick={onGoogleLogin}>Google</button>
            </div>
        </div>
    }, [t, loginState.email, loginState.password, formErrors, onChangeInput, onLoginWithPassword, submitDisabled, onGoogleLogin])

    return <AppScaffold menuStyle={"inverted"}>
        <Fragment>
            <div className={"bg-loginPage bg-no-repeat bg-left bg-cover min-h-screen"}>
                <div className={"app-container flex flex-col items-center justify-center gap-6 lg:gap-0 lg:flex-row lg:justify-between lg:items-center min-h-screen "}>
                    {LoginIntroduction}
                    {LoginForm}
                </div>
            </div>
            <AppDialog title={t("dialogs.recoverPassword.title")}
                       description={t("dialogs.recoverPassword.description")}
                       isOpen={showRecoverPassword}
                       onClose={() => setShowRecoverPassword(false)}>
                {RecoverPassword}
            </AppDialog>
        </Fragment>
    </AppScaffold>

}

export default ScreenLogin;