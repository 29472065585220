import {Listbox} from "@headlessui/react";
import {useCallback, useMemo} from "react";
import {InputError} from "../../../types/InputError.types";
import Label from "./Label";
import {BiCheck} from "react-icons/bi";
import {FaChevronDown} from "react-icons/fa";
import {useTranslation} from "react-i18next";

export type DropdownOption = {
    label: string
    value: string
}

type DropdownProps = {
    name: string,
    multiple: boolean,
    label?: string,
    options: DropdownOption[],
    placeholder?: string,
    value: string[],
    onChange: (value: string[] | string, name: string) => void,
    error?: InputError | undefined,
    inputClasses?: string,
    containerClasses?: string,
    required?: boolean,
}

const Dropdown = ({
                      name,
                      multiple = false,
                      label,
                      options = [],
                      value = [],
                      onChange,
                      error,
                      placeholder = "",
                      inputClasses = '',
                      containerClasses = '',
                      required = false
                  }: DropdownProps) => {

    const {t} = useTranslation()

    const onChangeInput = useCallback((value: string[]) => {
        onChange(value, name);
    }, [name, onChange])

    const renderValue: string = useMemo(() => {
        let result = ""
        if (multiple && Array.isArray(value)) {
            value.forEach((item: string, index: Number) => {
                const option = options.find((option: DropdownOption) => option.value === item)
                if (option) {
                    result += option.label + (index === value.length - 1 ? "" : ", ")
                }
            })
        } else {
            const option = options.find((option: DropdownOption) => option.value === value[0])
            if (option) {
                result = option.label
            }
        }
        return result;
    }, [multiple, options, value])

    return (<div className={`${containerClasses} relative min-w-[250px]`}>
            <Listbox name={name}
                     value={value}
                     onChange={onChangeInput}
                     multiple={multiple}>
                {label ? <Label requiredInput={required} htmlFor={'input-' + name}>{label}</Label> : false}
                <Listbox.Button className={`flex flex-row justify-between items-center p-2 px-3 text-left border ${!!error ? 'border-red-500' : 'border-gray-200'} placeholder:text-gray-500 rounded-md h-auto text-gray-900 block w-full ${inputClasses}`}>
                    <div className={"mr-2"}>{value.length > 0 ? renderValue : (placeholder ?
                        <span className={"text-gray-500"}>{placeholder}</span> : <span className={"text-gray-500"}>{t("placeholders.selectItem")}</span>)}
                    </div>
                    <FaChevronDown size={18} className={"text-gray-500"}/>
                </Listbox.Button>
                <Listbox.Options className={"shadow-lg rounded-lg mt-1 absolute w-full z-40 bg-white"}>
                    {options.map((item: DropdownOption) => {
                        return <Listbox.Option as={"div"}
                                               className={"cursor-pointer"}
                                               key={item.value}
                                               value={item.value}>
                            {({active, selected}) => (
                                <div
                                    className={`py-2 px-4 flex flex-row items-center border-b border-gray-200 ${
                                        selected ? 'bg-primary text-white' : 'bg-white text-primary'
                                    } hover:bg-primary hover:text-white`}
                                >
                                    {selected && <BiCheck size={18} className={"mr-1"}/>}
                                    {item.label}
                                </div>
                            )}

                        </Listbox.Option>
                    })}
                </Listbox.Options>
            </Listbox>
        </div>
    )
}

export default Dropdown;