import {NFT} from "./NFT.type";
import {User} from "firebase/auth";
import {ListWithPagination} from "./Lists.type";
import {Timestamp} from "firebase/firestore";
import {Collection} from "./Collection.type";
import {UserData} from "./UserData.type";

export enum TransactionType {
    CREATE = "CREATE",
    BUY = "BUY"
}

type TransactionInterface = {
    id: string;
    num: number;
    nftId: NFT['id']
    nftTokenId: NFT['tokenId']
    collectionId: Collection['id'] | null
    collection?: Collection
    type: TransactionType
    price: number
    buyer?: User['uid']
    sellerUid: User['uid']
    seller?: UserData
    date: Timestamp
}

export type Transaction = TransactionInterface;

export type TransactionState = Omit<Transaction, 'id' | 'num' | 'date' | 'collection'>

export type TransactionList = Omit<ListWithPagination, "data"> & { data: Transaction[] }

export type TransactionParameters = {
    number?: Transaction['num'] | null,
    nftTokenId?: Transaction['nftTokenId'] | null,
    collectionIds?: Transaction['collectionId'][] | null,
    type?: TransactionType | null,
}