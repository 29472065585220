import {useTranslation} from "react-i18next";

const Footer = () => {

    const {t} = useTranslation()

    return <footer className={"border-t py-10"}>
        <div className={"app-container border-b border-gray-200 pb-10 mb-10"}>
            <div className={"flex flex-col items-center"}>
                <p className={"font-bold text-black text-lg mb-6 text-center"}>{t("global.partners")}</p>
                <div className={"flex flex-row flex-wrap items-center justify-center gap-6"}>
                    <img src={require('./../../../images/footer/melboss.png')}
                         alt={"melboss music"}
                         className={"h-10"}/>
                    <img src={require('./../../../images/footer/mtu.png')} alt={"MTU"} className={"h-10"}/>
                    <img src={require('./../../../images/footer/laval.png')} alt={"Laval"} className={"h-10"}/>
                    <img src={require('./../../../images/footer/dex.png')} alt={"Dex"} className={"h-10"}/>
                    <img src={require('./../../../images/footer/medula.png')} alt={"Medula"} className={"h-10"}/>
                    <img src={require('./../../../images/footer/aim.png')} alt={"Aim"} className={"h-10"}/>
                </div>
            </div>
        </div>
        <div className={"app-container flex flex-row justify-between items-center flex-wrap"}>
            <img src={require('./../../../images/footer/eu_logo.png')} alt={"EU"} className={"h-16"} />
            <p>
                Copyright © {new Date().getFullYear()} videomuse. Powered by <a href={"https://www.melboss.com/"} className={"text-primary"} target={"_blank"}>Melboss Music</a>
            </p>
        </div>
    </footer>
}

export default Footer;