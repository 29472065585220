import {FormEvent, useCallback, useEffect, useState} from "react";
import {InputError} from "../../../types/InputError.types";
import Label from "./Label";

type InputNumberProps = {
    name: string,
    value: string,
    placeholder?: string,
    label?: string,
    inputClasses?: string,
    containerClasses?: string,
    required?: boolean,
    onChange: (value: string, name: string) => void,
    error?: InputError | undefined,
    min?: number,
    max?: number
    step?: number | string,
    helpText?: string
}

const InputNumber = ({
                         name,
                         value,
                         label,
                         onChange,
                         placeholder,
                         required = false,
                         inputClasses = '',
                         containerClasses = '',
                         error = undefined,
                         min = 0,
                         max = 100000,
                         step = 'any',
                         helpText = undefined
                     }: InputNumberProps) => {

    const [inputState, setInputState] = useState<string>(value)

    useEffect(() => {
        setInputState(value)
    }, [value])

    const onChangeInput = useCallback((event: FormEvent<HTMLInputElement>) => {
        setInputState(event.currentTarget.value);
    }, [])

    const onEndEditing = useCallback(() => {
        onChange(inputState, name);
    }, [inputState, name, onChange])

    return <div className={`${containerClasses}`}>
        {label ? <Label requiredInput={required} htmlFor={'input-' + name}>{label}</Label> : false}
        <input type={'number'}
               name={name}
               id={'input-' + name}
               value={inputState}
               placeholder={placeholder}
               onChange={onChangeInput}
               required={required}
               onBlur={onEndEditing}
               min={min}
               max={max}
               step={step}
               className={`p-2 border ${!!error ? 'border-red-500' : 'border-gray-200'} placeholder:text-gray-500 focus:border-0 focus:ring-0 focus:ring-offset-0 ring-0 rounded-md h-auto text-gray-900 block w-full ${inputClasses}`}/>
        {!!error ? <p className={"text-xs text-red-500 mt-1"}>{error.message}</p> : null}
        {!!helpText ? <p className={"text-xs text-gray-500 mt-1"}>{helpText}</p> : null}
    </div>
}

export default InputNumber;