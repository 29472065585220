import {useMemo} from "react";

export default function useMenu() {

    const menuItems = useMemo(() => [
        {
            name: "nav.home",
            url: "/",
        },
        {
            name: "nav.collections",
            url: "/collections",
            children: [
                {
                    name: "nav.explore",
                    url: "/explore"
                },
                {
                    name: "nav.collections",
                    url: "/collections"
                },
            ]
        },
        {
            name: "nav.account",
            url: "/account",
            children: [
                {
                    name: "nav.account",
                    url: '/account',
                    restricted: true
                },
                {
                    name: "nav.create",
                    url: '/create-nft',
                    restricted: true
                },
                {
                    name: "nav.login",
                    url: '/login',
                    hideWhenLogged: true
                },
                {
                    name: "nav.register",
                    url: '/register',
                    hideWhenLogged: true
                },
                {
                    name: "nav.logout",
                    url: '/logout',
                    restricted: true
                }
            ]
        },
        {
            name: "nav.statistics",
            url: "/statistics"
        }
    ], [])

    return {menuItems}

}