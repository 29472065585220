import AppScaffold from "../../components/ui/AppScaffold";
import {Fragment, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Collection, CollectionQueryParameters} from "../../types/Collection.type";
import useNFTCollectionQueries from "../../hooks/useNFTCollectionQueries";
import GridLoader from "../../components/ui/Loaders/GridLoader";
import CollectionItem from "../../components/ui/Collection/CollectionItem";
import {Button} from "../../components/ui/Buttons/Button";
import CollectionSearch from "../../components/search/CollectionSearch";
import {Helmet} from "react-helmet";

const initialQueryParameters: CollectionQueryParameters = {
    searchIds: [],
}


const COLLECTION_LIMIT_ITEMS = 8

const ScreenCollections = () => {

    const {t} = useTranslation()

    const {getCommunityCollections} = useNFTCollectionQueries()

    const [collections, setCollections] = useState<Collection[]>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const [lastItemId, setLastItemId] = useState<Collection['id'] | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingMoreItems, setLoadingMoreItems] = useState<boolean>(false)
    const [queryParameters, setQueryParameters] = useState<CollectionQueryParameters>(initialQueryParameters)

    const getCollections = useCallback(async (loadMoreItems: boolean = false) => {
        try {
            if (!loadMoreItems) {
                setLoading(true)
            } else {
                setLoadingMoreItems(true)
            }
            const collectionResponse = await getCommunityCollections(COLLECTION_LIMIT_ITEMS, loadMoreItems ? lastItemId : null, queryParameters)
            setCollections(prev => [...prev, ...collectionResponse.data])
            setTotalItems(collectionResponse.total)
            setLastItemId(collectionResponse.lastItemId)
        } catch (error) {
            console.log("error", error)
        } finally {
            setLoading(false)
            setLoadingMoreItems(false)
        }
    }, [getCommunityCollections, lastItemId, queryParameters])

    const onSearch = useCallback((hits: any[]) => {
        if (hits.length > 0) {
            setQueryParameters((prev: any) => {
                return {
                    ...prev,
                    searchIds: hits.map((item: any) => item.objectID)
                }
            })
        } else {
            setQueryParameters((prev: any) => {
                return {
                    ...prev,
                    searchIds: []
                }
            })
        }
    }, [])

    useEffect(() => {
        getCollections().catch()
        return () => {
            setCollections([])
            setTotalItems(0)
            setLastItemId(null)
        }
    }, [queryParameters]);

    const EmptyComponent = () => {
        return <div className={"w-full bg-primary/10 p-6 rounded-md my-10 flex flex-col items-center justify-center"}>
            <p>{t("messages.emptyNftExplore")}</p>
        </div>
    }

    return (
        <AppScaffold menuPosition={"relative"} menuStyle={"normal"}>
            <Fragment>
                <Helmet titleTemplate="Videomuse - %s">
                    <title>{t('pages.collections.title')}</title>
                    <meta name="description" content={t('pages.collections.description')}/>
                </Helmet>
                <div className={"py-28 px-20 text-center bg-createPage bg-cover bg-top"}>
                    <h1 className={"h1 text-white pb-4"}>{t("pages.collections.title")}</h1>
                </div>
                <section className={"app-container py-10"}>
                    <div className={"py-6 flex flex-col lg:flex-row gap-4"}>
                        <CollectionSearch onSearch={onSearch}/>
                    </div>
                    <div>
                        {loading ? <GridLoader visible/> : (
                            collections.length > 0 ?
                                (<div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-6"}>
                                    {collections.map((item: Collection) => <CollectionItem item={item}
                                                                                           key={item.id}/>)}
                                </div>) : <EmptyComponent/>
                        )}
                        {loadingMoreItems ? <GridLoader visible/> : null}
                        {!!collections.length && <div className={"py-6 flex flex-col items-center justify-center"}>
                          <Button disabled={collections.length >= totalItems || loadingMoreItems}
                                  id={"load-more-button"}
                                  onClick={() => getCollections(true)}>{t("actions.loadMore")}</Button>
                        </div>}
                    </div>
                </section>

            </Fragment>
        </AppScaffold>
    )
}

export default ScreenCollections;