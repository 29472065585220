import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";

i18n.use(initReactI18next)
    .use(I18NextHttpBackend)
    .init({
        lng: "en"
    })

export default i18n;