import {Link} from "react-router-dom";
import {Menu, Transition} from "@headlessui/react";
import {FaChevronDown} from "react-icons/fa";
import i18n from "../../../i18n";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../providers/UserProvider";
import useMetamaskConnection from "../../../hooks/useMetamaskConnection";
import {MdMenu, MdWallet} from "react-icons/md";
import useMenu from "../../../hooks/useMenu";
import {MenuItemType} from "../../../types/menu.types";
import {useCallback} from "react";
import {toast} from "react-toastify";
import {useWeb3Context} from "../../../providers/Web3Provider";

type MenuItemProps = {
    item: MenuItemType,
}
export type MainMenuProps = {
    style?: 'normal' | 'inverted',
    onToggleCollapsibleMenu: () => void
}

const MainMenu = ({style = 'normal', onToggleCollapsibleMenu}: MainMenuProps) => {

    const {accountBalance} = useWeb3Context()
    const {isLogged} = useUserContext()
    const {t} = useTranslation()

    const {connectToMetamask, connectedToMetamask} = useMetamaskConnection()

    const {menuItems} = useMenu()

    const MenuItem = ({item}: MenuItemProps) => {
        return !item?.children?.length ? <li key={item.name}>
            <Link className={`text-sm block font-bold py-7 px-5 ${style === 'normal' ? 'text-black' : 'text-white'}`}
                  to={item.url}>{t(item.name)}</Link>
        </li> : <Menu as={"div"} className={"relative"}>
            <Menu.Button as={"div"}
                         className={`text-sm cursor-pointer font-bold py-7 px-5 flex flex-row items-center justify-between ${style === 'normal' ? 'text-black' : 'text-white'}`}>
                {t(item.name)}
                {!!item?.children?.length ? <FaChevronDown size={8} className={"ml-2"}/> : false}
            </Menu.Button>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Menu.Items as={"ul"}
                            className={"absolute left-5 w-56 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"}>
                    {item.children.filter((subitem) => !isLogged ? !subitem.restricted : !subitem.hideWhenLogged)
                        .map((subitem: MenuItemType) =>
                            <Menu.Item as={"li"} key={subitem.url} className={"text-sm"}>
                                <Link to={subitem.url}
                                      className={"py-2 px-4 block text-[#606060] hover:bg-primary hover:text-white"}>{t(subitem.name)}</Link>
                            </Menu.Item>)}
                </Menu.Items>
            </Transition>
        </Menu>
    }

    const onConnectWallet = useCallback(async () => {
        try {
            await connectToMetamask()
        } catch (e) {
            console.error(e)
        }
    }, [connectToMetamask])

    return (
        <div className={"flex flex-row lg:flex-row lg:items-center py-6 lg:py-0"}>
            <nav className={"hidden lg:block"}>
                <ul className={"flex lg:flex-row lg:items-center"}>
                    {menuItems.map((item) => <MenuItem item={item} key={item.url}
                    />)}
                </ul>
            </nav>
            {!connectedToMetamask && <button onClick={onConnectWallet}
                                             className={"mr-2 lg:mr-0 lg:ml-5 text-sm text-white font-bold bg-primary hover:bg-primaryDark focus:bg-primaryDark transition-colors ease-in-out duration-200 py-1.5 px-5 rounded-md"}>
              <MdWallet className={"block lg:hidden"} size={18}/>
              <span className={"hidden lg:block"}>{i18n.t("nav.connectWallet")}</span>
            </button>}
            {connectedToMetamask && <div className={"text-sm text-white font-bold bg-primary py-1.5 px-5 rounded-md"}>
                {accountBalance > 0 ? accountBalance.toFixed(2) : 0} MATIC
            </div>}
            <button onClick={onToggleCollapsibleMenu} className={"p-2 block lg:hidden"}>
                <MdMenu size={24} className={style === 'normal' ? "text-black" : "text-white"}/>
            </button>
        </div>
    );
};

export default MainMenu;