import AppScaffold from '../../components/ui/AppScaffold'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/ui/Buttons/Button'
import CollectionsCarousel from './components/CollectionsCarousel'
import NewMuseCarousel from './components/NewMuseCarousel'
import FeaturedVideomakers from './components/FeaturedVideomakers'
import FeaturedArtists from './components/FeaturedArtists'
import CategoryBrowser from './components/CategoryBrowser'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const ScreenFront = () => {

  const {t} = useTranslation()

  const FrontIntroduction = () => {
    return <section className={"lg:w-6/12 md:pt-20"}>
      <h6 className={"uppercase text-primary font-bold mb-4"}>{t("pages.front.section_site_name")}</h6>
      <h1 className={"h1 mb-4 animate-wow-up"}>{t("pages.front.section_title")}</h1>
      <p className={"text-lg text-gray-500 mb-6 animate-wow-up"} dangerouslySetInnerHTML={{__html: t("pages.front.section_description")}} />
      <Link to={"/explore"} className={"animate-wow-up"}><Button>{t("global.explore")}</Button></Link>
    </section>
  }

  return <AppScaffold menuPosition={"relative"} menuStyle={"normal"} mainClasses={"bg-frontPage bg-no-repeat bg-top"}>
    <Helmet titleTemplate="Videomuse - %s">
      <title>{t('pages.front.title')}</title>
      <meta name="description" content={t('pages.front.description')}/>
    </Helmet>
    <div className={'app-container md:bg-frontSection bg-no-repeat bg-right md:bg-right-top md:bg-[length:50%] min-h-screen flex flex-col justify-center md:justify-center'}>
      <FrontIntroduction />
    </div>
    <CollectionsCarousel />
    <NewMuseCarousel />
    <div className={"app-container flex flex-col lg:flex-row justify-between"}>
      <div className={"lg:w-6/12"}>
        <FeaturedVideomakers />
      </div>
      <div className={"lg:w-6/12"}>
        <FeaturedArtists />
      </div>
    </div>
    <CategoryBrowser />
  </AppScaffold>
}

export default ScreenFront;