import useAlgoliaSearch from "../../hooks/useAlgoliaSearch";
import React, {useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import InputText from "../ui/Inputs/InputText";
import {debounce} from "../../utils/debounce";

type NFTSearchProps = {
    onSearch: (hits: any) => void
}

const collectionIndex = 'nft_index'

const NFTSearch = ({onSearch}: NFTSearchProps) => {

    const {t} = useTranslation()

    const [keywords, setKeywords] = useState<string>("");

    const {getIndex} = useAlgoliaSearch()

    const onSearchItem = useCallback(async (searchTerm: string) => {
        try {
            const index = await getIndex(collectionIndex);
            const results = await index.search(searchTerm);
            onSearch(results.hits);
            return results.hits;
        } catch (error) {
            console.error(error);
            return [];
        }
    }, [getIndex, onSearch]);

    const debouncedSearch = useRef(debounce(onSearchItem, 800)).current;

    const onChangeInput = useCallback(async (value: string, name: string) => {
        try {
            setKeywords(value);
            if (value.length > 2) {
                debouncedSearch(value)
            } else if (value.length === 0){
                onSearch([])
            }
        } catch (error) {
            console.error(error);
        }
    }, [debouncedSearch, onSearch]);

    return (
        <div>
            <InputText type={"search"}
                       name={"keywords"}
                       placeholder={t("placeholders.NFTsearch")}
                       value={keywords || ''}
                       onChange={onChangeInput}/>
        </div>
    )

}

export default NFTSearch