import {FormEvent, useCallback, useEffect, useState} from "react";
import {InputError} from "../../../types/InputError.types";
import Label from "./Label";
import {FaSearch} from "react-icons/fa";

type InputTextProps = {
    type: "text" | "search" | "email" | "password" | "number",
    name: string,
    value: string,
    placeholder?: string,
    label?: string,
    inputClasses?: string,
    containerClasses?: string,
    required?: boolean,
    onChange: (value: string, name: string) => void,
    onEndEditing?: (value: string, name: string) => void,
    error?: InputError | undefined,
    min?: number,
    max?: number
    step?: number | string,
    helpText?: string
}

const InputText = ({
                       type,
                       name,
                       value,
                       label,
                       onChange,
                       onEndEditing,
                       placeholder,
                       required = false,
                       inputClasses = '',
                       containerClasses = '',
                       error = undefined,
                       min = 0,
                       max = 100000,
                       step = 'any',
                       helpText = undefined
                   }: InputTextProps) => {

    const [inputValue, setInputValue] = useState<string>(value)

    useEffect(() => {
        setInputValue(value)
    }, [value])

    const onChangeInput = useCallback((event: FormEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value, name);
        setInputValue(event.currentTarget.value)
    }, [name, onChange])

    const onBlur = useCallback((event: FormEvent<HTMLInputElement>) => {
        onEndEditing && onEndEditing(inputValue, name);
    }, [inputValue, name, onEndEditing])

    return <div className={`min-w-[280px] ${containerClasses}`}>
        {label ? <Label requiredInput={required} htmlFor={'input-' + name}>{label}</Label> : false}
        <div className={`${!!error ? 'border-red-500' : 'border-gray-200'} p-2 px-2 border flex flex-row items-center justify-between rounded-md ${inputClasses}`}>
            {type === 'search' ? <FaSearch className={"mr-2"}/> : null}
            <input type={type}
                   name={name}
                   id={'input-' + name}
                   value={inputValue || value}
                   placeholder={placeholder}
                   onChange={onChangeInput}
                   required={required}
                   min={min}
                   max={max}
                   step={step}
                   onBlur={onBlur}
                   className={`placeholder:text-gray-500 border-0 shadow-none outline-0 h-auto focus:border-0 focus:ring-0 focus:ring-offset-0 ring-0 text-gray-900 block w-full`}/>
        </div>

        {!!error ? <p className={"text-xs text-red-500 mt-1"}>{error.message}</p> : null}
        {!!helpText ? <p className={"text-xs text-gray-500 mt-1"}>{helpText}</p> : null}
    </div>
}

export default InputText;