import {Fragment, useEffect} from "react";
import {useUserContext} from "../../providers/UserProvider";
import {useNavigate} from "react-router-dom";

type ProtectedPageProps = {
    children: JSX.Element

}

const ProtectedPage = ({children}: ProtectedPageProps) => {

    const {user} = useUserContext()
    const navigate = useNavigate()

    useEffect(() => {
        if (user === undefined) {
            navigate('/login')
        }
    }, [navigate, user]);

    return <Fragment>
        {children}
    </Fragment>
}

export default ProtectedPage;