import {FileRejection, FileWithPath, useDropzone} from "react-dropzone";
import {Fragment, useCallback, useState} from "react";
import {getStorage, ref, uploadBytes} from "firebase/storage";
import {Button} from "../../../ui/Buttons/Button";
import {IoMdTrash} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../../providers/UserProvider";
import {InputError} from "../../../../types/InputError.types";

type CollectionCoverDropzoneProps = {
    setFilePreview: (file: FileWithPath | undefined) => void,
    onUploadFile: (filePath: string) => void,
    setFormErrors: (errors: { name: string, message: string }[]) => void,
    formErrors: InputError[],
    filePreview: FileWithPath | undefined
}

const storage = getStorage()

const CollectionCoverDropzone = ({setFilePreview, onUploadFile, setFormErrors, formErrors, filePreview}: CollectionCoverDropzoneProps) => {

    const {user} = useUserContext()
    const {t} = useTranslation()

    const [disableDropzone, setDisableDropzone] = useState<boolean>(false)

    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        try {
            // Do something with the files
            setFilePreview(acceptedFiles[0])
            setDisableDropzone(true)
            const filePath = `user/${user?.uid}/collections/${acceptedFiles[0].name}`
            const metadata = {
                contentType: acceptedFiles[0].type
            }
            const storageRef = ref(storage, filePath);
            uploadBytes(storageRef, acceptedFiles[0], metadata).then((snapshot) => {
                onUploadFile(filePath)
                //setFormState({...formState, filePath: filePath})
            })
        } catch (e) {
            console.log("error", e)
            setDisableDropzone(false)
        }

    }, [onUploadFile, setFilePreview, user?.uid])

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            const firstError = fileRejections[0].errors[0].message
            if (!!firstError) {
                setFormErrors([{name: "filePath", message: firstError}])
            }
            setDisableDropzone(false)
        }
    }, [setFormErrors])

    const {getRootProps, getInputProps, open} = useDropzone({
        onDrop, onDropRejected: onDropRejected, accept: {
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
        }, maxSize: 5 * 1000000, maxFiles: 1, disabled: disableDropzone
    })

    const onRemoveFilePreview = useCallback(() => {
        // If file exists, remove it from storage
        setFilePreview(undefined)
        setDisableDropzone(false)
    }, [setFilePreview])

    const FilePreview = ({file}: { file: FileWithPath }) => {

        const preview = URL.createObjectURL(file)

        return file.path ? <div className={"text-center flex items-center justify-center"}>
            <div className={"relative"}>
                <button onClick={onRemoveFilePreview} className={"absolute -top-5 -right-5"}>
                    <IoMdTrash size={24} className={"text-red-600"}/>
                </button>
                <img src={preview}
                     alt={file?.name}
                     onLoad={() => URL.revokeObjectURL(preview)}
                     className={"h-20 w-auto"}/>
            </div>
        </div> : null
    }

    return <div>
        <div {...getRootProps({className: 'dropzone', id: 'collection-dropzone'})} className={"p-16 text-center border-4 border-dashed rounded-lg"}>
            {!!filePreview ? <FilePreview file={filePreview}/> : <Fragment><input {...getInputProps()} />
                <p className={"mb-4"}>{t("placeholders.uploadCollectionCover")}</p>
                <Button size={"lg"} onClick={open}>{t("actions.browse")}</Button></Fragment>}
        </div>
        {!!formErrors.find(e => e.name === 'file') ?
            <p className={"text-xs text-red-500 mt-1"}>{formErrors.find(e => e.name === 'filePath')?.message}</p> : null}
    </div>

}

export default CollectionCoverDropzone