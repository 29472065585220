import {useTranslation} from "react-i18next";
import useUserQueries from "../../../hooks/useUserQueries";
import {useCallback, useLayoutEffect, useState} from "react";
import {UserData, UserType} from "../../../types/UserData.type";
import GridLoader from "../../../components/ui/Loaders/GridLoader";
import AuthorHorizontalPreview from "../../../components/ui/Author/AuthorHorizontalPreview";

const FeaturedArtists = () => {

    const {t} = useTranslation()
    const {getSomeUsersByType} = useUserQueries()

    const [items, setItems] = useState<UserData[]>([])
    const [itemsLoading, setItemsLoading] = useState<boolean>(true)

    useLayoutEffect(() => {
        getItems().catch()
    }, []);

    const getItems = useCallback(async () => {
        try {
            setItemsLoading(true)
            const response = await getSomeUsersByType(UserType.MUSICIAN, 6)
            console.log("response", response)
            setItems(response)
        } catch (error) {
            console.log("error", error)
        } finally {
            setItemsLoading(false)
        }
    }, [getSomeUsersByType])

    return (
        <div>
            <h2 className={"text-center relative mb-6"}>
                {t("global.musicians")}
                <span className={"absolute -bottom-6 left-1/2 -translate-x-1/2 w-20 h-0.5 bg-primary"}/>
            </h2>
            {!itemsLoading ? <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 py-6"}>
                {items.length > 0 ? items.map((item, index) => <AuthorHorizontalPreview key={item.uid} user={item}/>) : null}
            </div> : <GridLoader/>}
        </div>
    )
};

export default FeaturedArtists;