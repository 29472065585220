import {AppLogState} from "../types/Log.type";
import {addDoc, collection, DocumentReference, serverTimestamp} from "firebase/firestore";
import {db} from "../firebase";

const COLLECTION_NAME = 'logs'

export default function useLogs() {

    const storeLog = async (log: AppLogState) => {
        try {

            console.log("log", log)

            const docRef: DocumentReference = await addDoc(collection(db, COLLECTION_NAME), {
                ...log,
                createdAt: serverTimestamp()
            })

            return Promise.resolve(docRef.id)

        } catch (error) {
            console.log("error", error)
            return Promise.reject(error)
        }
    }

    return {storeLog}

}