import AppDialog from "../../../components/ui/AppDialog/AppDialog";
import {useTranslation} from "react-i18next";
import {Button} from "../../../components/ui/Buttons/Button";

type AuthorDeleteAccountDialogProps = {
    isOpen: boolean,
    onClose: () => void,
    onDeleteUserAccount: () => void
}

const AuthorDeleteAccountDialog = ({isOpen, onClose, onDeleteUserAccount}: AuthorDeleteAccountDialogProps) => {

    const {t} = useTranslation()

    return (
        <AppDialog isOpen={isOpen}
                   onClose={onClose}
                   title={t('dialogs.authorDeleteAccount.title')}
                   description={t('dialogs.authorDeleteAccount.description')}>
            <div className={"flex flex-row justify-end gap-6"}>
                <Button onClick={onClose}
                        extraClasses={"bg-transparent border border-primary !text-primary"}>{t('actions.cancel')}</Button>
                <Button onClick={onDeleteUserAccount}>{t('actions.removeAccount')}</Button>
            </div>
        </AppDialog>
    )
}

export default AuthorDeleteAccountDialog;