import {Collection, CollectionState} from "../../../types/Collection.type";
import AppDialog from "../../../components/ui/AppDialog/AppDialog";
import FormCollection from "../../../components/forms/collection/FormCollection";
import {useCallback, useState} from "react";
import {InputError} from "../../../types/InputError.types";
import {object, string, ValidationError} from "yup";
import {parseYupErrors} from "../../../utils/parseYupErrors.js";
import {useAppContext} from "../../../providers/AppProvider";
import useNFTCollectionQueries from "../../../hooks/useNFTCollectionQueries";

type CollectionEditDialogProps = {
    collectionId: Collection['id'],
    isOpen: boolean,
    initialUserData: CollectionState
    onClose: () => void,
    refetchData: () => Promise<void>
}

const CollectionEditDialog = ({collectionId, isOpen, initialUserData, onClose, refetchData}: CollectionEditDialogProps) => {

    const {setAppLoading} = useAppContext()

    const {updateCollection} = useNFTCollectionQueries()

    const [formState, setFormState] = useState<CollectionState>(initialUserData)
    const [formErrors, setFormErrors] = useState<InputError[]>([])

    const schema = object().shape({
        name: string().required().min(3),
    })

    const onSubmit = useCallback(async () => {
        try {
            setAppLoading(true)
            schema.validateSync(formState, {abortEarly: false})
            await updateCollection(collectionId, formState)
            onClose()
            await refetchData()
        } catch (error) {
            console.error(error)
            if (error instanceof ValidationError) {
                setFormErrors(parseYupErrors(error))
            }
        } finally {
            setAppLoading(false)
        }
    }, [collectionId, formState, onClose, refetchData, schema, setAppLoading, updateCollection])

    return (
        <AppDialog isOpen={isOpen} onClose={onClose}>
            <FormCollection formState={formState}
                            setFormState={setFormState}
                            formErrors={formErrors}
                            onSubmit={onSubmit}
                            setFormErrors={setFormErrors}/>
        </AppDialog>
    )
}

export default CollectionEditDialog