import {Fragment, useCallback, useState} from "react";
import {UserDataPayload, UserType} from "../../../types/UserData.type";
import FormUserData from "../../../components/forms/userData/FormUserData";
import {object, string, ValidationError} from "yup";
import {parseYupErrors} from "../../../utils/parseYupErrors.js";
import {InputError} from "../../../types/InputError.types";
import useUserQueries from "../../../hooks/useUserQueries";
import {User} from "firebase/auth";
import {useAppContext} from "../../../providers/AppProvider";
import AppDialog from "../../../components/ui/AppDialog/AppDialog";
import {useTranslation} from "react-i18next";

type AuthorEditDialogProps = {
    isOpen: boolean,
    initialUserData: UserDataPayload
    userId: User['uid'],
    onClose: () => void,
    refetchData: () => Promise<void>,
    onOpenDeleteAccount: () => void
}

const AuthorEditDialog = ({
                              isOpen,
                              initialUserData,
                              userId,
                              onClose,
                              refetchData,
                              onOpenDeleteAccount
                          }: AuthorEditDialogProps) => {

    const {t} = useTranslation()
    const {setAppLoading} = useAppContext()
    const {updateUserData} = useUserQueries()

    const [userState, setUserState] = useState<UserDataPayload>(initialUserData)
    const [formErrors, setFormErrors] = useState<InputError[]>([])

    const schema = object().shape({
        fullName: string().required().min(4),
        username: string().required().min(4),
        phone: string().nullable().optional(),
        userType: string().required().oneOf([UserType.DEFAULT, UserType.MUSICIAN, UserType.VIDEOMAKER]),
    })

    const onSubmit = useCallback(async () => {
        try {
            setAppLoading(true)
            schema.validateSync(userState, {abortEarly: false})
            await updateUserData(userState, userId)
            await refetchData()
            onClose()
        } catch (error) {
            console.error(error)
            if (error instanceof ValidationError) {
                setFormErrors(parseYupErrors(error))
            }
        } finally {
            setAppLoading(false)
        }
    }, [onClose, refetchData, schema, setAppLoading, updateUserData, userId, userState])

    return (
        <AppDialog isOpen={isOpen}
                   onClose={onClose}
                   title={t('dialogs.authorEdit.title')}
                   description={t('dialogs.authorEdit.description')}>
            <Fragment>
                <FormUserData formState={userState}
                              setFormState={setUserState}
                              onSubmit={onSubmit}
                              formErrors={formErrors}
                              setFormErrors={setFormErrors}
                />
                <div className={"w-full flex items-center justify-center mt-2"}>
                    <button className={"text-red-400 font-bold text-sm"} onClick={onOpenDeleteAccount}>{t("actions.removeAccount")}</button>
                </div>
            </Fragment>
        </AppDialog>
    )
};

export default AuthorEditDialog;