import {FileRejection, FileWithPath, useDropzone} from "react-dropzone";
import {Fragment, useCallback, useMemo, useState} from "react";
import {getDownloadURL, getStorage, ref, uploadBytesResumable, UploadTask} from "firebase/storage";
import {Button} from "../../../ui/Buttons/Button";
import {IoMdTrash} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../../providers/UserProvider";
import {InputError} from "../../../../types/InputError.types";

const FILE_MAX_SIZE_MB = 5

type VideoCoverDropzoneProps = {
    setFilePreview: (file: FileWithPath | undefined) => void,
    onUploadFile: (filePath: string) => void,
    setFormErrors: (errors: { name: string, message: string }[]) => void,
    formErrors: InputError[],
    filePreview: FileWithPath | undefined
}

const storage = getStorage()

const VideoCoverDropzone = ({
                                setFilePreview,
                                onUploadFile,
                                setFormErrors,
                                formErrors,
                                filePreview
                            }: VideoCoverDropzoneProps) => {

    const {user} = useUserContext()
    const {t} = useTranslation()

    const [disableDropzone, setDisableDropzone] = useState<boolean>(false)
    const [percentageProgress, setPercentageProgress] = useState<number>(0)
    const [uploadTask, setUploadTask] = useState<UploadTask>()

    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        try {
            // Do something with the files
            setFormErrors([])
            setFilePreview(acceptedFiles[0])
            setDisableDropzone(true)
            const filePath = `user/${user?.uid}/covers/${acceptedFiles[0].name}`
            const metadata = {
                contentType: acceptedFiles[0].type
            }
            const storageRef = ref(storage, filePath);
            const uploadBytesTask = uploadBytesResumable(storageRef, acceptedFiles[0], metadata)
            setUploadTask(uploadBytesTask)

            uploadBytesTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setPercentageProgress(progress)
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            }, (error) => {
                error.code === 'storage/unauthorized' ? setFormErrors([{
                    name: "coverPath",
                    message: t("messages.unauthorized")
                }]) : setFormErrors([{name: "coverPath", message: t("messages.fileError")}])
                setDisableDropzone(false)
            }, () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadBytesTask.snapshot.ref).then((downloadURL) => {
                    onUploadFile(downloadURL)
                });
            });
        } catch (e) {
            console.log("error", e)
            setDisableDropzone(false)
        }

    }, [onUploadFile, setFilePreview, setFormErrors, t, user?.uid])

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        if (fileRejections.length > 0) {
            const firstError = fileRejections[0].errors[0]
            if (!!firstError) {
                switch (firstError.code) {
                    case "file-invalid-type":
                        setFormErrors([{
                            name: "coverPath",
                            message: t("messages.fileInvalidType", {extensions: ".mp4"})
                        }])
                        break
                    case "file-too-large":
                        setFormErrors([{
                            name: "coverPath",
                            message: t("messages.fileTooLarge", {size: FILE_MAX_SIZE_MB + "MB"})
                        }])
                        break
                    default:
                        setFormErrors([{name: "coverPath", message: t("messages.fileError")}])
                        break
                }
            }
            setDisableDropzone(false)
        }
    }, [setFormErrors, t])

    const {getRootProps, getInputProps, open} = useDropzone({
        onDrop, onDropRejected: onDropRejected, accept: {
            "image/*": [".jpg", ".jpeg", ".png"],
        }, maxSize: FILE_MAX_SIZE_MB * 1000000, maxFiles: 1, disabled: disableDropzone
    })

    const onRemoveFilePreview = useCallback(() => {
        // If file exists, remove it from storage
        setFilePreview(undefined)
        setDisableDropzone(false)
        setPercentageProgress(0)
        if (uploadTask) {
            uploadTask.cancel()
        }
    }, [setFilePreview, uploadTask])

    const FilePreview = useMemo(() => {

        if (!filePreview) {
            return null
        }
        const preview = URL.createObjectURL(filePreview)

        return preview ? <div className={"text-center flex items-center justify-center"}>
            <div className={"relative"}>
                <button onClick={onRemoveFilePreview} className={"absolute -top-5 -right-5"}>
                    <IoMdTrash size={24} className={"text-red-600"}/>
                </button>
                <img src={preview}
                     alt={filePreview?.name}
                     onLoad={() => URL.revokeObjectURL(preview)}
                     className={"h-20 w-auto"}/>
            </div>
        </div> : null
    }, [filePreview, onRemoveFilePreview])

    return <div>
        <div {...getRootProps({
            className: 'dropzone',
            id: 'video-cover-dropzone'
        })} className={"p-16 text-center border-4 border-dashed rounded-lg"}>
            {!!filePreview ? FilePreview : <Fragment><input {...getInputProps()} />
                <p className={"mb-4"}>{t("placeholders.uploadVideoCover")}</p>
                <Button size={"lg"} onClick={open}>{t("actions.browse")}</Button></Fragment>}
        </div>
        <div className={"flex flex-col lg:flex-row justify-between mt-1"}>
            {!!formErrors.find(e => e.name === 'coverPath') ?
                <p className={"text-xs text-red-500 w-full"}>{formErrors.find(e => e.name === 'coverPath')?.message}</p> : null}
            <p className={"text-xs text-right text-primaryDark w-full"}>{percentageProgress > 0 && percentageProgress < 100 && t("messages.uploadProgresss", {progress: Number(percentageProgress).toFixed(0)})}</p>
        </div>
    </div>

}

export default VideoCoverDropzone