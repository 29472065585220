import {Transaction} from "../../../types/Statistics.type";
import {useCallback} from "react";
import {Timestamp} from "firebase/firestore";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

type TransactionTableProps = {
    transactions: Transaction[]
};

const TransactionTable = ({transactions}: TransactionTableProps) => {

    const {t} = useTranslation()

    const formatDate = useCallback((date: Timestamp) => {
        if (date) {
            return date.toDate().toLocaleDateString() + " " + date.toDate().toLocaleTimeString()
        }
        return ""
    }, []);

    const formatCollection = useCallback((transaction: Transaction) => {
        if (transaction.collection) {
            return <Link className={"text-primary"}
                         to={"/collections/" + transaction.collectionId}>{transaction.collection.name}</Link>
        } else {
            return <Link className={"text-primary"} to={"/explore"}>{t("global.defaultCollectionName")}</Link>
        }
    }, [t])

    const formatSeller = useCallback((transaction: Transaction) => {
        return <Link className={"text-primary"}
                     to={"/author/" + transaction.sellerUid}>{transaction.seller?.username}</Link>
    }, [])

    return (
        <div className={"w-full bg-white rounded-md"}>
            <table className={"w-full"}>
                <thead className={"uppercase text-sm"}>
                <tr className={"border-b-2"}>
                    <th className={"font-normal text-left p-2"}>{t("global.num")}</th>
                    <th className={"font-normal text-left  p-2"}>{t("global.tokenId")}</th>
                    <th className={"font-normal text-left p-2"}>{t("global.collection")}</th>
                    <th className={"font-normal text-left p-2"}>{t("global.seller")}</th>
                    <th className={"font-normal text-left p-2"}>{t("global.type")}</th>
                    <th className={"font-normal text-left p-2"}>{t("global.price")}</th>
                    <th className={"font-normal text-left p-2"}>{t("global.date")}</th>
                </tr>
                </thead>
                <tbody className={"font-bold text-lg text-black"}>
                {transactions.map((transaction) => (
                    <tr className={"border-b border-gray-200"} key={transaction.id}>
                        <td className={"p-2"}>{transaction.num}</td>
                        <td className={"p-2"}>{transaction.nftTokenId}</td>
                        <td className={"p-2 break-words"}>{formatCollection(transaction)}</td>
                        <td className={"p-2 break-words"}>{formatSeller(transaction)}</td>
                        <td className={"p-2"}>{transaction.type}</td>
                        <td className={"p-2"}>{transaction.price} MATIC</td>
                        <td className={"p-2"}>{formatDate(transaction.date)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
};

export default TransactionTable;