import AppScaffold from "../../components/ui/AppScaffold";
import {useTranslation} from "react-i18next";
import RegisterForm from "./components/RegisterForm";
import {Fragment, useCallback, useState} from "react";
import {object, ref, string, ValidationError} from "yup";
import {parseYupErrors} from "../../utils/parseYupErrors.js";
import {InputError} from "../../types/InputError.types";
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    User,
    UserCredential,
    sendEmailVerification, signOut
} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {UserDataPayload, UserType} from "../../types/UserData.type";
import useUserQueries from "../../hooks/useUserQueries";
import {FirebaseError} from "@firebase/util";
import {toast} from "react-toastify";
import {useAppContext} from "../../providers/AppProvider";

export type RegisterState = {
    name: string,
    username: string,
    email: string,
    phone: string,
    password: string,
    passwordConfirm: string
    userType: UserType
}

const initialRegisterState: RegisterState = {
    name: "",
    email: "",
    username: "",
    phone: "",
    password: "",
    passwordConfirm: "",
    userType: UserType.DEFAULT
}

const googleProvider = new GoogleAuthProvider();

const ScreenRegister = () => {

    const {setAppLoading} = useAppContext()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const auth = getAuth()

    const {storeUserData, userHasData} = useUserQueries()

    const [registerState, setRegisterState] = useState<RegisterState>(initialRegisterState)
    const [formErrors, setFormErrors] = useState<InputError[]>([])

    const schema = object().shape({
        name: string().required().min(4),
        email: string().email().required(),
        username: string().min(4),
        phone: string(),
        password: string().required().min(6),
        passwordConfirm: string().required().oneOf([ref('password')],'Passwords do not match')
    })

    const createUserData = useCallback(async (user: User): Promise<void> => {
        try {
            const userData: UserDataPayload = {
                userType: UserType.DEFAULT,
                fullName: registerState.name,
                username: registerState.username,
                email: registerState.email,
                phone: registerState.phone,
                likes: [],
                purchases: [],
                following: [],
                followers: [],
                emailVerified: false,
                created: new Date(),
                active: false
            }
            await storeUserData(userData, user.uid)
        } catch (e) {
            console.error(e)
        }
    }, [registerState.email, registerState.name, registerState.phone, registerState.username, storeUserData])

    const onRegisterCreate = useCallback(async () => {
        try {
            setAppLoading(true)
            setFormErrors([])
            schema.validateSync(registerState, {abortEarly: false})

            const {user} = await createUserWithEmailAndPassword(auth, registerState.email, registerState.password)

            await createUserData(user);
            await sendEmailVerification(user)

            toast(t("messages.emailVerificationSent"), {type: "success"})

            signOut(auth).then(() => {
                navigate("/login")
            })

        } catch (e){
            if(e instanceof ValidationError){
                setFormErrors(parseYupErrors(e))
            }
            if (e instanceof FirebaseError) {
                console.error(e.code)
                toast(t("firebase." + e.code), {type: "error"})
            }
        } finally {
            setAppLoading(false)
        }
    }, [setAppLoading, schema, registerState, auth, createUserData, navigate, t])

    const onGoogleLogin = useCallback(async () => {
        try {
            const result: UserCredential = await signInWithPopup(auth, googleProvider);
            const user = result.user;

            const hasData: boolean = await userHasData(user.uid)
            if (!hasData) {
                await storeUserData({
                    fullName: user.displayName || "",
                    username: user.displayName || "",
                    email: user.email || "",
                    phone: user.phoneNumber || "",
                    avatar: user.photoURL || "",
                    userType: UserType.DEFAULT,
                    likes: [],
                    purchases: [],
                    following: [],
                    followers: [],
                    created: new Date(),
                    emailVerified: user.emailVerified,
                    active: true
                }, user.uid)
            }

            navigate("/account")

        } catch (e: unknown) {
            console.error(e)
            if (e instanceof FirebaseError) {
                toast(t("firebase." + e.code), {type: "error"})
            }
        }
    }, [auth, navigate, storeUserData, t, userHasData])

    return <AppScaffold menuStyle={"inverted"}>
        <Fragment>
            <div className={"py-28 px-20 text-center bg-registerPage bg-cover bg-top"}>
                <h1 className={"h1 text-white pb-4"}>{t("pages.register.title")}</h1>
                <p className={"text-white"}>{t("pages.register.description")}</p>
            </div>
            <div className={"app-container py-24 lg:w-8/12 mx-auto"}>
                <h3 className={"h3 mb-4"}>{t('pages.register.form.title')}</h3>
                <p>{t('pages.register.form.description')}</p>
                <RegisterForm registerState={registerState}
                              setRegisterState={setRegisterState}
                              onSubmit={onRegisterCreate}
                              formErrors={formErrors}/>
                <div className={"flex flex-row flex-wrap items-center"}>
                    <p className={"text-gray-500 mr-3"}>Or register with:</p>
                    <button className={"text-primary font-bold"} onClick={onGoogleLogin}>Google</button>
                </div>
            </div>
        </Fragment>
    </AppScaffold>

}

export default ScreenRegister;