import {
    addDoc,
    arrayUnion,
    collection,
    doc, documentId,
    DocumentReference,
    DocumentSnapshot,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    orderBy,
    Query,
    query,
    QuerySnapshot,
    serverTimestamp,
    startAfter,
    updateDoc,
    where
} from "firebase/firestore";
import {db} from "../firebase";
import {Collection, CollectionList, CollectionState} from "../types/Collection.type";
import useUserQueries from "./useUserQueries";
import {UserData} from "../types/UserData.type";
import {NFT, NFTQueryParameters} from "../types/NFT.type";

const collectionName: string = "collections";

export default function useNFTCollectionQueries() {

    const {getUserData} = useUserQueries()

    const getUserCollections = async (userId: string, status = true): Promise<Collection[]> => {
        try {
            const q = query(
                collection(db, collectionName),
                where('userId', "==", userId),
                where('published', "==", status)
            );

            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.reject(null)
            }
            const collections: Collection[] = []
            querySnapshot.forEach((doc) => {
                collections.push({...doc.data(), id: doc.id} as Collection)
            });
            return Promise.resolve(collections)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const createCollection = async (item: CollectionState): Promise<string> => {
        try {
            try {
                const docRef: DocumentReference = await addDoc(collection(db, collectionName), {
                    ...item,
                    created: serverTimestamp(),
                    updated: serverTimestamp()
                });
                return Promise.resolve(docRef.id)
            } catch (error) {
                return Promise.reject(error)
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const updateCollection = async (collectionId: Collection['id'], collection: CollectionState): Promise<string> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, collectionId);
            await updateDoc(docRef, {
                ...collection,
                updated: serverTimestamp()
            });
            return Promise.resolve(collectionId)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const updateCollectionAddress = async (collectionId: Collection['id'], address: Collection['address']): Promise<string> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, collectionId);
            await updateDoc(docRef, {
                address,
                updated: serverTimestamp()
            });
            return Promise.resolve(collectionId)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getCollection = async (collectionId: Collection['id']): Promise<Collection> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, collectionId);
            const docSnap: DocumentSnapshot = await getDoc(docRef);
            if (docSnap.exists()) {
                const collectionWithAuthor = await assignUserDataToItem({
                    ...docSnap.data(),
                    id: docSnap.id
                } as Collection)
                return Promise.resolve(collectionWithAuthor)
            }
            return Promise.reject(null)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getCollectionsByIds = async (ids: Collection['id'][]): Promise<Collection[]> => {
        try {
            const collections: Collection[] = []

            if (!ids.length) {
                return Promise.resolve(collections)
            }

            const q = query(
                collection(db, collectionName),
                where(documentId(), 'in', ids)
            )
            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.reject(null)
            }
            querySnapshot.forEach((doc) => {
                collections.push({...doc.data(), id: doc.id} as Collection)
            });

            return Promise.resolve(collections)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const addNFTToCollection = async (collectionId: Collection['id'], nftId: NFT['id']): Promise<boolean> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, collectionId);
            await updateDoc(docRef, {
                nfts: arrayUnion(nftId)
            });
            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getCommunityCollections = async (limitItems: number | null = null, startAfterId: NFT['id'] | null = null, queryParameters: NFTQueryParameters | null = null): Promise<CollectionList> => {
        try {
            let q: Query = query(
                collection(db, collectionName),
                where('published', "==", true),
            );
            if (queryParameters) {
                if (queryParameters.searchIds?.length) {
                    q = query(q, where(documentId(), "in", queryParameters.searchIds))
                }
            }

            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            if(queryParameters?.searchIds?.length === 0){
                q = query(q, orderBy("created", "desc"))
            }

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            if (limitItems) {
                q = query(q, limit(limitItems))
            }

            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.reject(null)
            }
            const collections: Collection[] = []
            for (const doc of querySnapshot.docs) {
                const collection: Collection = {...doc.data(), id: doc.id} as Collection
                const collectionWithAuthor = await assignUserDataToItem(collection)
                if (!collectionWithAuthor?.filePath) {
                    const nft: NFT | null = await getFirstNFTCoverFromCollection(doc.id)
                    if (nft){
                        collectionWithAuthor.filePath = nft?.coverPath
                    }
                }
                collections.push(collectionWithAuthor)
            }

            return Promise.resolve({
                data: collections,
                total,
                lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id
            })
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const assignUserDataToItem = async (item: Collection): Promise<Collection> => {
        const author: UserData | null = await getUserData(item.userId)
        return Promise.resolve({...item, author})
    }

    const getFirstNFTCoverFromCollection = async (collectionId: Collection['id']): Promise<NFT|null> => {
        try {
            const q: Query = query(
                collection(db, "nfts"),
                where('collectionId', "==", collectionId),
                orderBy('created', 'asc'),
                limit(1)
            );

            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve(null)
            }
            const doc = querySnapshot.docs[0]
            const nft: NFT = {...doc.data(), id: doc.id} as NFT
            return Promise.resolve(nft)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        getUserCollections,
        createCollection,
        getCollection,
        updateCollection,
        getCommunityCollections,
        addNFTToCollection,
        getCollectionsByIds,
        updateCollectionAddress
    }
}