import {useCallback, useLayoutEffect, useMemo, useState} from "react";
import '@splidejs/react-splide/css';
import {Splide, SplideSlide} from "@splidejs/react-splide";
import useNFTQueries from "../../../hooks/useNFTQueries";
import {NFT, NFTList} from "../../../types/NFT.type";
import NFTItem from "../../../components/ui/NFT/NFTItem";
import GridLoader from "../../../components/ui/Loaders/GridLoader";

const ITEMS_CAROUSEL_LIMIT_ITEMS: number = 12

const NewMuseCarousel = () => {

    const {getCommunityNTFs} = useNFTQueries()

    const [items, setItems] = useState<NFT[]>([])

    const carouselSettings = useMemo(() => {
        return {
            rewind: true,
            dots: false,
            width: '100%',
            gap: '1.5rem',
            fixedWidth: '300px',
            breakpoints: {
                '640': {
                    gap: '1rem',
                },
                '960': {
                    gap: '1rem',
                },
            }
        }
    }, [])

    useLayoutEffect(() => {
        getItems().catch()
    }, []);

    const getItems = useCallback(async () => {
        try {
            const response: NFTList = await getCommunityNTFs(ITEMS_CAROUSEL_LIMIT_ITEMS)
            setItems(response.data)
        } catch (error) {
            console.log("error", error)
        }
    }, [getCommunityNTFs]);

    return (
        <section className={"app-container py-24 px-10"}>
            <h2 className={"text-center relative mb-6"}>
                New <span className={"text-primary"}>ClipMes</span>
                <span className={"absolute -bottom-6 left-1/2 -translate-x-1/2 w-20 h-0.5 bg-primary"}/>
            </h2>
            <div className={"py-10 slider-container"}>
                {items.length > 0 ? <Splide options={carouselSettings}>
                    {items.map((item: NFT, index) =>
                        <SplideSlide key={item.id}><NFTItem nft={item}/></SplideSlide>)}
                </Splide> : <GridLoader visible />}
            </div>
        </section>
    )
}

export default NewMuseCarousel