const Label = ({children, requiredInput = false, htmlFor = ""}: {
    children: JSX.Element | string,
    requiredInput: boolean,
    htmlFor: string
}) => {
    return <label
        htmlFor={htmlFor}
        className={"block text-lg font-bold mb-2 text-gray-900"}>{children} {requiredInput ? <sup>*</sup> : null}</label>
}

export default Label