import {NFT, NFTList, NFTQueryParameters, NFTState} from "../types/NFT.type";
import {
    addDoc,
    arrayUnion,
    collection,
    CollectionReference,
    doc,
    documentId,
    DocumentReference,
    DocumentSnapshot,
    getCountFromServer,
    getDoc,
    getDocs, increment,
    limit,
    or,
    orderBy,
    Query,
    query,
    QuerySnapshot,
    serverTimestamp,
    startAfter,
    updateDoc,
    where
} from "firebase/firestore";
import {db} from "../firebase";
import useUserQueries from "./useUserQueries";
import {UserData} from "../types/UserData.type";
import useNFTCollectionQueries from "./useNFTCollectionQueries";
import {Collection} from "../types/Collection.type";
import {User} from "firebase/auth";

const collectionName: string = "nfts";
const collectionUserLikesName: string = "userLikes";

export default function useNFTQueries() {

    const {getUserData, getUserDataByIds} = useUserQueries()
    const {getCollection, addNFTToCollection, getCollectionsByIds} = useNFTCollectionQueries()

    const getNTF = async (id: string): Promise<NFT | null> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, id);
            const docSnap: DocumentSnapshot = await getDoc(docRef);
            if (docSnap.exists()) {
                const nftResult = docSnap.data() as NFT
                // TODO: Improve this search result, two queries or one?
                const musician: UserData | null = await getUserData(nftResult.musicianUid)
                const videomaker: UserData | null = await getUserData(nftResult.videomakerUid)
                const collection: Collection | null = nftResult.collectionId ? await getCollection(nftResult.collectionId) : null
                return Promise.resolve({...nftResult, id: docSnap.id, collection, musician, videomaker})
            }
            return Promise.reject(null)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const createNTF = async (item: NFTState): Promise<string> => {
        try {
            const docRef: DocumentReference = await addDoc(collection(db, collectionName), {
                ...item,
                created: serverTimestamp(),
                updated: serverTimestamp(),
                boughtCount: 0,
            });

            if (item.collectionId) {
                await addNFTToCollection(item.collectionId, docRef.id)
            }

            return Promise.resolve(docRef.id)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const updateNFT = async (id: string, item: NFTState): Promise<string> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, id);
            await updateDoc(docRef, {
                ...item,
                updated: serverTimestamp()
            });
            return Promise.resolve(id)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getUserCreatedNTFs = async (userId: User['uid'], limitItems: number | null = null, startAfterId: NFT['id'] | null = null): Promise<NFTList> => {
        try {
            const docRef: CollectionReference = collection(db, collectionName);
            let q: Query = query(docRef, or(where("musicianUid", "==", userId), where("videomakerUid", "==", userId), where("userId", "==", userId)));

            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            q = query(q, orderBy("created", "desc"))

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            if (limitItems) {
                q = query(q, limit(limitItems))
            }

            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: total
                })
            } else {
                const nfts: NFT[] = [];
                const collectionIds = new Set<Collection['id']>()
                const userIds = new Set<UserData['uid']>()
                for (const doc of querySnapshot.docs) {
                    nfts.push({...doc.data(), id: doc.id} as NFT)
                    if (doc.data().collectionId) {
                        collectionIds.add(doc.data().collectionId)
                    }
                    userIds.add(doc.data().musicianUid)
                    userIds.add(doc.data().videomakerUid)
                }

                const nftsWithCollection = await mergeNFTWithCollection(nfts, Array.from(collectionIds))
                const nftsWithUserData = await mergeNFTWithUserData(nftsWithCollection, Array.from(userIds))
                return Promise.resolve({
                    data: nftsWithUserData,
                    lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id,
                    total: total
                })
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getUserOnSaleNFT = async (userId: User['uid'], limitItems: number | null = null, startAfterId: NFT['id'] | null = null): Promise<NFTList> => {
        try {
            const docRef: CollectionReference = collection(db, collectionName);
            let q: Query = query(docRef, where("userId", "==", userId), where("onSale", "==", true));

            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            q = query(q, orderBy("created", "desc"))

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            if (limitItems) {
                q = query(q, limit(limitItems))
            }

            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: total
                })
            } else {
                const nfts: NFT[] = [];
                const collectionIds = new Set<Collection['id']>()
                const userIds = new Set<UserData['uid']>()
                for (const doc of querySnapshot.docs) {
                    nfts.push({...doc.data(), id: doc.id} as NFT)
                    if (doc.data().collectionId) {
                        collectionIds.add(doc.data().collectionId)
                    }
                    userIds.add(doc.data().musicianUid)
                    userIds.add(doc.data().videomakerUid)
                }

                const nftsWithCollection = await mergeNFTWithCollection(nfts, Array.from(collectionIds))
                const nftsWithUserData = await mergeNFTWithUserData(nftsWithCollection, Array.from(userIds))
                return Promise.resolve({
                    data: nftsWithUserData,
                    lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id,
                    total: total
                })
            }
        } catch (error) {
            console.error("Error getting documents: ", error);
            return Promise.reject(error)
        }
    }

    const getUserLikedNFT = async (userId: User['uid'], limitItems: number | null = null, startAfterId: NFT['id'] | null = null): Promise<NFTList> => {
        try {
            const userLikeIds = collection(db, collectionUserLikesName)
            const userLikeQuery = query(userLikeIds, where("userId", "==", userId), orderBy('created', 'desc'))
            const userLikeSnapshot = await getDocs(userLikeQuery)
            const nftIds: string[] = []
            userLikeSnapshot.forEach((doc) => {
                nftIds.push(doc.data().nftId)
            })

            if (nftIds.length === 0) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: 0
                })
            }

            const docRef: CollectionReference = collection(db, collectionName);
            let q: Query = query(docRef, where(documentId(), 'in', nftIds));

            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            if (limitItems) {
                q = query(q, limit(limitItems))
            }

            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: total
                })
            } else {
                const nfts: NFT[] = [];
                const collectionIds = new Set<Collection['id']>()
                const userIds = new Set<UserData['uid']>()
                for (const doc of querySnapshot.docs) {
                    nfts.push({...doc.data(), id: doc.id} as NFT)
                    if (doc.data().collectionId) {
                        collectionIds.add(doc.data().collectionId)
                    }
                    userIds.add(doc.data().musicianUid)
                    userIds.add(doc.data().videomakerUid)
                }

                const nftsWithCollection = await mergeNFTWithCollection(nfts, Array.from(collectionIds))
                const nftsWithUserData = await mergeNFTWithUserData(nftsWithCollection, Array.from(userIds))
                return Promise.resolve({
                    data: nftsWithUserData,
                    lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id,
                    total: total
                })
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getUserPurchasedNFT = async (userId: User['uid'], limitItems: number | null = null, startAfterId: NFT['id'] | null = null): Promise<NFTList> => {
        try {
            const docRef: CollectionReference = collection(db, collectionName);
            let q: Query = query(docRef, where("userId", "==", userId), where("previousOwnerId", "!=", null));

            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            q = query(q, orderBy("created", "desc"))

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            if (limitItems) {
                q = query(q, limit(limitItems))
            }

            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: total
                })
            } else {

                const nfts: NFT[] = [];
                const collectionIds = new Set<Collection['id']>()
                const userIds = new Set<UserData['uid']>()
                for (const doc of querySnapshot.docs) {
                    nfts.push({...doc.data(), id: doc.id} as NFT)
                    if (doc.data().collectionId) {
                        collectionIds.add(doc.data().collectionId)
                    }
                    userIds.add(doc.data().musicianUid)
                    userIds.add(doc.data().videomakerUid)
                }

                const nftsWithCollection = await mergeNFTWithCollection(nfts, Array.from(collectionIds))
                const nftsWithUserData = await mergeNFTWithUserData(nftsWithCollection, Array.from(userIds))
                return Promise.resolve({
                    data: nftsWithUserData,
                    lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id,
                    total: total
                })
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const getCommunityNTFs = async (limitItems: number | null = null, startAfterId: NFT['id'] | null = null, queryParameters: NFTQueryParameters | null = null): Promise<NFTList> => {
        try {
            const docRef: CollectionReference = collection(db, collectionName);
            let q: Query = query(docRef,
                where("published", "==", true),
            );

            if (queryParameters) {
                if (queryParameters.categoryIds?.length) {
                    q = query(q, where("categories", "array-contains-any", queryParameters.categoryIds))
                }
                if (queryParameters.searchIds?.length) {
                    q = query(q, where(documentId(), "in", queryParameters.searchIds))
                }
            }

            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            if(queryParameters?.searchIds?.length === 0){
                q = query(q, orderBy("created", "desc"))
            }

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            if (limitItems) {
                q = query(q, limit(limitItems))
            }

            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: total
                })
            } else {
                const nfts: NFT[] = [];
                const collectionIds = new Set<Collection['id']>()
                const userIds = new Set<UserData['uid']>()
                for (const doc of querySnapshot.docs) {
                    nfts.push({...doc.data(), id: doc.id} as NFT)
                    if (doc.data().collectionId) {
                        collectionIds.add(doc.data().collectionId)
                    }
                    userIds.add(doc.data().musicianUid)
                    userIds.add(doc.data().videomakerUid)
                }

                const nftsWithCollection = await mergeNFTWithCollection(nfts, Array.from(collectionIds))
                const nftsWithUserData = await mergeNFTWithUserData(nftsWithCollection, Array.from(userIds))

                return Promise.resolve({
                    data: nftsWithUserData,
                    lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id,
                    total: total
                })
            }
        } catch (error) {
            console.error("Error getting documents: ", error);
            return Promise.reject(error)
        }
    }

    const getCollectionNFTs = async (collectionId: Collection['id'], limitItems: number | null = null, startAfterId: NFT['id'] | null = null): Promise<NFTList> => {
        try {
            const docRef: CollectionReference = collection(db, collectionName);
            let q: Query = query(docRef,
                where("published", "==", true),
                where("collectionId", "==", collectionId),
            );
            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            q = query(q, orderBy("created", "desc"))

            if (limitItems) {
                q = query(q, limit(limitItems))
            }

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: total
                })
            } else {
                const nfts: NFT[] = [];
                const collectionIds = new Set<Collection['id']>()
                const userIds = new Set<UserData['uid']>()
                for (const doc of querySnapshot.docs) {
                    nfts.push({...doc.data(), id: doc.id} as NFT)
                    if (doc.data().collectionId) {
                        collectionIds.add(doc.data().collectionId)
                    }
                    userIds.add(doc.data().musicianUid)
                    userIds.add(doc.data().videomakerUid)
                }

                const nftsWithCollection = await mergeNFTWithCollection(nfts, Array.from(collectionIds))
                const nftsWithUserData = await mergeNFTWithUserData(nftsWithCollection, Array.from(userIds))
                return Promise.resolve({
                    data: nftsWithUserData,
                    lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id,
                    total: total
                })
            }
        } catch (error) {
            console.error("Error getting documents: ", error);
            return Promise.reject(error)
        }
    }

    const changeNFtOnSale = async (nftId: NFT['id'], onSale: boolean): Promise<string> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, nftId);
            await updateDoc(docRef, {
                onSale: onSale
            });
            return Promise.resolve(nftId)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const changeItemPrice = async (nftId: NFT['id'], price: number): Promise<string> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, nftId);
            await updateDoc(docRef, {
                price: price
            });
            return Promise.resolve(nftId)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const assignNewOwnerToItem = async (nftId: NFT['id'], userId: UserData['uid']): Promise<boolean> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, nftId);
            const document: DocumentSnapshot = await getDoc(docRef)
            const currentUserId = document?.data()?.userId || null
            await updateDoc(docRef, {
                userId: userId,
                previousOwnerId: currentUserId,
                onSale: false,
                approved: false,
                boughtCount: increment(1)
            });
            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(false)
        }
    }

    const addNewVisit = async (nftId: NFT['id'], ip: string): Promise<boolean> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, nftId);
            await updateDoc(docRef, {
                views: arrayUnion(ip)
            });
            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(false)
        }
    }

    const storeNFTCollectionAddress = async (nftId: NFT['id'], collectionAddress: string): Promise<boolean> => {
        try {
            const docRef: DocumentReference = doc(db, collectionName, nftId);
            await updateDoc(docRef, {
                collectionAddress: collectionAddress
            });
            return Promise.resolve(true)
        } catch (error) {
            return Promise.reject(false)
        }
    }

    const mergeNFTWithCollection = async (nfts: NFT[], collectionIds: Collection['id'][]): Promise<NFT[]> => {
        try {
            const collections: Collection[] = await getCollectionsByIds(collectionIds)
            const nftsWithCollection = nfts.map((nft) => {
                const collection = collections.find((collection) => collection.id === nft.collectionId)
                return {...nft, collection}
            })
            return Promise.resolve(nftsWithCollection)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    const mergeNFTWithUserData = async (nfts: NFT[], userIds: UserData['uid'][]): Promise<NFT[]> => {
        try {
            const users: UserData[] = await getUserDataByIds(userIds)
            const nftsWithUserData = nfts.map((nft) => {
                const musician = users.find((user) => user.uid === nft.musicianUid)
                const videomaker = users.find((user) => user.uid === nft.videomakerUid)
                return {...nft, musician, videomaker} as NFT
            })
            return Promise.resolve(nftsWithUserData)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {
        createNTF,
        getNTF,
        getUserOnSaleNFT,
        getUserCreatedNTFs,
        getUserLikedNFT,
        updateNFT,
        getCommunityNTFs,
        getCollectionNFTs,
        changeNFtOnSale,
        assignNewOwnerToItem,
        getUserPurchasedNFT,
        addNewVisit,
        changeItemPrice,
        storeNFTCollectionAddress
    }
}