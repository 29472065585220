import {useTranslation} from "react-i18next";
import {useCallback, useLayoutEffect, useMemo, useState} from "react";
import {Collection} from "../../../types/Collection.type";
import useNFTCollectionQueries from "../../../hooks/useNFTCollectionQueries";
import CollectionItem from "../../../components/ui/Collection/CollectionItem";
import '@splidejs/react-splide/css';
import {Splide, SplideSlide} from "@splidejs/react-splide";
import GridLoader from "../../../components/ui/Loaders/GridLoader";

const COLLECTION_CAROUSEL_LIMIT_ITEMS: number = 8

const CollectionsCarousel = () => {

    const {t} = useTranslation()
    const {getCommunityCollections} = useNFTCollectionQueries()

    const [items, setItems] = useState<Collection[]>([])

    const carouselSettings = useMemo(() => {
        return {
            rewind: true,
            dots: false,
            width: '100%',
            gap: '1.5rem',
            fixedWidth: 'auto',
            breakpoints: {
                '640': {
                    gap: '1rem',
                },
                '960': {
                    gap: '1rem',
                },
            }
        }
    }, [])

    useLayoutEffect(() => {
        getItems().catch()
    }, []);

    const getItems = useCallback(async () => {
        try {
            const response = await getCommunityCollections(COLLECTION_CAROUSEL_LIMIT_ITEMS)
            setItems(response.data)
            console.log("getCommunityCollections", response.data)
        } catch (error) {
            console.log("error", error)
        }
    }, [getCommunityCollections]);

    return (
        <section className={"app-container py-24 px-10"}>
            <h2 className={"text-center relative mb-6"}>
                {t("global.collections")}
                <span className={"absolute -bottom-6 left-1/2 -translate-x-1/2 w-20 h-0.5 bg-primary"}/>
            </h2>
            <div className={"py-10 slider-container"}>
                {items.length > 0 ? <Splide options={carouselSettings}>
                        {items.map((item, index) =>
                            <SplideSlide key={item.id}><CollectionItem item={item}/></SplideSlide>)}
                </Splide> : <GridLoader visible />}
            </div>
        </section>
    )
}

export default CollectionsCarousel