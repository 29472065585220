import {InputError} from "../../../types/InputError.types";
import {CollectionState} from "../../../types/Collection.type";
import {Button} from "../../ui/Buttons/Button";
import InputText from "../../ui/Inputs/InputText";
import {useCallback, useState} from "react";
import CollectionCoverDropzone from "./CollectionCoverDropzone/CollectionCoverDropzone";
import {FileWithPath} from "react-dropzone";
import Label from "../../ui/Inputs/Label";
import {useTranslation} from "react-i18next";
import useNFTFileStorage from "../../../hooks/useNFTFileStorage";
import {UserDataPayload} from "../../../types/UserData.type";

type FormCollectionProps = {
    formState: CollectionState,
    setFormState: (formState: (prev: CollectionState) => any) => void,
    formErrors: InputError[],
    onSubmit: () => void,
    setFormErrors: (formErrors: InputError[]) => void,
}

const FormCollection = ({formState, setFormState, formErrors, onSubmit, setFormErrors}: FormCollectionProps) => {

    const {t} = useTranslation()

    const {getAbsoluteUrl} = useNFTFileStorage()

    const [filePreview, setFilePreview] = useState<FileWithPath | undefined>(undefined)

    const onChangeInput = useCallback((value: string, name: string) => {
        setFormState(prev => ({...prev, [name]: value}))
    }, [setFormState])

    const onUploadFile = useCallback(async (filePath: string) => {
        const absoluteUrl = await getAbsoluteUrl(filePath)
        if(absoluteUrl){
            setFormState(prev => ({...prev, filePath: absoluteUrl}))
        }
    }, [getAbsoluteUrl, setFormState])

    return (
        <div>
            <form onSubmit={(event) => event.preventDefault()}>
                <div className={"mb-4"}>
                    <Label requiredInput={false} htmlFor={""}>{t("inputs.collectionCover")}</Label>
                    <CollectionCoverDropzone setFilePreview={setFilePreview}
                                             onUploadFile={onUploadFile}
                                             setFormErrors={setFormErrors}
                                             formErrors={formErrors}
                                             filePreview={filePreview}/>
                </div>
                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6"}>
                    <InputText type={"text"}
                               name={"name"}
                               value={formState?.name || ""}
                               label={"Name"}
                               required
                               error={formErrors.find((error) => error.name === "name")}
                               onChange={onChangeInput}/>
                </div>

                <div className={"flex justify-end"}>
                    <Button onClick={onSubmit}>Save</Button>
                </div>
            </form>
        </div>
    )
}

export default FormCollection