import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {Contract, ethers} from "ethers";
import {Maybe} from "yup";
import {NFT, NFTState} from "../types/NFT.type";
import {abi_items, abi_market} from "../utils/abi";
import useNFTQueries from "../hooks/useNFTQueries";
import {User} from "firebase/auth";
import useUserPurchaseNFTQueries from "../hooks/useUserPurchaseNFTQueries";
import {Collection} from "../types/Collection.type";
import {formatEther, Logger, parseEther} from "ethers/lib/utils";
import {useSDK} from "@metamask/sdk-react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import useStatisticQueries from "../hooks/useStatisticQueries";
import {TransactionType} from "../types/Statistics.type";
import useLogs from "../hooks/useLogs";

type Web3ContextType = {
    provider: any | undefined,
    marketContract: Contract | undefined,
    createNFTForSale: (nft: NFTState, collection: Collection | null | undefined) => Promise<Maybe<any>>
    buyNFTItem: (nft: NFT) => Promise<Maybe<any>>,
    checkItemWasSold: (nft: NFT, userId: User['uid']) => Promise<boolean>,
    changeItemPrice: (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress'], price: number) => Promise<any>,
    sellItem: (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress'], price: number) => Promise<any>,
    cancelItemSell: (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress']) => Promise<any>,
    sendNFTCreatedToWallet: (nft: NFT) => Promise<boolean>,
    accountBalance: number,
    checkOwnerOfNFT: (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress'], address: string) => Promise<boolean>
}

type Web3ProviderProps = {
    children: JSX.Element
}

const Web3Context = createContext({} as Web3ContextType);

export function useWeb3Context() {
    return useContext(Web3Context);
}

const Web3Provider = ({children}: Web3ProviderProps) => {

    const {connected: connectedToMetamask, account, sdk} = useSDK()

    const {t} = useTranslation()

    const [connected, setConnected] = useState<boolean>(false)
    const [provider, setProvider] = useState<any>()
    const [marketContract, setMarketContract] = useState<Contract>()
    const [itemsContract, setItemsContract] = useState<Contract>()
    const [accountBalance, setAccountBalance] = useState<number>(0)

    const {assignNewOwnerToItem} = useNFTQueries()
    const {createUserPurchase} = useUserPurchaseNFTQueries()
    const {createTransaction} = useStatisticQueries()
    const {storeLog} = useLogs()

    const initWeb3Connection = useCallback(async (): Promise<Maybe<any>> => {
        try {

            if (typeof window.ethereum !== 'undefined') {
                const prov: ethers.providers.Web3Provider = new ethers.providers.Web3Provider(window.ethereum as any);
                const requestAccount = await prov.send("eth_requestAccounts", []);
                setProvider(prov);

                if (process.env.REACT_APP_MARKET_ADDR_CONTRACT) {
                    const marketContractResponse: Contract = new ethers.Contract(process.env.REACT_APP_MARKET_ADDR_CONTRACT, abi_market, prov);
                    setMarketContract(marketContractResponse);
                }
                if (process.env.REACT_APP_ITEMS_ADDR_CONTRACT) {
                    const itemsContractResponse: Contract = new ethers.Contract(process.env.REACT_APP_ITEMS_ADDR_CONTRACT, abi_items, prov);
                    setItemsContract(itemsContractResponse);
                }

                return Promise.resolve(requestAccount);
            } else {
                return Promise.reject("No ethereum provider available");
            }

        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }, [])

    const createNFTForSale = useCallback(async (nft: NFTState, collection: Collection | null | undefined): Promise<[tokenId: number, collectionAddress: string]> => {
        try {

            if (!connectedToMetamask) {
                toast(t("messages.metamaskNotConnected"), {type: "error"})
                return Promise.reject("Metamask not connected")
            }

            if (!provider) {
                return Promise.reject("No provider available");
            }
            if (!marketContract) {
                return Promise.reject("No marketContract available");
            }
            if (!process.env.REACT_APP_ITEMS_ADDR_CONTRACT) {
                return Promise.reject("No items contract address available");
            }

            let collectionAddress: string = process.env.REACT_APP_ITEMS_ADDR_CONTRACT;

            const signer = await provider.getSigner();

            const smartContractWithSigner: any = marketContract?.connect(signer)

            const price = parseEther(nft.price.toString())

            const itemUrl = `https://us-central1-videomuse-23616.cloudfunctions.net/displayNFTMetadata/${nft.uuid}`;

            let tokenId;
            let createItemResponse;
            let receipt;

            if (!collection) {

                const estimatedGas = await smartContractWithSigner.estimateGas.createItemForSale(
                    process.env.REACT_APP_ITEMS_ADDR_CONTRACT,
                    price,
                    nft.musicWallet,
                    nft.musicRoyalties * 100,
                    nft.videomakerWallet,
                    nft.videomakerRoyalties * 100,
                    nft.name,
                    nft.description,
                    itemUrl
                )

                const gasPrice = await provider.getGasPrice()

                createItemResponse = await smartContractWithSigner?.createItemForSale(
                    process.env.REACT_APP_ITEMS_ADDR_CONTRACT,
                    price,
                    nft.musicWallet,
                    nft.musicRoyalties * 100,
                    nft.videomakerWallet,
                    nft.videomakerRoyalties * 100,
                    nft.name,
                    nft.description,
                    itemUrl,
                    {
                        gasLimit: estimatedGas,
                        gasPrice: gasPrice
                    }
                )

                receipt = await createItemResponse.wait(1)
                //console.log("created itemId - " + receipt.events[2].args["itemId"]);
                tokenId = parseInt(receipt.events[2].args["itemId"]);
            } else if (collection.id && !collection.address) {
                // Create new collection and save in collection

                const symbol = `VM-${collection.name.substring(0, 3).toUpperCase()}`

                const estimatedGas = await smartContractWithSigner.estimateGas.createItemForSaleNewCollection(
                    collection.name,
                    symbol,
                    price,
                    nft.musicWallet,
                    nft.musicRoyalties * 100,
                    nft.videomakerWallet,
                    nft.videomakerRoyalties * 100,
                    nft.name,
                    nft.description,
                    itemUrl
                )

                const gasPrice = await provider.getGasPrice()

                createItemResponse = await smartContractWithSigner?.createItemForSaleNewCollection(
                    collection.name,
                    symbol,
                    price,
                    nft.musicWallet,
                    nft.musicRoyalties * 100,
                    nft.videomakerWallet,
                    nft.videomakerRoyalties * 100,
                    nft.name,
                    nft.description,
                    itemUrl,
                    {
                        gasLimit: estimatedGas,
                        gasPrice: gasPrice
                    }
                )
                receipt = await createItemResponse.wait(1)
                //console.log("created collection - " + receipt.events[3].args["collection"]);
                //console.log("created itemId - " + receipt.events[3].args["itemId"]);
                tokenId = parseInt(receipt.events[3].args["itemId"]);
                collectionAddress = receipt.events[3].args["collection"];
            } else if (collection.id && collection.address) {
                collectionAddress = collection.address

                const estimatedGas = await smartContractWithSigner.estimateGas.createItemForSale(
                    collection.address,
                    price,
                    nft.musicWallet,
                    nft.musicRoyalties * 100,
                    nft.videomakerWallet,
                    nft.videomakerRoyalties * 100,
                    nft.name,
                    nft.description,
                    itemUrl
                )

                const gasPrice = await provider.getGasPrice()

                createItemResponse = await smartContractWithSigner?.createItemForSale(
                    collection.address,
                    price,
                    nft.musicWallet,
                    nft.musicRoyalties * 100,
                    nft.videomakerWallet,
                    nft.videomakerRoyalties * 100,
                    nft.name,
                    nft.description,
                    itemUrl,
                    {
                        gasLimit: estimatedGas,
                        gasPrice: gasPrice
                    }
                )
                receipt = await createItemResponse.wait(1)
                tokenId = parseInt(receipt.events[2].args["itemId"]);
            }

            if (!tokenId) {
                return Promise.reject("Error creating item, no tokenId found")
            }

            return Promise.resolve([tokenId, collectionAddress]);

        } catch (error: any) {
            console.log(error);
            await storeLog({message: error.toString(), context: "createNFTForSale"})
            return Promise.reject(error);
        }
    }, [storeLog, connectedToMetamask, marketContract, provider, t]);

    const sendNFTCreatedToWallet = useCallback(async (nft: NFT): Promise<boolean> => {
        try {
            if (!window.ethereum) {
                return Promise.reject(false)
            }

            const response = await window.ethereum.request({
                "method": "wallet_watchAsset",
                "params": {
                    "type": "ERC721",
                    "options": {
                        "symbol": "VM-" + nft.name.substring(0, 3).toUpperCase(),
                        "address": nft.collectionAddress,
                        "tokenId": nft.tokenId?.toString(),
                        "image": !!nft.coverPath ? nft.coverPath : "https://videomuse.co/logo.png",
                    }
                }
            });
            return Promise.resolve(response === true)
        } catch (error) {
            console.log(error);
            return false
        }
    }, [])

    const checkOwnerOfNFT = useCallback(async (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress'], address: string): Promise<boolean> => {
        try {
            if (!collectionAddress) {
                return Promise.reject("Collection address not found")
            }
            const response: Contract = new ethers.Contract(collectionAddress, abi_items, provider);
            const owner = await response.ownerOf(tokenId)
            return Promise.resolve(owner.toLowerCase() === address.toLowerCase())
        } catch (e) {
            return Promise.reject(e)
        }
    }, [provider])

    const buyNFTItem = useCallback(async (nft: NFT): Promise<void> => {
        try {
            if (!connectedToMetamask) {
                await sdk?.connect();
                toast(t("messages.metamaskNotConnected"), {type: "error"});
                return Promise.reject("Metamask not connected");
            }
            if (nft.tokenId === undefined) {
                return Promise.reject("TokenId not found");
            }

            if (!provider) {
                return Promise.reject("No provider available");
            }
            const signer = await provider.getSigner();
            const smartContractWithSigner: any = marketContract?.connect(signer);

            const item = await marketContract?.itemsMarket(nft.collectionAddress, nft.tokenId);
            if (!item) {
                return Promise.reject("Item not found");
            }
            const gasPrice = await provider.getGasPrice();

            const estimatedGas = await smartContractWithSigner.estimateGas.buyItem(nft.collectionAddress, Number(nft.tokenId), {
                value: parseEther(nft.price.toString()),
            });

            const buyItemResponse = await smartContractWithSigner.buyItem(nft.collectionAddress, Number(nft.tokenId), {
                value: parseEther(nft.price.toString()),
                gasLimit: estimatedGas,
                gasPrice: gasPrice,
            });

            if (!buyItemResponse) {
                return Promise.reject("Error buying item");
            }

            await buyItemResponse.wait(1);

        } catch (error: any) {
            console.log("buyNFTItem", error);
            console.log("buyNFTItem", error?.code);
            if (error?.code === Logger.errors.TRANSACTION_REPLACED) {
                return Promise.resolve();
            }
            await storeLog({message: error.toString(), context: "buyNFTItem"});
            return Promise.reject(error);
        } finally {

        }
    }, [connectedToMetamask, marketContract, provider, sdk, storeLog, t]);

    // Check item was sold and belongs to user
    const checkItemWasSold = useCallback(async (nft: NFT, userId: User['uid']): Promise<boolean> => {
        if(!account){
            console.log("No account found")
            return Promise.reject("No account found")
        }
        const userIsOwner = await checkOwnerOfNFT(nft.tokenId, nft.collectionAddress, account);
        if (userIsOwner) {
            await assignNewOwnerToItem(nft.id, userId);
            await createUserPurchase(nft.id, userId);
            await sendNFTCreatedToWallet(nft);
            await createTransaction({
                nftId: nft.id,
                nftTokenId: nft.tokenId,
                collectionId: nft?.collectionId || null,
                type: TransactionType.BUY,
                price: nft.price,
                sellerUid: nft.userId,
                buyer: userId
            });
            return Promise.resolve(true);
        }
        return Promise.resolve(false);
    }, [account, assignNewOwnerToItem, checkOwnerOfNFT, createTransaction, createUserPurchase, sendNFTCreatedToWallet])

    const changeItemPrice = useCallback(async (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress'], price: number): Promise<any> => {
        try {

            if (!connectedToMetamask) {
                toast(t("messages.metamaskNotConnected"), {type: "error"})
                return Promise.reject("Metamask not connected")
            }

            if (!provider) {
                return Promise.reject("No provider available");
            }

            const signer = await provider.getSigner();
            const smartContractWithSigner: any = marketContract?.connect(signer);

            const estimatedGas = await smartContractWithSigner.estimateGas.editItemPrice(collectionAddress, tokenId, parseEther(price.toString()))
            const gasPrice = await provider.getGasPrice()

            console.log("estimatedGas", estimatedGas)

            const editItemPriceResponse = await smartContractWithSigner.editItemPrice(collectionAddress, tokenId, parseEther(price.toString()), {
                gasLimit: estimatedGas,
                gasPrice: gasPrice
            })

            const receipt = await editItemPriceResponse.wait(1)

            return Promise.resolve(receipt);
        } catch (error: any) {
            await storeLog({message: error.toString(), context: "changeItemPrice"})
            console.log(error);
            return Promise.reject(error);
        }
    }, [connectedToMetamask, marketContract, provider, storeLog, t])

    const sellItem = useCallback(async (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress'], price: number): Promise<any> => {
        try {

            if (!connectedToMetamask) {
                toast(t("messages.metamaskNotConnected"), {type: "error"})
                return Promise.reject("Metamask not connected")
            }

            if (!provider) {
                return Promise.reject("No provider available");
            }

            const signer = await provider.getSigner();
            const smartContractWithSigner: any = marketContract?.connect(signer);

            const estimatedGas = await smartContractWithSigner.estimateGas.sellItem(collectionAddress, tokenId, parseEther(price.toString()))
            const gasPrice = await provider.getGasPrice()

            const sellItemResponse = await smartContractWithSigner.sellItem(collectionAddress, tokenId, parseEther(price.toString()), {
                gasLimit: estimatedGas,
                gasPrice: gasPrice
            })

            try {
                const receipt = await sellItemResponse.wait(1)
                return Promise.resolve(receipt);
            } catch (error: any) {
                if (error?.code === Logger.errors.TRANSACTION_REPLACED) {
                    // Perform the same actions as above since the transaction was replaced but considered successful
                    const userIsOwner = await checkOwnerOfNFT(tokenId, collectionAddress, marketContract?.address || "")
                    if (userIsOwner) {
                    }
                }
            }


        } catch (error: any) {
            console.log(error);
            await storeLog({message: error.toString(), context: "sellItem"})
            return Promise.reject(error);
        }
    }, [storeLog, checkOwnerOfNFT, connectedToMetamask, marketContract, provider, t])

    const cancelItemSell = useCallback(async (tokenId: NFT['tokenId'], collectionAddress: NFT['collectionAddress']): Promise<any> => {
        try {
            if (!connectedToMetamask) {
                toast(t("messages.metamaskNotConnected"), {type: "error"})
                return Promise.reject("Metamask not connected")
            }

            if (!provider) {
                return Promise.reject("No provider available");
            }

            const signer = await provider.getSigner();
            const smartContractWithSigner: any = marketContract?.connect(signer);

            const estimatedGas = await smartContractWithSigner.estimateGas.cancelItemSale(collectionAddress, tokenId)
            const gasPrice = await provider.getGasPrice()

            const cancelItemResponse = await smartContractWithSigner.cancelItemSale(collectionAddress, tokenId, {
                gasLimit: estimatedGas,
                gasPrice: gasPrice
            })

            const receipt = await cancelItemResponse.wait(1)

            return Promise.resolve(receipt);
        } catch (error: any) {
            console.log(error);
            await storeLog({message: error.toString(), context: "cancelItemSell"})
            return Promise.reject(error);
        }
    }, [storeLog, connectedToMetamask, marketContract, provider, t])

    const getAccountBalance = useCallback(async () => {
        try {
            if (!window.ethereum) {
                return
            }
            if (connected) {
                const balance = await window.ethereum.request({
                    method: 'eth_getBalance',
                    params: [window.ethereum.selectedAddress, 'latest']
                })
                if (typeof balance === "string") {
                    const etherFormatedBalance = Number(formatEther(balance) || 0);
                    setAccountBalance(etherFormatedBalance)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, [connected])

    useEffect(() => {
        if (!connected) {
            initWeb3Connection().then(() => {
                setConnected(true)
            }).catch((error) => {
                console.error("Error connecting to web3", error)
            })
        }
        getAccountBalance().then()
    }, [connected, connectedToMetamask, getAccountBalance, initWeb3Connection]);

    return <Web3Context.Provider value={
        {
            provider,
            marketContract,
            createNFTForSale,
            buyNFTItem,
            checkItemWasSold,
            changeItemPrice,
            sellItem,
            cancelItemSell,
            accountBalance,
            sendNFTCreatedToWallet,
            checkOwnerOfNFT
        }
    }>{children}</Web3Context.Provider>
};

export default Web3Provider;