import useMenu from "../../../hooks/useMenu";
import {Link} from "react-router-dom";
import {Menu, Transition} from "@headlessui/react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {MenuItemType} from "../../../types/menu.types";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../providers/UserProvider";

type CollapsibleMainMenuProps = {
    show: boolean,
    style?: 'normal' | 'inverted',
}

type MenuItemProps = {
    item: MenuItemType,
}

const CollapsibleMainMenu = ({show = true, style = 'normal'}: CollapsibleMainMenuProps) => {

    const {isLogged} = useUserContext()
    const {menuItems} = useMenu()
    const {t} = useTranslation()

    const MenuItem = ({item}: MenuItemProps) => {
        return !item?.children?.length ? <li key={item.name}>
            <Link className={`text-sm block border-b border-gray-200 font-bold py-6 px-5 ${style === 'normal' ? 'text-gray-600' : 'text-white'}`}
                  to={item.url}>{t(item.name)}</Link>
        </li> : <Menu as={"div"} className={"relative"}>
            {({open}) => (
                <>
                    <Menu.Button as={"div"}
                                 className={`text-sm border-b border-gray-200 cursor-pointer font-bold py-6 px-5 flex flex-row items-center justify-between ${style === 'normal' ? 'text-gray-600' : 'text-white'}`}>
                        {t(item.name)}
                        {!!item?.children?.length ? (open ? <FaChevronUp size={12} className={"ml-2"}/> :
                            <FaChevronDown size={12} className={"ml-2"}/>) : false}
                    </Menu.Button>
                    {!!item?.children?.length && <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Menu.Items as={"ul"}>
                          {item.children.filter((subitem) => !isLogged ? !subitem.restricted : !subitem.hideWhenLogged)
                              .map((subitem: MenuItemType) =>
                                  <Menu.Item as={"li"}
                                             key={subitem.url}
                                             className={"text-sm border-b border-gray-200"}>
                                      <Link to={subitem.url}
                                            className={`py-4 px-4 block ${style === 'normal' ? 'text-gray-600' : 'text-white'} hover:bg-primary hover:text-white`}>{t(subitem.name)}</Link>
                                  </Menu.Item>)}
                      </Menu.Items>
                    </Transition>}
                </>
            )}
        </Menu>
    }

    return (
        <div className={`${show ? 'scale-y-100' : 'scale-y-0'} ${style === 'normal' ? 'bg-white' : 'bg-primaryDark'} w-full origin-top absolute top-0 left-0 overflow-hidden transition-all duration-300`}>
            <nav className={"block lg:hidden shadow-lg z-[99]"}>
                <ul className={"flex flex-col"}>
                    {menuItems.map((item) => <MenuItem item={item} key={item.url}
                    />)}
                </ul>
            </nav>
        </div>
    )
}

export default CollapsibleMainMenu;