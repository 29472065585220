import InputNumber from "../../ui/Inputs/InputNumber";
import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../ui/Buttons/Button";

type FormChangeNFTPriceProps = {
    currentPrice: number,
    onChangePrice: (price: number) => void
}

const FormChangeNFTPrice = ({onChangePrice, currentPrice}: FormChangeNFTPriceProps) => {

    const {t} = useTranslation()

    const [formState, setFormState] = useState<{ price: number }>({
        price: currentPrice
    })

    const onChangeInput = useCallback((value: string, name: string) => {
        if (name === "price") {
            setFormState(prev => ({...prev, [name]: +value}))
            return;
        }
    }, [setFormState])

    const onSubmit = useCallback(() => {
        onChangePrice(formState.price)
    }, [formState.price, onChangePrice])

    return (
        <form onSubmit={(ev) => ev.preventDefault() } noValidate={true}>
            <InputNumber
                         name={"price"}
                         required={true}
                         value={formState.price.toString()}
                         onChange={onChangeInput}
                         placeholder={t("placeholders.NFTprice")}
                         containerClasses={"mb-4"}
                         min={0}
                         max={10000000}
                         label={t("inputs.price")}/>
            <Button size={"sm"} disabled={!formState.price && formState.price > 0} onClick={onSubmit}>{t("actions.changeItemPrice")}</Button>
        </form>
    )
}

export default FormChangeNFTPrice;