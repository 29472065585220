import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import {useUserContext} from "../providers/UserProvider";

const ProtectedRoutes = () => {

    const {user} = useUserContext()

    if (user === undefined) {
        return <Navigate to="/login" />
    } else {
        return <Outlet />
    }

};

export default ProtectedRoutes;