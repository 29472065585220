import {FormEvent, useCallback} from "react";
import {InputError} from "../../../types/InputError.types";
import Label from "./Label";

type TextAreaProps = {
    name: string,
    value: string,
    placeholder?: string,
    label?: string,
    inputClasses?: string,
    containerClasses?: string,
    required?: boolean,
    onChange: (value: string, name: string) => void,
    error?: InputError | undefined
    rows?: number
}

const TextArea = ({

                      name,
                      value,
                      label,
                      onChange,
                      placeholder,
                      required = false,
                      inputClasses = '',
                      containerClasses = '',
                      error = undefined,
                      rows = 5
                  }: TextAreaProps) => {

    const onChangeInput = useCallback((event: FormEvent<HTMLTextAreaElement>) => {
        onChange(event.currentTarget.value, name);
    }, [name, onChange])

    return <div className={`${containerClasses}`}>
        {label ? <Label requiredInput={required} htmlFor={'input-' + name}>{label}</Label> : false}
        <textarea name={name}
                  id={'input-' + name}
                  placeholder={placeholder}
                  onChange={onChangeInput}
                  required={required}
                  value={value}
                  rows={rows}
                  className={`p-2 border ${!!error ? 'border-red-500' : 'border-gray-200'} placeholder:text-gray-500 rounded-md h-auto text-gray-900 block w-full ${inputClasses}`}/>
        {!!error ? <p className={"text-xs text-red-500 mt-1"}>{error.message}</p> : null}
    </div>
}

export default TextArea;