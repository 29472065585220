import {useCallback} from "react";
import {Transaction, TransactionList, TransactionParameters, TransactionState} from "../types/Statistics.type";
import {
    addDoc,
    collection,
    doc,
    DocumentSnapshot,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    orderBy,
    Query,
    query,
    QuerySnapshot,
    serverTimestamp,
    startAfter,
    where,
} from "firebase/firestore";
import {db} from "../firebase";
import {NFT} from "../types/NFT.type";
import {Collection} from "../types/Collection.type";
import useNFTCollectionQueries from "./useNFTCollectionQueries";
import {UserData} from "../types/UserData.type";
import useUserQueries from "./useUserQueries";

const useStatisticQueries = () => {

    const collectionName = 'transactions';

    const {getCollectionsByIds} = useNFTCollectionQueries()
    const {getUserDataByIds} = useUserQueries()

    const createTransaction = useCallback(async (transaction: TransactionState): Promise<boolean> => {
        try {

            let num = 1;
            const lastNumQuery: Query = query(collection(db, collectionName), orderBy('num', 'desc'), limit(1));
            const lastNumDocs = await getDocs(lastNumQuery);
            if (!lastNumDocs.empty) {
                const lastNumDoc = lastNumDocs.docs[0].data() as Transaction;
                num = lastNumDoc.num + 1;
            }

            await addDoc(collection(db, collectionName), {
                ...transaction,
                num,
                date: serverTimestamp(),
            });

            return Promise.resolve(true);

        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    }, []);

    const mergeTransactionWithCollection = useCallback(async (transactions: Transaction[], collectionIds: Collection['id'][]): Promise<Transaction[]> => {
        try {
            const collections: Collection[] = await getCollectionsByIds(collectionIds)
            const transactionsWithCollection: Transaction[] = transactions.map((transaction: Transaction) => {
                const collection = collections.find((collection) => collection.id === transaction.collectionId)
                if (!collection) {
                    console.error('Collection not found for transaction', transaction)
                    return transaction
                }
                return {...transaction, collection}
            })
            return Promise.resolve(transactionsWithCollection)
        } catch (error) {
            return Promise.reject(error)
        }
    }, [getCollectionsByIds])

    const mergeTransactionWithUserData = useCallback(async (transactions: Transaction[], userIds: UserData['uid'][]): Promise<Transaction[]> => {
        try {
            if(userIds.length === 0) {
                return Promise.resolve(transactions)
            }
            const users: UserData[] = await getUserDataByIds(userIds)
            const transactionsWithUserData: Transaction[] = transactions.map((transaction: Transaction) => {
                const user = users.find((user) => user.uid === transaction.sellerUid)
                if (!user) {
                    console.error('User not found for transaction', transaction)
                    return transaction
                }
                return {...transaction, seller: user}
            })
            return Promise.resolve(transactionsWithUserData)
        } catch (error) {
            return Promise.reject(error)
        }
    }, [getUserDataByIds])

    const getTransactions = useCallback(async (limitItems: number = 20, startAfterId: NFT['id'] | null = null, queryParameters: TransactionParameters): Promise<TransactionList> => {
        try {

            const collectionReference = collection(db, collectionName);
            let q: Query = query(collectionReference);


            if (queryParameters.number) {
                q = query(q, where('num', '==', queryParameters.number));
            }
            if (queryParameters.nftTokenId) {
                q = query(q, where('nftTokenId', '==', queryParameters.nftTokenId));
            }
            if (queryParameters.type && queryParameters.type.length > 0) {
                q = query(q, where('type', '==', queryParameters.type));
            }
            if (!!queryParameters?.collectionIds?.length && queryParameters?.collectionIds?.length > 0) {
                q = query(q, where("collectionId", "in", queryParameters.collectionIds))
            }
            const totalQuery = await getCountFromServer(q)
            const total = totalQuery.data().count

            if (startAfterId) {
                const startAfterDoc: DocumentSnapshot = await getDoc(doc(db, collectionName, startAfterId))
                q = query(q, startAfter(startAfterDoc))
            }

            if (!queryParameters?.collectionIds?.length) {
                q = query(q, orderBy('num', 'desc'))
            }

            q = query(q, limit(limitItems))

            const querySnapshot: QuerySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return Promise.resolve({
                    data: [],
                    lastItemId: null,
                    total: total
                })
            } else {
                const transactions: Transaction[] = [];
                const collectionIds = new Set<Collection['id']>()
                const sellerIds = new Set<UserData['uid']>()
                for (const doc of querySnapshot.docs) {
                    transactions.push({...doc.data(), id: doc.id} as Transaction);
                    collectionIds.add(doc.data().collectionId)
                    if(!!doc.data().sellerUid){
                        sellerIds.add(doc.data().sellerUid)
                    }
                }

                const filteredCollectionIds = Array.from(collectionIds).filter((collectionId) => collectionId !== null)

                if(filteredCollectionIds.length === 0) {
                    return Promise.resolve({
                    data: transactions,
                    lastItemId: querySnapshot.docs[querySnapshot.docs.length - 1].id,
                    total: total
                })}

                const transactionsWithCollection = await mergeTransactionWithCollection(transactions, Array.from(filteredCollectionIds))

                const transactionsWithUserData = await mergeTransactionWithUserData(transactionsWithCollection, Array.from(sellerIds))

                const lastItemId = querySnapshot.docs[querySnapshot.docs.length - 1].id;
                return Promise.resolve({
                    data: transactionsWithUserData,
                    lastItemId,
                    total: total
                });
            }
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    }, [mergeTransactionWithCollection, mergeTransactionWithUserData])

    return {createTransaction, getTransactions};

}

export default useStatisticQueries;