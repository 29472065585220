import {NFT} from "../../../types/NFT.type";
import {useCallback, useMemo} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "../Buttons/Button";
import {useUserContext} from "../../../providers/UserProvider";
import {IoMdHeart} from "react-icons/io";
import {Popover} from "@headlessui/react";
import {IoCog} from "react-icons/io5";
import {useTranslation} from "react-i18next";
import {MdOutlinePriceChange, MdOutlineRemoveShoppingCart, MdOutlineShoppingCart,} from "react-icons/md";
import {useWeb3Context} from "../../../providers/Web3Provider";
import {useAppContext} from "../../../providers/AppProvider";
import {toast} from "react-toastify";
import {FaCheck, FaVideo} from "react-icons/fa";

export type NFTComponentProps = {
    nft: NFT,
    onLikeOrDislike?: (nftId: NFT['id'], like: boolean) => void
    onBuy?: () => void
    canBePutOnSale?: boolean
    canBeRemovedFromSale?: boolean
    canBeEdited?: boolean
    onPutItemOnSale?: (nftId: NFT['id']) => void,
    onRemoveItemFromSale?: (nftId: NFT['id']) => void,
    priceCanBeChanged?: boolean,
    onChangePrice?: (nftId: NFT['id']) => void,
}

const NFTItem = ({
                     nft,
                     onLikeOrDislike,
                     onPutItemOnSale,
                     onRemoveItemFromSale,
                     canBePutOnSale = false,
                     canBeRemovedFromSale = false,
                     canBeEdited = false,
                     priceCanBeChanged = false,
                     onChangePrice
                 }: NFTComponentProps) => {

    const {setAppLoading} = useAppContext()
    const {buyNFTItem, checkItemWasSold} = useWeb3Context()
    const {user} = useUserContext()
    const {t} = useTranslation()

    const navigate = useNavigate()

    const likedByUser = useMemo(() => {
        if (!!user?.likes && !!nft) {
            return user.likes.find((like) => like.nftId === nft.id)
        }
        return false
    }, [nft, user?.likes])

    const canBeBought: boolean = useMemo(() => {
        // If not registered user, can't buy
        if (!user) {
            return false
        }
        // If user is the owner, can't buy
        if (user?.uid === nft.userId) {
            return false
        }
        // Can only be reselled twice
        if(nft.boughtCount >= 3){
            return false
        }
        return nft.onSale
    }, [nft.boughtCount, nft.onSale, nft.userId, user])

    const videoMakerProfilePicture = useMemo(() => {
        return (
            <Link to={'/author/' + nft.videomakerUid}
                  className={"bg-primary w-14 h-14 rounded-full block relative"}>
                {!!nft.videomaker && !!nft.videomaker.avatar ? <img src={nft.videomaker.avatar}
                                                                    referrerPolicy={"no-referrer"}
                                                                    alt={nft.videomaker.fullName?.toString()}
                                                                    className={"w-14 h-14 rounded-full hover:p-2 transition-all ease-in-out duration-500"}/> :
                    <div className={"bg-gray-200 w-14 h-14 rounded-full hover:p-2 transition-all ease-in-out duration-500"}/>}
                <span className={"bg-primaryDark w-5 h-5 p-1 absolute rounded-full bottom-0 right-0 flex fle-col items-center justify-center"}>
                        <FaVideo className={"text-white"} size={10}/>
                    </span>
            </Link>
        )

    }, [nft.videomaker, nft.videomakerUid])

    const musicianProfilePicture = useMemo(() => {
        return (
            <Link to={'/author/' + nft.musicianUid} className={"bg-primary w-14 h-14 rounded-full block"}>
                {!!nft.musician && !!nft.musician.avatar ? <img src={nft.musician.avatar}
                                                                referrerPolicy={"no-referrer"}
                                                                alt={nft.musician.fullName}
                                                                className={"w-14 h-14 rounded-full hover:p-2 transition-all ease-in-out duration-500"}/> :
                    <div className={"bg-gray-200 w-14 h-14 rounded-full hover:p-2 transition-all ease-in-out duration-500"}/>}
                <span className={"bg-primary w-5 h-5 p-1 absolute rounded-full bottom-0 right-0 flex fle-col items-center justify-center"}>
                        <FaCheck className={"text-white"} size={10}/>
                    </span>
            </Link>
        )
    }, [nft.musician, nft.musicianUid])

    const FilePreview = useMemo(() => {
        return nft ? (nft.coverPath ? <img src={nft.coverPath} alt={nft.name} className={"w-full rounded-md mb-4"}/> :
                <video src={nft.filePath} controls={false} preload={"metadata"} className={"mb-4 rounded-md"}/>) :
            <div className={"w-full h-40 rounded-md bg-gray-200 mb-4"}/>
    }, [nft])


    const EditPopover = useMemo(() => {
        return nft.id ? (<Popover className={"relative"}>
            <Popover.Button as={"button"}
                            className={"bg-primary hover:bg-primary/80 p-2 text-white absolute right-2 top-2 rounded-md z-20"}>
                <IoCog size={18}/>
            </Popover.Button>
            <Popover.Panel className="absolute -right-5 top-14 bg-white border w-full z-30 shadow-xl rounded-md">
                <ul>
                    {canBePutOnSale && <li className={"text-sm hover:bg-primary hover:text-white border-b block"}>

                      <button className={"flex flex-row items-center py-4 px-4 w-full"}
                              onClick={() => onPutItemOnSale ? onPutItemOnSale(nft.id) : {}}>
                        <MdOutlineShoppingCart size={18} className={"mr-1"}/>
                          {t("actions.putOnSale")}
                      </button>
                    </li>}
                    {canBeRemovedFromSale && <li className={"text-sm hover:bg-primary hover:text-white border-b block"}>
                      <button className={"flex flex-row items-center py-4 px-4 w-full"}
                              onClick={() => onRemoveItemFromSale ? onRemoveItemFromSale(nft.id) : {}}>
                        <MdOutlineRemoveShoppingCart size={18} className={"mr-1"}/>
                          {t("actions.removeFromSale")}
                      </button>
                    </li>}
                    {priceCanBeChanged && <li className={"text-sm hover:bg-primary hover:text-white border-b block"}>
                      <button className={"flex flex-row items-center py-4 px-4 w-full"}
                              onClick={() => onChangePrice ? onChangePrice(nft.id) : {}}
                      >
                        <MdOutlinePriceChange size={18} className={"mr-1"}/>
                          {t("actions.changeItemPrice")}
                      </button>
                    </li>}
                </ul>
            </Popover.Panel>
        </Popover>) : false
    }, [canBePutOnSale, canBeRemovedFromSale, nft?.id, onChangePrice, onPutItemOnSale, onRemoveItemFromSale, priceCanBeChanged, t])

    const onBuyItem = useCallback(async () => {
        try {
            if (nft.tokenId && !!user?.uid) {
                setAppLoading(true)
                await buyNFTItem(nft)
                await checkItemWasSold(nft, user.uid)
                toast(t("toast.itemBought"), {type: "success"})
                navigate("/account?tab=purchased")
            }
        } catch (error: any) {
            if (!!error?.code) {
                toast.error(t("messages." + error.code))
            } else {
                toast.error(t("messages.errorBuyingItem"))
            }

        } finally {
            setAppLoading(false)
        }
    }, [buyNFTItem, checkItemWasSold, navigate, nft, setAppLoading, t, user?.uid])


    const LikeHeart = () => {
        return (
            <div className={"flex flex-row items-center"}>
                {likedByUser ? (
                        <button onClick={() => onLikeOrDislike ? onLikeOrDislike(nft.id, false) : {}}>
                            <IoMdHeart className={"text-primary"}/>
                        </button>) :
                    (<button onClick={() => onLikeOrDislike ? onLikeOrDislike(nft.id, true) : {}}>
                        <IoMdHeart className={"text-gray-300 hover:text-primary"}/>
                    </button>)}
                <span className={"text-xs text-gray-500 ml-1"}>{nft.likesCount}</span>
            </div>
        )
    }

    const Skeleton = () => {
        return (
            <div className={"border border-gray-200 bg-gray-100 rounded-xl w-full transition-shadow duration-300 ease-in-out hover:shadow-lg min-w-[300px]"}/>
        )
    }

    return (
        nft ?
            <div className={"border border-gray-200 rounded-xl w-full transition-shadow duration-300 ease-in-out hover:shadow-lg min-w-[300px]"}>
                {canBeEdited && EditPopover}
                <div className={`p-4 relative ${(!!nft.videomaker?.avatar || !!nft.musician?.avatar) && 'mt-6'}`}>
                    {nft.videomaker?.avatar || nft.musician?.avatar ?
                        <div className={"top-0 left-4 -translate-y-1.5 absolute z-20"}>
                            <div className={"relative flex flex-row items-center gap-x-2"}>
                                {videoMakerProfilePicture}
                                {musicianProfilePicture}
                            </div>
                        </div> : null}
                    <Link to={`/nft/${nft.id}`} className={"block"}>
                        <div className={"overflow-hidden h-64 flex flex-col items-center justify-center hover:scale-105 transition-transform ease-in-out duration-700"}>
                            <span className={"rounded-lg overflow-hidden flex flex-col items-center justify-center"}>{FilePreview}</span>
                        </div>
                        <h4 className={"text-base mb-1"}>{nft.name}</h4>
                    </Link>
                    {nft.collection ?
                        <Link to={'/collections/' + nft.collectionId}>
                            <h5 className={"text-primary font-bold mb-2 text-sm"}>{nft.collection.name}</h5>
                        </Link> : null}
                    <p className={"text-sm text-gray-400 font-bold mb-2"}>{nft.price} MATIC</p>
                    <div className={"flex flex-row justify-between items-center"}>
                        {canBeBought ? <Button size={"xs"} onClick={onBuyItem}>Buy</Button> : false}
                        <div>
                            <LikeHeart/>
                        </div>
                    </div>
                </div>
            </div> : <Skeleton/>
    )

}

export default NFTItem;