import NFTItem from "../../../components/ui/NFT/NFTItem";
import type {NFT} from "../../../types/NFT.type";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../../providers/UserProvider";
import {Fragment, useCallback, useState} from "react";
import useNFTQueries from "../../../hooks/useNFTQueries";
import {useWeb3Context} from "../../../providers/Web3Provider";
import AppDialog from "../../../components/ui/AppDialog/AppDialog";
import FormChangeNFTPrice from "../../../components/forms/nft/FormChangeNFTPrice";
import {toast} from "react-toastify";
import {useAppContext} from "../../../providers/AppProvider";

type AuthorCreatedNFTItemsProps = {
    items: NFT[],
    onLikeOrDislikeItem: (nftId: NFT['id'], like: boolean) => void,
    refreshItems: () => void
}

const AuthorNFTItems = ({items, refreshItems, onLikeOrDislikeItem}: AuthorCreatedNFTItemsProps) => {

    const {appLoading, setAppLoading} = useAppContext()

    const {t} = useTranslation()
    const {user} = useUserContext()
    const {changeNFtOnSale, changeItemPrice: changeNFTPrice} = useNFTQueries()
    const {changeItemPrice, sellItem, cancelItemSell} = useWeb3Context()

    const [openChangePriceState, setOpenChangePriceState] = useState<{
        currentPrice: number,
        nftId: NFT['id']
    } | undefined>()

    const onPutItemOnSale = useCallback(async (nftId: NFT['id']) => {
        try {
            setAppLoading(true)
            const item = items.find(item => item.id === nftId)
            if (!item) return
            if (!item.previousOwnerId) {
                await changeNFtOnSale(nftId, true)
            } else {
                await sellItem(item.tokenId, item.collectionAddress, item.price)
                await changeNFtOnSale(nftId, true)
            }
            refreshItems()
            toast.success(t("messages.itemPutOnSale"))
        } catch (error: any) {
            console.error(error)
            if (!!error?.code) {
                toast.error(t("messages." + error.code))
            } else {
                toast.error(t("messages.errorPuttingItemOnSale"))
            }
        } finally {
            setAppLoading(false)
        }

    }, [t, changeNFtOnSale, items, refreshItems, sellItem, setAppLoading])

    const onRemoveItemOnSale = useCallback(async (nftId: NFT['id']) => {
        try {
            setAppLoading(true)
            const item = items.find(item => item.id === nftId)
            if (!item) return
            if (!item.previousOwnerId) {
                await changeNFtOnSale(nftId, false)
            } else {
                await cancelItemSell(item.tokenId, item.collectionAddress)
                await changeNFtOnSale(nftId, false)
            }
            toast.success(t("messages.itemRemovedFromSale"))
            refreshItems()
        } catch (error: any) {
            console.error(error)
            if (!!error?.code) {
                toast.error(t("messages." + error.code))
            } else {
                toast.error(t("messages.errorRemovingItemFromSale"))
            }
        } finally {
            setAppLoading(false)
        }
    }, [t, cancelItemSell, changeNFtOnSale, items, refreshItems, setAppLoading])

    const onChangePrice = useCallback((nftId: NFT['id']) => {
        const item = items.find(item => item.id === nftId)
        if (!item) return
        setOpenChangePriceState({currentPrice: item.price, nftId: nftId})
    }, [items])

    const changePrice = useCallback(async (price: number) => {
        try {
            setAppLoading(true)
            const item = items.find(item => item.id === openChangePriceState?.nftId)
            if (!item) return
            await changeItemPrice(item.tokenId, item.collectionAddress, price)
            await changeNFTPrice(item.id, price)
            toast.success(t("messages.priceChanged"))
            setOpenChangePriceState(undefined)
            await refreshItems()
        } catch (error: any) {
            if (!!error?.code) {
                toast.error(t("messages." + error.code))
            } else {
                toast.error(t("messages.errorChangingPrice"))
            }
            console.error(error)
        } finally {
            setAppLoading(false)
        }
    }, [changeItemPrice, changeNFTPrice, items, openChangePriceState?.nftId, refreshItems, setAppLoading, t])

    const EmptyComponent = () => {
        return <div className={"w-full bg-primary/10 p-6 rounded-md my-10 flex flex-col items-center justify-center"}>
            <p>{t("messages.emptyNft", {category: t("global.thisSection")})}</p>
        </div>
    }

    return items.length ? <Fragment>
        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"}>
            {items.map((item: NFT, index: number) => <NFTItem nft={item}
                                                              key={index}
                                                              onPutItemOnSale={onPutItemOnSale}
                                                              onRemoveItemFromSale={onRemoveItemOnSale}
                                                              canBeEdited={item.userId === user?.uid && !appLoading}
                                                              canBePutOnSale={item.userId === user?.uid && !item.onSale}
                                                              canBeRemovedFromSale={item.userId === user?.uid && item.onSale}
                                                              priceCanBeChanged={item.userId === user?.uid && item.onSale}
                                                              onChangePrice={onChangePrice}
                                                              onLikeOrDislike={onLikeOrDislikeItem}/>
            )}
        </div>
        <AppDialog isOpen={!!openChangePriceState?.nftId} onClose={() => setOpenChangePriceState(undefined)}>
            <FormChangeNFTPrice currentPrice={openChangePriceState?.currentPrice || 0} onChangePrice={changePrice}/>
        </AppDialog>
    </Fragment> : <EmptyComponent/>
}

export default AuthorNFTItems;