import { getStorage, ref, getDownloadURL } from "firebase/storage";

export default function useNFTFileStorage() {

    const getAbsoluteUrl = (url: string): Promise<string|null> => {
        const storage = getStorage();
        const pathReference = ref(storage, url);

        return getDownloadURL(pathReference).then((url) => {
            return url
        }).catch((error) => {
            return null
        })
    }

    return {getAbsoluteUrl}

}