import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useCallback, useMemo} from "react";
import {Combobox, ComboboxOption} from "@headlessui/react";

type GlobalSearchResultItemProps = {
    item: any,
    isLastItem?: boolean
}

const GlobalSearchResultItem = ({item, isLastItem = false}: GlobalSearchResultItemProps) => {

    const {t} = useTranslation()

    const indexName = useMemo(() => {
        if (item.index === 'nft_index') {
            return t("global.nft")
        } else if (item.index === 'user_index') {
            switch (item.userType) {
                case "musician":
                    return t("userTypes.musician")
                case "videomaker":
                    return t("userTypes.videomaker")
                default:
                    return t("userTypes.default")
            }
        } else if (item.index === 'collection_index') {
            return t("global.collection")
        }
    }, [item.index, item?.userType, t])


    return (
        <ComboboxOption className={`group cursor-pointer py-2 px-4 flex flex-row items-center border-gray-200 text-primary hover:bg-primary hover:text-white ${!isLastItem && 'border-b'}`}
                         value={item.objectID}>
            <div>
                {item.index === 'nft_index' &&
                    (!!item.coverPath ? <img src={item.coverPath} alt={item.name} className={"w-8 h-8 rounded-full mr-2 bg-primary group-hover:bg-white"}/> : <div className={"w-8 h-8 rounded-full mr-2 bg-primary group-hover:bg-white"}/>)}
                {item.index === 'user_index' &&
                    (!!item.avatar ? <img src={item.avatar} alt={item.username} className={"w-8 h-8 rounded-full mr-2 bg-primary group-hover:bg-white"}/> : <div className={"w-8 h-8 rounded-full mr-2 bg-primary group-hover:bg-white"}/>)}
                {item.index === 'collection_index' &&
                    (!!item.filePath ? <img src={item.filePath} alt={item.name} className={"w-8 h-8 rounded-full mr-2 bg-primary group-hover:bg-white"}/> : <div className={"w-8 h-8 rounded-full mr-2 bg-primary group-hover:bg-white"}/>)}
            </div>
            <div>
                <p className={"text-base font-bold mb-1"}>{item.name}</p>
                <p className={"text-sm text-gray-500 group-hover:text-white"}>{indexName}</p>
            </div>
        </ComboboxOption>)

}

export default GlobalSearchResultItem