import AppScaffold from "../../components/ui/AppScaffold";
import {useParams} from "react-router-dom";
import {Fragment, useCallback, useLayoutEffect, useMemo, useState} from "react";
import useNFTCollectionQueries from "../../hooks/useNFTCollectionQueries";
import {Collection, CollectionState} from "../../types/Collection.type";
import {NFT} from "../../types/NFT.type";
import useNFTQueries from "../../hooks/useNFTQueries";
import NFTItem from "../../components/ui/NFT/NFTItem";
import {useUserContext} from "../../providers/UserProvider";
import {Button} from "../../components/ui/Buttons/Button";
import {IoPencil} from "react-icons/io5";
import {useTranslation} from "react-i18next";
import CollectionEditDialog from "./components/CollectionEditDialog";
import trimWallet from "../../utils/walletFunctions";
import {toast} from "react-toastify";
import GridLoader from "../../components/ui/Loaders/GridLoader";

//TODO: Remove related files when deleting item

const COLLECTION_LIMIT_ITEMS = 8

const ScreenCollectionItem = () => {

    const {id} = useParams() as { id: string }
    const {t} = useTranslation()

    const {user} = useUserContext()
    const {getCollection} = useNFTCollectionQueries()
    const {getCollectionNFTs} = useNFTQueries()

    const [item, setItem] = useState<Collection | null>(null)
    const [items, setItems] = useState<NFT[]>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const [lastItemId, setLastItemId] = useState<NFT['id'] | null>(null)
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const userCanEditCollection = useMemo(() => {
        return user?.uid === item?.userId
    }, [item?.userId, user?.uid])

    const getItem = useCallback(async () => {
        try {
            const response = await getCollection(id)
            setItem(response)
        } catch (error) {
            console.log("error", error)
        }
    }, [getCollection, id])

    const getItems = useCallback(async (loadMoreItems: boolean = false) => {
        try {
            setLoading(true)
            const response = await getCollectionNFTs(id, COLLECTION_LIMIT_ITEMS, loadMoreItems ? lastItemId : null)
            setItems(response.data)
            setTotalItems(response.total)
            setLastItemId(response.lastItemId)
        } catch (error) {
            console.log("error", error)
        } finally {
            setLoading(false)
        }
    }, [getCollectionNFTs, id, lastItemId])

    useLayoutEffect(() => {
        if (id) {
            getItem().catch()
            getItems().catch()
        }
        return () => {
            setItem(null)
            setItems([])
            setTotalItems(0)
            setLastItemId(null)
        }
    }, [id]);

    const onCopyWallet = useCallback(() => {
        if (user?.wallet) {
            navigator.clipboard.writeText(user.wallet)
            toast(t('actions.walletCopied'), {
                type: "success"
            })
        }
    }, [t, user?.wallet])

    const CollectionAuthor = useMemo(() => {
        return (
            <div className={"w-full flex flex-col items-center justify-center"}>
                <div className={"-translate-y-1/2 flex flex-col items-center justify-center"}>
                    {item?.author?.avatar ? <img src={item?.author?.avatar}
                                                 alt={item?.author?.fullName}
                                                 className={"w-36 h-36 rounded-full mb-6"}/> :
                        <div className={"w-36 h-36 rounded-full mb-6 bg-primary/20"}/>}
                    <h1 className={"text-2xl"}>{item?.name}</h1>
                    {!!item?.address && <p className={'text-gray-500 font-bold mb-2 flex flex-row items-center'}>
                        {trimWallet(item?.address)}
                      <button onClick={onCopyWallet}
                              className={'ml-4 text-xs bg-gray-100 border border-gray-200 rounded-md px-2 py-1 text-black hover:bg-gray-50'}>Copy
                      </button>
                    </p>}
                    {userCanEditCollection &&
                      <Button size={'sm'} extraClasses={"mt-2"} onClick={() => setOpenEditDialog(true)}>
                              <span className={"flex flex-row items-center"}>
                                  <IoPencil className={"mr-1"}/>
                                  {t('actions.edit')}
                              </span>
                      </Button>}
                </div>
            </div>
        )
    }, [item?.author?.avatar, item?.author?.fullName, item?.author?.wallet, item?.name, onCopyWallet, t, userCanEditCollection])

    return (
        <AppScaffold menuPosition={"absolute"} menuStyle={"normal"}>
            <Fragment>
                <section
                    style={{backgroundImage: `url(${item?.filePath})`} as React.CSSProperties}
                    className={`bg-primary/20 min-h-[360px] bg-cover bg-center bg-no-repeat`}/>
                <section className={"app-container relative"}>
                    {CollectionAuthor}
                </section>
                <section className={"app-container mb-10 min-h-[360px]"}>
                    {loading ? <GridLoader visible/> :
                        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"}>
                            {items.map((item: NFT) => <NFTItem nft={item}
                                                               key={item.id}/>)}
                        </div>}
                    {!!items.length && <div className={"py-6 flex flex-col items-center justify-center"}>
                      <Button disabled={items.length >= totalItems}
                              onClick={() => getItems(true)}>{t("actions.loadMore")}</Button>
                    </div>}
                </section>
                {item && userCanEditCollection && <CollectionEditDialog collectionId={item.id} isOpen={openEditDialog}
                                                                        initialUserData={item as CollectionState}
                                                                        onClose={() => setOpenEditDialog(false)}
                                                                        refetchData={getItem}/>}
            </Fragment>
        </AppScaffold>
    )
}

export default ScreenCollectionItem