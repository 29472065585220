import AppScaffold from "../../components/ui/AppScaffold";
import {useTranslation} from "react-i18next";
import useNFTQueries from "../../hooks/useNFTQueries";
import {useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react";
import {NFT, NFTQueryParameters} from "../../types/NFT.type";
import NFTItem from "../../components/ui/NFT/NFTItem";
import {Button} from "../../components/ui/Buttons/Button";
import Dropdown, {DropdownOption} from "../../components/ui/Inputs/Dropdown";
import {useNFTCategories} from "../../hooks/useNFTCategories";
import {useUserContext} from "../../providers/UserProvider";
import useUserLikes from "../../hooks/useUserLikes";
import GridLoader from "../../components/ui/Loaders/GridLoader";
import {useSearchParams} from "react-router-dom";
import NFTSearch from "../../components/search/NFTSearch";
import {Helmet} from "react-helmet";

const EXPLORE_LIMIT_ITEMS = 8

const initialQueryParameters: NFTQueryParameters = {
    categoryIds: [],
    searchIds: [],
}

const ScreenExplore = () => {
    const {t} = useTranslation()

    const {user} = useUserContext()
    const {nftCategories} = useNFTCategories()
    const {getCommunityNTFs} = useNFTQueries()
    const {likeOrDislikeNFT} = useUserLikes()

    let [searchParams, setSearchParams] = useSearchParams();

    const [nftItems, setNftItems] = useState<NFT[]>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const [lastItemId, setLastItemId] = useState<NFT['id'] | null>(null)
    const [queryParameters, setQueryParameters] = useState<NFTQueryParameters>(initialQueryParameters)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingMoreItems, setLoadingMoreItems] = useState<boolean>(false)
    const [searchIsEmpty, setSearchIsEmpty] = useState<boolean | undefined>(undefined)

    const nftCategoriesAsOptions = useMemo(() => {
        return nftCategories.map((item): DropdownOption => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }, [nftCategories])

    const getItems = useCallback((loadMoreItems: boolean = false) => {
        if (!loadMoreItems) {
            setLoading(true)
        } else {
            setLoadingMoreItems(true)
        }
        getCommunityNTFs(EXPLORE_LIMIT_ITEMS, loadMoreItems ? lastItemId : null, queryParameters).then((data) => {
            setNftItems(prev => [...prev, ...data.data])
            setTotalItems(data.total)
            setLastItemId(data.lastItemId)
        }).catch((error) => {
            console.log("error getCommunityNTFs", error)
        }).finally(() => {
            setLoading(false)
            setLoadingMoreItems(false)
        })
    }, [getCommunityNTFs, lastItemId, queryParameters])

    const onChangeCategory = useCallback((value: string[] | string, name: string) => {
        if (Array.isArray(value)) {
            setQueryParameters(prev => {
                return {
                    ...prev,
                    categoryIds: value
                }
            })
        }
    }, [])

    const onSearch = useCallback((hits: any[]) => {
        setSearchIsEmpty(false)
        if (hits.length > 0) {
            setQueryParameters((prev: any) => {
                return {
                    ...prev,
                    searchIds: hits.map((item: any) => item.objectID)
                }
            })
        } else {
            setSearchIsEmpty(true)
            setQueryParameters((prev: any) => {
                return {
                    ...prev,
                    searchIds: []
                }
            })
        }
    }, [])

    const onLikeOrDislikeItem = useCallback(async (nftId: NFT['id'], like: boolean) => {
        try {
            if (!user?.uid) throw new Error("User not found")
            await likeOrDislikeNFT(nftId, user, like, setNftItems)
        } catch (error) {
            console.log("error", error)
        }
    }, [likeOrDislikeNFT, user])

    useEffect(() => {
        if (searchParams.has('category')) {
            setQueryParameters((prev: any) => {
                return {
                    ...prev,
                    categoryIds: [searchParams.get('category')]
                }
            })
        }
    }, [searchParams]);

    useLayoutEffect(() => {
        getItems()
        return () => {
            setNftItems([])
            setTotalItems(0)
            setLastItemId(null)
        }
    }, [queryParameters])

    const EmptyComponent = () => {
        return <div className={"w-full bg-primary/10 p-6 rounded-md my-10 flex flex-col items-center justify-center"}>
            <p>{t("messages.emptyNftExplore")}</p>
        </div>
    }

    return (
        <AppScaffold menuPosition={"relative"} menuStyle={"normal"}>
            <>
                <Helmet titleTemplate="Videomuse - %s">
                    <title>{t('pages.explore.title')}</title>
                    <meta name="description" content={t('pages.explore.description')}/>
                </Helmet>
                <div className={"py-28 px-20 text-center bg-createPage bg-cover bg-top"}>
                    <h1 className={"h1 text-white pb-4"}>{t("pages.explore.title")}</h1>
                </div>
                <section className={"app-container py-10"}>
                    <div className={"py-6 flex flex-col lg:flex-row gap-4"}>
                        <NFTSearch onSearch={onSearch}/>
                        <Dropdown name={"categories"}
                                  label={""}
                                  multiple={true}
                                  containerClasses={"mb-4"}
                                  placeholder={t("placeholders.NFTcategories")}
                                  options={nftCategoriesAsOptions}
                                  value={queryParameters.categoryIds ? queryParameters.categoryIds : []}
                                  onChange={onChangeCategory}/>
                    </div>
                    <div>
                        {searchIsEmpty === true && <div className={"w-full bg-primary/10 p-6 rounded-md flex flex-col items-center justify-center"}>
                          <p>{t("messages.emptyNFTSearch")}</p>
                        </div>}
                        {loading ? <GridLoader visible/> :
                            (nftItems.length > 0 ?
                                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-6"}>
                                    {nftItems.map((item: NFT) => <NFTItem nft={item}
                                                                          key={item.id}
                                                                          onLikeOrDislike={onLikeOrDislikeItem}/>)}
                                </div> : <EmptyComponent/>)}
                        {loadingMoreItems ? <GridLoader visible/> : null}
                        {!!nftItems.length && (nftItems.length < totalItems) && <div className={"py-6 flex flex-col items-center justify-center"}>
                          <Button disabled={loadingMoreItems || loading} onClick={() => getItems(true)}>{t("actions.loadMore")}</Button>
                        </div>}
                    </div>
                </section>
            </>
        </AppScaffold>
    );
};

export default ScreenExplore;