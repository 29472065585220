import {useSDK} from "@metamask/sdk-react";
import {useCallback, useMemo} from "react";
import {toast} from "react-toastify";

const useMetamaskConnection = () => {

    const {sdk, connected, balance} = useSDK()

    const connectToMetamask = useCallback(async () => {
        try {
            await sdk?.connect()
            // TODO: Launch a toast message
        } catch (e: any) {
            toast.error(e.message)
            console.error(e)
        }
    },[sdk])

    const connectedToMetamask = useMemo(() => connected, [connected])

    return {connectToMetamask, connectedToMetamask, balance}

}

export default useMetamaskConnection