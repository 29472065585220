import {UserData, UserType} from "../../../types/UserData.type";
import {FaCheck, FaVideo} from "react-icons/fa";
import {Link} from "react-router-dom";

type AuthorHorizontalPreviewProps = {
    user: UserData
}

const AuthorHorizontalPreview = ({user}: AuthorHorizontalPreviewProps) => {

    return (
        <div className={"flex flex-row items-center"}>
            <Link to={'/author/' + user.uid} className={"relative bg-primary w-14 h-14 rounded-full block mr-4"}>
                <img src={user.avatar}
                     referrerPolicy={"no-referrer"}
                     alt={user.fullName}
                     className={"w-14 h-14 rounded-full hover:p-2 transition-all ease-in-out duration-500"}/>
                    <span className={`${user?.userType === UserType.VIDEOMAKER ? 'bg-primaryDark' : 'bg-primary'} w-5 h-5 p-1 absolute rounded-full bottom-0 right-0 flex fle-col items-center justify-center`}>
                        {user?.userType === UserType.VIDEOMAKER ?
                            <FaVideo className={"text-white"} size={14}/> :
                            <FaCheck className={"text-white"} size={14}/>}
                    </span>
            </Link>
            <div>
                <Link to={'/author/' + user.uid} className={"block"}>
                    <p className={"font-bold text-black hover:text-primary"}>{user.fullName}</p>
                </Link>
            </div>
        </div>
    )

}

export default AuthorHorizontalPreview