import {useCallback, useEffect} from 'react'
import {getAuth, signOut} from "firebase/auth"
import { useNavigate } from 'react-router-dom'
import {useSDK} from "@metamask/sdk-react";
import {useUserContext} from "../../providers/UserProvider";

const auth = getAuth()

const ScreenLogout = () => {

  const {setUser} = useUserContext()
  const {connected: connectedToMetamask, sdk} = useSDK()

  const navigate = useNavigate()

  const onLogout = useCallback(() => {
    if (connectedToMetamask) {
        sdk?.disconnect()
    }
    signOut(auth).then(() => {
      setUser(null)
      navigate("/")
    })
  }, [connectedToMetamask, navigate, sdk, setUser])

  useEffect(() => onLogout(), [onLogout])

  return <></>;
}

export default ScreenLogout;