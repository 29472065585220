type Procedure = (...args: any[]) => void;

export function debounce<F extends Procedure>(
    func: F,
    waitMilliseconds = 50,
): F {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
        const context = this;

        const doLater = () => {
            timeoutId = undefined;
            func.apply(context, args);
        };

        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(doLater, waitMilliseconds);
    } as F;
}