import {AiOutlineLoading3Quarters} from "react-icons/ai";

type GridLoaderProps = {
    visible?: boolean
}

const GridLoader = ({visible = false}: GridLoaderProps) => {
    return (
        <div className={"w-full py-20 flex flex-col items-center justify-center"}>
            <div>
                <AiOutlineLoading3Quarters size={56} className={"animate-spin text-primary"} />
            </div>
        </div>
    )
}


export default GridLoader