import {Combobox, ComboboxInput, ComboboxOption, ComboboxOptions} from "@headlessui/react";
import React, {useCallback, useRef, useState} from "react";
import useAlgoliaSearch from "../../hooks/useAlgoliaSearch";
import {NFT} from "../../types/NFT.type";
import {useTranslation} from "react-i18next";
import {UserType} from "../../types/UserData.type";
import Label from "../ui/Inputs/Label";
import {BiSearch} from "react-icons/bi";
import {debounce} from "../../utils/debounce";

type UserDataSearchProps = {
    label?: string,
    placeholder?: string,
    required?: boolean,
    userType: UserType,
    onChange: (userId: string) => void
}

const collectionIndex = 'user_index'

const UserDataSearch = ({userType, onChange, label, placeholder, required = false}: UserDataSearchProps) => {

    const {t} = useTranslation()

    const [selectedResult, setSelectedResult] = useState<string>("");
    const [searchResults, setSearchResults] = useState<any[]>([])

    const {getIndex} = useAlgoliaSearch()

    const onSearchItem = useCallback(async (searchTerm: string) => {
        try {
            console.log("searchTerm", searchTerm)
            const index = await getIndex(collectionIndex);
            const results = await index.search(searchTerm, {
                filters: `userType:${userType}`
            });
            console.log("results", results)
            setSearchResults(results.hits);
            return results.hits;
        } catch (error) {
            console.error(error);
            return [];
        }
    }, [getIndex, userType]);

    const debouncedSearch = useRef(debounce(onSearchItem, 800)).current;

    const onChangeComboBox = useCallback(async (userId: NFT['userId']) => {
        if (userId) {
            const userName = searchResults.find((result) => result.objectID === userId)?.username;
            setSelectedResult(`@${userName}`);
            onChange(userId)
        }
    }, [onChange, searchResults]);

    const onChangeInput = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const value = event.target.value;
            console.log("value", value)
            if (value.length >= 2) {
                debouncedSearch(value)
            }
            if (value.length === 0) {
                setSearchResults([])
                setSelectedResult("")
            }
        } catch (error) {
            console.error(error);
        }
    }, [debouncedSearch]);


    return (
        <Combobox value={selectedResult} onChange={onChangeComboBox}>
            {label ?
                <Label requiredInput={required} htmlFor={'input-user-data-search-' + userType}>{label}</Label> : false}
            <div className={"p-2 border placeholder:text-gray-500 rounded-md h-auto text-gray-900 w-full flex flex-row items-center"}>
                <BiSearch size={18} className={"mx-2 text-gray-500"}/>
                <ComboboxInput onChange={onChangeInput}
                                placeholder={placeholder}
                                className={'p-2 placeholder:text-gray-500 h-auto text-gray-900 block w-full outline-none'}/>
            </div>
            <ComboboxOptions className={"absolute z-20 mt-1 max-h-60 w-full min-w-[250px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"}>
                {searchResults.map((result: any, index) => (
                    <ComboboxOption key={result.objectID}
                                     className={`cursor-pointer py-2 px-4 flex flex-row items-center ${index !== searchResults.length - 1 && 'border-b'} border-gray-200 text-primary hover:bg-primary hover:text-white`}
                                     value={result.objectID}>
                        @{result.username}
                    </ComboboxOption>
                ))}
                {searchResults.length === 0 &&
                  <ComboboxOption className={"py-2 px-4 text-gray-500"} value={""}>
                      {t("global.noResults")}
                  </ComboboxOption>}
            </ComboboxOptions>
        </Combobox>
    )
}

export default UserDataSearch;