import React, {Fragment} from 'react';
import './App.css';
import "./i18n";
import "./firebase"
import 'react-toastify/dist/ReactToastify.css';

import {RouterProvider} from "react-router-dom";
import {router} from "./navigation/router";
import UserProvider from "./providers/UserProvider";
import {ToastContainer} from "react-toastify";
import AppProvider from "./providers/AppProvider";
import {MetaMaskProvider} from "@metamask/sdk-react";
import Web3Provider from "./providers/Web3Provider";

function App() {
    return (
        <MetaMaskProvider
            debug={process.env.NODE_ENV === "development"}
            sdkOptions={{
                dappMetadata: {
                    name: process.env.REACT_APP_APP_NAME || "Videomuse - NFT Marketplace Design",
                    url: window.location.href,
                },
                // Other options
            }}>
            <AppProvider>
                <Web3Provider>
                    <Fragment>
                        <UserProvider>
                            <RouterProvider router={router}/>
                        </UserProvider>
                        <ToastContainer/>
                    </Fragment>
                </Web3Provider>
            </AppProvider>
        </MetaMaskProvider>
    );
}

export default App;
