import algoliasearch, {SearchClient, SearchIndex} from "algoliasearch";

export default function useAlgoliaSearch() {

    const getIndex = async (indexName: string): Promise<SearchIndex> => {
        try {
            if (!process.env.REACT_APP_ALGOLIA_APPLICATION_ID || !process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY) {
                throw new Error("Algolia environment variables not set");
            }
            const client: SearchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY);
            const index = client.initIndex(indexName);

            return Promise.resolve(index);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    const searchMultipleIndexes = async (queries: {
        indexName: string,
        query: string,
        params: any
    }[]): Promise<any[]> => {
        try {
            if (!process.env.REACT_APP_ALGOLIA_APPLICATION_ID || !process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY) {
                throw new Error("Algolia environment variables not set");
            }
            const client: SearchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY);
            const searchResponse = await client.multipleQueries(queries);
            return searchResponse.results;
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    return {getIndex, searchMultipleIndexes};

}