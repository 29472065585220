import {Collection} from "../../../types/Collection.type";
import {Link} from "react-router-dom";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";

type CollectionItemProps = {
    item: Collection
}

const CollectionItem = ({item}: CollectionItemProps) => {

    const {t} = useTranslation()

    const CollectionAuthor = useMemo(() => {
        return (
            <Link to={'/author/' + item.userId} className={"bg-primary w-16 h-16 rounded-full block mb-2"}>
                {item.author?.avatar ? <img src={item.author.avatar}
                                            referrerPolicy={"no-referrer"}
                                            alt={item.author?.fullName}
                                            className={"w-16 h-16 border-[5px] border-white rounded-full transition-all ease-in-out duration-500"}/> :
                    <div className={"w-16 h-16 rounded-full border-[5px] border-white transition-all ease-in-out duration-500 bg-primary/20"}/>}
            </Link>
        )
    }, [item.author?.avatar, item.author?.fullName, item.userId])

    return (
        <div className={"bg-white rounded-lg border border-gray-200 overflow-hidden min-w-[250px]"}>
            <Link to={'/collections/' + item.id} className={"block h-48 overflow-hidden"}>
                <div style={{backgroundImage: item.filePath ? 'url(' + item.filePath + ')' : 'none'}}
                     className={`bg-primary/20 h-48 w-full bg-cover bg-center hover:scale-110 transition-transform ease-in-out duration-500`}/>
            </Link>
            <div className={"p-4 max-h-[120px]"}>
                <div className={"flex flex-col items-center justify-center -translate-y-12"}>
                    {CollectionAuthor}
                    <h4 className={"text-base mb-1"}>{item.name}</h4>
                    <p className={"text-sm text-gray-400"}>{item.nfts.length} ClipMEs</p>
                </div>
            </div>
        </div>
    )
}

export default CollectionItem