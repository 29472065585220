import AppScaffold from '../../components/ui/AppScaffold'
import {useTranslation} from 'react-i18next'
import {Button} from '../../components/ui/Buttons/Button'
import useNFTQueries from '../../hooks/useNFTQueries'
import {Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import type {NFT, NFTList} from "../../types/NFT.type";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import useUserQueries from "../../hooks/useUserQueries";
import {UserData, UserType} from "../../types/UserData.type";
import {useUserContext} from "../../providers/UserProvider";
import AuthorEditDialog from "./components/AuthorEditDialog";
import {IoPencil} from "react-icons/io5";
import trimWallet from "../../utils/walletFunctions";
import {toast} from "react-toastify";
import useUserLikes from "../../hooks/useUserLikes";
import AuthorNFTItems from "./components/AuthorNFTItems";
import AuthorTabs from "./components/AuthorTabs";
import GridLoader from "../../components/ui/Loaders/GridLoader";
import {FaCheck, FaVideo} from "react-icons/fa";
import {Helmet} from "react-helmet";
import AuthorDeleteAccountDialog from "./components/AuthorDeleteAccountDialog";
import {FirebaseError} from "@firebase/util";

const AUTHOR_LIMIT_ITEMS = 8

export enum AuthorTabsEnum {
    onSale = 'onSale',
    created = 'created',
    liked = 'liked',
    purchased = 'purchased'
}

const ScreenAuthor = () => {

    const {id} = useParams() as { id: string }
    const {t} = useTranslation()

    const {user, setUser, onDeleteUser, isLogged} = useUserContext()
    const {getUserCreatedNTFs, getUserOnSaleNFT, getUserLikedNFT, getUserPurchasedNFT} = useNFTQueries()
    const {getUserData, followUser, unfollowUser} = useUserQueries()
    const {likeOrDislikeNFT} = useUserLikes()

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    const [author, setAuthor] = useState<UserData>()
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
    const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState<boolean>(false)
    const [authorNFTItems, setAuthorNFTItems] = useState<NFT[]>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const [lastItemId, setLastItemId] = useState<NFT['id'] | null>(null)
    const [itemsTab, setItemsTab] = useState<AuthorTabsEnum>(AuthorTabsEnum.onSale)
    const [contentLoading, setContentLoading] = useState<boolean>(false)
    const [loadingMoreItems, setLoadingMoreItems] = useState<boolean>(false)

    const userId = useMemo(() => {
        return id || user?.uid
    }, [id, user?.uid, isLogged])

    const authorIsCurrentUser = useMemo(() => {
        return user?.uid === userId
    }, [user?.uid, userId])

    const canFollowUser = useMemo(() => {
        console.log(user?.following);
        if (authorIsCurrentUser) {
            return false
        }
        return !user?.following?.includes(id);

    }, [authorIsCurrentUser, id, user?.following])

    const canUnfollowUser = useMemo(() => {
        if (authorIsCurrentUser) {
            return false
        }
        return user?.following?.includes(id);

    }, [authorIsCurrentUser, id, user?.following])

    useEffect(() => {
        if (userId) {
            getData().catch()
        }
        readTabFromURL()
    }, [userId])

    useEffect(() => {
        if (!!userId) {
            getItems().catch(() => console.log("error"))
        }
        return () => {
            setAuthorNFTItems([])
            setTotalItems(0)
            setLastItemId(null)
        }
    }, [userId, itemsTab]);

    const appendTabToURL = useCallback((tab: AuthorTabsEnum) => {
        setSearchParams({tab})
    }, [setSearchParams])

    const onSetTab = useCallback((tab: AuthorTabsEnum) => {
        appendTabToURL(tab)
        setItemsTab(tab)
    }, [appendTabToURL])

    const readTabFromURL = useCallback(() => {
        const tab = searchParams.get('tab') as AuthorTabsEnum
        if (tab) {
            setItemsTab(tab)
        }
    }, [searchParams])

    const getItems = useCallback(async (loadMoreItems: boolean = false) => {
        try {
            if (!!userId) {
                if (!loadMoreItems) {
                    setContentLoading(true)
                } else {
                    setLoadingMoreItems(true)
                }

                let response: NFTList = {data: [], total: 0, lastItemId: null};
                switch (itemsTab) {
                    case 'onSale':
                        response = await getUserOnSaleNFT(userId, AUTHOR_LIMIT_ITEMS, loadMoreItems ? lastItemId : null)
                        break
                    case 'created':
                        response = await getUserCreatedNTFs(userId, AUTHOR_LIMIT_ITEMS, loadMoreItems ? lastItemId : null)
                        break
                    case 'liked':
                        response = await getUserLikedNFT(userId, AUTHOR_LIMIT_ITEMS, loadMoreItems ? lastItemId : null)
                        break
                    case 'purchased':
                        response = await getUserPurchasedNFT(userId, AUTHOR_LIMIT_ITEMS, loadMoreItems ? lastItemId : null)
                        break
                }

                if (!!response?.data) {
                    setAuthorNFTItems(prev => [...prev, ...response.data])
                    setTotalItems(response.total)
                    setLastItemId(response.lastItemId)
                }
            }
        } catch (e) {
            console.error(e)
        } finally {
            setContentLoading(false)
            setLoadingMoreItems(false)
        }
    }, [getUserCreatedNTFs, getUserLikedNFT, getUserOnSaleNFT, getUserPurchasedNFT, itemsTab, lastItemId, userId])

    const getData = useCallback(async () => {
        try {
            if (!!userId) {
                const response = await getUserData(userId)
                setAuthor(response as UserData)
            }
        } catch (error) {
            console.log("error", error)
        }
    }, [getUserData, userId])

    const onFollowOrUnfollowUser = useCallback(async (follow: boolean) => {
        try {
            if (!userId || !user?.uid) throw new Error("User not found")
            if (follow) {
                await followUser(user.uid, userId)
                setAuthor(prev => ({
                    ...prev,
                    followers: [...(prev?.followers || []), user.uid]
                }) as UserData)
                setUser((prev: UserData) => ({
                    ...prev,
                    following: [...(prev?.following || []), userId]
                }) as UserData)
            } else {
                await unfollowUser(user.uid, userId)
                setAuthor(prev => ({
                    ...prev,
                    followers: (prev?.followers || []).filter(follower => follower !== user.uid)
                }) as UserData)
                setUser((prev: UserData) => ({
                    ...prev,
                    following: (prev?.following || []).filter(following => following !== userId)
                }) as UserData)
            }
        } catch (error) {
            console.log("error", error)
        }
    }, [followUser, setUser, unfollowUser, user?.uid, userId])

    const onCopyWallet = useCallback(() => {
        if (author?.wallet) {
            navigator.clipboard.writeText(author.wallet)
            toast(t('actions.walletCopied'), {
                type: "success"
            })
        }
    }, [t, author?.wallet])

    const onLikeOrDislikeItem = useCallback(async (nftId: NFT['id'], like: boolean) => {
        try {
            if (!user?.uid) throw new Error("User not found")
            await likeOrDislikeNFT(nftId, user, like, setAuthorNFTItems)
        } catch (error) {
            console.log("error", error)
        }
    }, [likeOrDislikeNFT, user])

    const onDeleteUserAccount = useCallback(async () => {
        try {
            await onDeleteUser()
            toast(t('messages.accountDeleted'), {type: "success"})
            navigate('/')
        } catch (error: any) {
            console.error("error", error)
            if (error instanceof FirebaseError) {
                if (error.code === "auth/requires-recent-login") {
                    toast(t("firebase." + error.code), {type: "error"})
                    navigate('/login?deleteAccount=' + userId)
                }
            } else {
                toast(t('messages.errorDeletingAccount'), {type: "error"})
            }

        } finally {
            setOpenDeleteAccountDialog(false)
        }
    }, [])

    return <AppScaffold menuPosition={'relative'}>
        <Fragment>
            <Helmet titleTemplate="Videomuse - %s">
                <title>{author?.fullName}</title>
                <meta name="description" content={t('pages.author.description')}/>
            </Helmet>
            <div className={'py-28 px-20 text-center bg-registerPage bg-cover bg-top'}/>
            <section className={'app-container pt-24 pb-8 flex flex-col gap-6 md:gap-0 md:flex-row md:items-center md:justify-between'}>
                <div>
                    <div className={'flex flex-row items-center'}>
                        <div className={"relative"}>
                            {author?.avatar ? <img alt={author.fullName || ''}
                                                   referrerPolicy={'no-referrer'}
                                                   className={'h-20 w-20 md:h-36 md:w-36 block rounded-full'}
                                                   src={author?.avatar}/> :
                                <div className={'h-20 w-20 md:h-36 md:w-36 bg-gray-200 rounded-full'}/>
                            }
                            {author?.userType !== UserType.DEFAULT &&
                              <span className={`${author?.userType === UserType.VIDEOMAKER ? 'bg-primaryDark' : 'bg-primary'} w-8 h-8 md:w-10 md:h-10 p-1 absolute rounded-full bottom-0 right-0 flex fle-col items-center justify-center`}>
                                {author?.userType === UserType.VIDEOMAKER ?
                                    <FaVideo className={"text-white"} size={14}/> :
                                    <FaCheck className={"text-white"} size={14}/>}
                              </span>
                            }
                        </div>
                        <div className={'ml-5'}>
                            <h4 className={'h4 mb-2'}>{author?.fullName}</h4>
                            <p className={'text-primary font-bold mb-2'}>@{author?.username}</p>
                            {!!author?.wallet && <p className={'text-gray-500 font-bold mb-2 flex flex-row items-center'}>
                                {trimWallet(author.wallet)}
                              <button onClick={onCopyWallet}
                                      className={'ml-4 text-xs bg-gray-100 border border-gray-200 rounded-md px-2 py-1 text-black hover:bg-gray-50'}>Copy
                              </button>
                            </p>}
                            {authorIsCurrentUser &&
                              <Button size={'sm'} extraClasses={"mt-2"} onClick={() => setOpenEditDialog(true)}>
                              <span className={"flex flex-row items-center"}>
                                  <IoPencil className={"mr-1"}/>
                                  {t('actions.edit')}
                              </span>
                              </Button>}
                        </div>
                    </div>
                </div>
                <div className={'flex flex-row items-center justify-between'}>
                    {!!author?.followers?.length &&
                      <p className={'mr-5 font-bold'}>{t('global.followers', {count: author?.followers?.length})}</p>}
                    {canFollowUser &&
                      <Button size={'lg'} onClick={() => onFollowOrUnfollowUser(true)}>{t('actions.follow')}</Button>}
                    {canUnfollowUser && <Button size={'lg'}
                                                onClick={() => onFollowOrUnfollowUser(false)}>{t('actions.unfollow')}</Button>}
                </div>
            </section>
            <section className={'app-container pb-10'}>
                <AuthorTabs setTab={onSetTab} tabSelected={itemsTab} authorIsCurrentUser={authorIsCurrentUser}/>
                {!contentLoading ? <AuthorNFTItems items={authorNFTItems}
                                                   refreshItems={getItems}
                                                   onLikeOrDislikeItem={onLikeOrDislikeItem}/> : <GridLoader visible/>}
                {loadingMoreItems ? <GridLoader visible/> : null}
                {!!authorNFTItems.length && <div className={"py-6 flex flex-col items-center justify-center"}>
                  <Button disabled={authorNFTItems.length >= totalItems || loadingMoreItems}
                          onClick={() => getItems(true)}>{t("actions.loadMore")}</Button>
                </div>}
            </section>
            {authorIsCurrentUser && author && userId &&
              <AuthorEditDialog isOpen={openEditDialog}
                                userId={userId}
                                initialUserData={author}
                                onClose={() => setOpenEditDialog(false)}
                                refetchData={getData}
                                onOpenDeleteAccount={() => {
                                    setOpenEditDialog(false)
                                    setOpenDeleteAccountDialog(true)
                                }}
              />}
            {authorIsCurrentUser && author && userId &&
              <AuthorDeleteAccountDialog isOpen={openDeleteAccountDialog}
                                         onDeleteUserAccount={onDeleteUserAccount}
                                         onClose={() => setOpenDeleteAccountDialog(false)}/>}
        </Fragment>
    </AppScaffold>
}

export default ScreenAuthor