import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {AuthorTabsEnum} from "../ScreenAuthor";

type AuthorTabsProps = {
    setTab: (tab: AuthorTabsEnum) => void,
    tabSelected: string,
    authorIsCurrentUser: boolean
}

const AuthorTabs = ({setTab, tabSelected, authorIsCurrentUser}: AuthorTabsProps) => {

    const {t} = useTranslation()

    const tabs = useMemo(() => {
        const items = [
            {
                name: AuthorTabsEnum.onSale,
                label: t("tabs.onSale"),
                restricted: false
            },
            {
                name: AuthorTabsEnum.created,
                label: t("tabs.created"),
                restricted: false
            },
            {
                name: AuthorTabsEnum.liked,
                label: t("tabs.liked"),
                restricted: false
            },
            {
                name: AuthorTabsEnum.purchased,
                label: t("tabs.purchased"),
                restricted: true
            }
        ]
        if (authorIsCurrentUser) {
            return items
        } else {
            return items.filter(item => !item.restricted)
        }
    }, [t, authorIsCurrentUser])

    return (
        <div className={"flex flex-row gap-x-1 md:gap-x-3 py-6"}>
            {tabs.map(tab => {
                return <button key={tab.name}
                               className={`text-sm md:text-base px-2 md:px-3 py-2 min-w-[80px] rounded text-center transition-colors duration-300 font-bold ${tabSelected === tab.name ? 'bg-primaryDark text-white hover:bg-primaryDark/80' : 'bg-gray-200/90 text-primary hover:text-white hover:bg-primaryDark'}`}
                               onClick={() => setTab(tab.name)}>{tab.label}</button>
            })}
        </div>
    )
}

export default AuthorTabs