import React from "react";

type ButtonProps = {
    size?: "xs" | "sm" | "md" | "lg"
    extraClasses?: string,
    children: JSX.Element | string,
    disabled?: boolean,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    id?: string
}

export const Button = ({extraClasses = '', size = 'sm', children, onClick, disabled = false, id = ''}: ButtonProps) => {
    return (
        <button disabled={disabled}
                {...(id && {id})}
                className={`text-${size} text-white font-bold bg-primary hover:bg-primaryDark focus:bg-primaryDark transition-colors ease-in-out duration-200 py-1.5 px-10 rounded-md ${disabled && 'opacity-25'} ${extraClasses}`}
                onClick={onClick}>{children}</button>
    );
};