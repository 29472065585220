import {useNFTCategories} from "../../../hooks/useNFTCategories";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {FaFacebook, FaInstagram, FaTiktok, FaYoutube} from "react-icons/fa6";

const BottomBar = () => {

    const {t} = useTranslation()

    const {nftCategories} = useNFTCategories()

    return <div className={"border-t"}>
        <div className={"app-container text-center lg:text-left py-20 gap-6 flex flex-col md:gap-0 md:flex-row justify-between"}>
            <div className={"md:w-6/12 lg:4/12"}>
                <img src={require('./../../../images/logo.png')}
                     className={"w-auto pb-4 mx-auto lg:mx-0"}
                     alt={""}
                     height={36}
                     width={167}/>
                <p className={"pb-4"}>Inspire. Play. Engage</p>
                <p><a href={"mailto:info@videomuse.co"} className={"pb-4 text-primary"}>info@videomuse.co </a></p>
            </div>
            <div className={"md:w-6/12 lg:4/12"}>
                <h5 className={"h5 mb-5"}>{t("global.categories")}</h5>
                <ul>
                    <li className={"pb-2"}><Link to={'/explore'}
                                                 className={"text-gray-500 hover:text-primary"}>{t('global.allNFT')}</Link>
                    </li>
                    {nftCategories.map((category, key) => <li key={key} className={"pb-2"}>
                        <Link to={`/explore?category=${category.id}`}
                              className={"text-gray-500 hover:text-primary"}>{category.name}</Link>
                    </li>)}
                </ul>
            </div>
            <div className={"md:w-6/12 lg:4/12"}>
                <h5 className={"h5 mb-5"}>{t("global.resources")}</h5>
                <ul>
                    <li className={"pb-2"}>
                        <Link to={'/about'} className={"text-gray-500 hover:text-primary"}>{t("nav.about")}</Link>
                    </li>
                    <li className={"pb-2"}>
                        <Link to={'/privacy-policy'}
                              className={"text-gray-500 hover:text-primary"}>{t("nav.privacyPolicy")}</Link>
                    </li>
                    <li className={"pb-2"}>
                        <Link to={'/general-terms-of-sale'}
                              className={"text-gray-500 hover:text-primary"}>{t("nav.generalTermsOfSale")}</Link>
                    </li>
                    <li className={"pb-2"}>
                        <Link to={'/terms-of-use'}
                              className={"text-gray-500 hover:text-primary"}>{t("nav.termsOfUse")}</Link>
                    </li>
                    <li className={"pb-2"}>
                        <Link to={'/how-to-buy-clipme'}
                              className={"text-gray-500 hover:text-primary"}>{t("nav.howToBuyClipMe")}</Link>
                    </li>
                    <li className={"pb-2"}>
                        <Link to={'/selling-clipme'}
                              className={"text-gray-500 hover:text-primary"}>{t("nav.sellingClipme")}</Link>
                    </li>
                    <li className={"pb-2"}>
                        <Link to={'/what-clipme-are'}
                              className={"text-gray-500 hover:text-primary"}>{t("nav.whatClipmeAre")}</Link>
                    </li>
                </ul>
            </div>
            <div className={"md:w-6/12 lg:4/12"}>
                <h5 className={"h5 mb-5"}>{t("global.social")}</h5>
                <ul className={"flex flex-row gap-x-6"}>
                <li className={"pb-3"}>
                        <a href={"https://www.instagram.com/videomuse_/"}
                           target={"_blank"}
                           className={"text-gray-500 hover:text-primary"}>
                            <FaInstagram size={28} className={"inline-block"}/>
                        </a>
                    </li>
                    <li className={"pb-3"}>
                        <a href={"https://www.tiktok.com/@videomuse_"}
                           target={"_blank"}
                           className={"text-gray-500 hover:text-primary"}>
                            <FaTiktok size={28} className={"inline-block"}/>
                        </a>
                    </li>
                    <li className={"pb-3"}>
                        <a href={"https://www.facebook.com/profile.php?id=100093163732871"}
                           target={"_blank"}
                           className={"text-gray-500 hover:text-primary"}>
                            <FaFacebook size={28} className={"inline-block"}/>
                        </a>
                    </li>
                    <li className={"pb-3"}>
                        <a href={"https://www.youtube.com/@Videomuse_"}
                           target={"_blank"}
                           className={"text-gray-500 hover:text-primary"}>
                            <FaYoutube size={28} className={"inline-block"}/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
}

export default BottomBar;