import AppScaffold from "../../components/ui/AppScaffold";
import {useTranslation} from "react-i18next";

const Screen404 = () => {

    const {t} = useTranslation()

    return (
        <AppScaffold menuPosition={"relative"} menuStyle={"normal"}>
            <>
                <div className={"py-28 px-20 text-center bg-createPage bg-cover bg-top"}>
                    <h1 className={"h1 text-white pb-4"}>{t("pages.404.title")}</h1>
                </div>
                <section className={"app-container py-10"}>
                    <div className={"w-full bg-primary/10 p-6 rounded-md my-10 flex flex-col items-center justify-center"}>
                        <p>{t("pages.404.description")}</p>
                    </div>
                </section>
            </>
        </AppScaffold>
    )
}

export default Screen404