import {User} from "firebase/auth";
import {NFT} from "../types/NFT.type";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    limit,
    query,
    Query,
    serverTimestamp,
    updateDoc,
    where
} from "firebase/firestore";
import {db} from "../firebase";

export function useUserLikeQueries() {

    const nftsCollectionName = "nfts";
    const collectionName = "userLikes";

    const createUserLike = async (userId: User['uid'], nftId: NFT['id']): Promise<number> => {
        try {
            await addDoc(collection(db, collectionName), {
                userId,
                nftId,
                created: serverTimestamp(),
            });

            let likesCount = 0;
            const nftReference = doc(db, nftsCollectionName, nftId);
            const nftDoc = await getDoc(nftReference);
            if (nftDoc.exists()) {
                const nftData = nftDoc.data() as NFT;
                if (nftData) {
                    likesCount = nftData.likesCount + 1;
                    await updateDoc(nftReference, {
                        likesCount: increment(1)
                    });
                }
            }
            return likesCount;

        } catch (error) {
            return Promise.reject(error)
        }
    }

    const removeUserLike = async (nftId: NFT['id']): Promise<number> => {
        try {

            let likesCount = 0;

            const q: Query = query(collection(db, collectionName), where("nftId", "==", nftId), limit(1));
            const likesDoc = await getDocs(q);

            if (!likesDoc.empty) {

                const likeDoc = likesDoc.docs[0];
                await deleteDoc(likeDoc.ref)

                const nftReference = doc(db, nftsCollectionName, nftId);
                const nftDoc = await getDoc(nftReference);

                if (nftDoc.exists()) {
                    likesCount = nftDoc.data().likesCount - 1;
                    await updateDoc(nftReference, {
                        likesCount: increment(-1)
                    });
                }
            }
            return likesCount;
        } catch (error) {
            return Promise.reject(error)
        }
    }

    return {createUserLike, removeUserLike}

}