import {NFTState} from "../../../types/NFT.type";
import {FileWithPath} from "react-dropzone";
import {useMemo} from "react";
import {UserData} from "../../../types/UserData.type";
import {Collection} from "../../../types/Collection.type";

type FormNFTPreviewItemProps = {
    filePreview?: FileWithPath,
    videoMaker?: UserData,
    musician?: UserData,
    collection?: Collection,
    item: NFTState
}

const FormNFTPreviewItem = ({item, videoMaker, musician, collection, filePreview = undefined}: FormNFTPreviewItemProps) => {

    const fileIsVideo = (type: string): boolean => {
        if(type.includes("video/")) {
            return true;
        }
        return false;
    }

    const videoMakerProfilePicture = useMemo(() => {
        if (!!videoMaker && !!videoMaker.avatar) {
            return <img src={videoMaker.avatar}
                        referrerPolicy={"no-referrer"}
                        alt={videoMaker.fullName?.toString()}
                        className={"w-14 h-14 rounded-full"}/>
        }
        return null
    }, [videoMaker])

    const musicianProfilePicture = useMemo(() => {
        if (!!musician && !!musician.avatar) {
            return <img src={musician.avatar}
                        referrerPolicy={"no-referrer"}
                        alt={musician.fullName?.toString()}
                        className={"w-14 h-14 rounded-full"}/>
        }
        return null
    }, [musician])

    const FilePreview = useMemo(() => {
        return filePreview?.path ? fileIsVideo(filePreview?.type) ?
                <video src={URL.createObjectURL(filePreview)} controls={false} preload={"none"} className={"h-32 w-full"}/>
                : <img src={URL.createObjectURL(filePreview)} alt={""} className={"w-full rounded-md mb-2"}/> :
            <div className={"w-full h-40 rounded-md bg-gray-200 mb-2"}/>
    }, [filePreview])

    return <div className={"border border-gray-200 rounded-xl w-full sticky top-20"}>
        <div className={`p-4 relative ${(!!videoMaker?.avatar || !!musician?.avatar) && 'mt-6'}`}>
            {videoMaker?.avatar || musician?.avatar ? <div className={"top-0 left-4 -translate-y-1.5 absolute"}>
                <div className={"relative flex flex-row items-center gap-x-2"}>
                    {videoMakerProfilePicture}
                    {musicianProfilePicture}
                </div>
            </div> : null}
            {FilePreview}
            <h4 className={"text-base mb-1"}>{item.name}</h4>
            {collection ? <h5 className={"text-primary font-bold mb-2 text-sm"}>{collection.name}</h5>: null}
            <p className={"text-sm text-gray-400 font-bold"}>{item.price} MATIC</p>
        </div>
    </div>

}

export default FormNFTPreviewItem;