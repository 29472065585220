import {AiOutlineLoading3Quarters} from "react-icons/ai";

type AppLoaderProps = {
    visible?: boolean
}

const AppLoader = ({visible = false}: AppLoaderProps) => {
    return visible ?
        <div className={"fixed h-full bg-white/80 w-full z-[999] top-0 left-0 right-0 flex flex-1 items-center justify-center"}>
            <AiOutlineLoading3Quarters size={56} className={"animate-spin text-primary"} />
        </div> : null;
}


export default AppLoader;