import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Category} from "../types/Category.type";
import {FaCube, FaHeadphones, FaMicrochip, FaMicrophone, FaMusic, FaVideo} from "react-icons/fa";

export function useNFTCategories() {

    const {t} = useTranslation()

    const nftCategories = useMemo((): Category[]  => {
        return [
            {
                name: t("categories.videoclip"),
                id: "videoclip",
                icon: <FaVideo />
            },
            {
                name: t("categories.live"),
                id: "live",
                icon: <FaMicrophone />
            },
            {
                name: t("categories.arXr"),
                id: "arXr",
                icon: <FaHeadphones />
            },
            {
                name: t("categories.digitalArt"),
                id: "digitalArt",
                icon: <FaMicrochip />
            },
            {
                name: t("categories.lyric"),
                id: "lyric",
                icon: <FaMusic />
            },
            {
                name: t("categories.abstract"),
                id: "abstract",
                icon: <FaCube />
            },
        ]
    }, [t])

    const getCategoryById = (id: string): Category | null => {
        return nftCategories.find((item: Category) => item.id === id) || null
    }

    return {nftCategories, getCategoryById}

}